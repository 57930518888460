import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, Dropdown } from "react-bootstrap";

import "./Navigation.css";

class TopNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutMenuOpen: false,
            feedbackMenuOpen: false,
            infoMenuOpen: false,
            sampleMenuOpen: false,
        }
    }

    componentDidMount() {
        if(this.props.isAuthenticated && !this.props.fullName) {
            this.props.handleLogout();
        }
    }

    openMenu = menu => {
        this.setState({
            aboutMenuOpen: menu === 'about',
            feedbackMenuOpen: menu === 'feedback',
            infoMenuOpen: menu === 'info',
            sampleMenuOpen: menu === 'sample'
        });
    }

    closeMenu = menu => {
        this.setState({
            aboutMenuOpen: false,
            feedbackMenuOpen: false,
            infoMenuOpen: false,
            sampleMenuOpen: false
        });
    }

    renderAuthNav() {
      const { lessonLabel, isCoach, isAdmin, isHATResearcher, isAdminView } = this.props;
      const tutorName = sessionStorage.getItem('tutorName');
      const tutorId = sessionStorage.getItem('tutorId');
      const feedbackLabel = (isCoach || isHATResearcher) ? `Feedback for ${tutorName || tutorId}` : 'My Feedback';
      
      const currentVideo = sessionStorage.getItem("mostRecentVideo");
      const homeLink = isCoach ? '/coach-home' : isHATResearcher ? '/hat-researcher-home' : '/';
      const homeLinkName = (isCoach || isHATResearcher) ? 'All Tutors' : 'Home';
      
      return (
        <>
          <Nav className="mr-auto text link" style={{ paddingLeft: 2 }}>

            {/* **************** HOME LINK (variable: different if it's a coach) *********************** */}
            <Nav.Link as={Link} to={homeLink} className='mainLink'>{homeLinkName}</Nav.Link>


            {/* **************** FEEDBACK LINK: only if a currentVideo *********************** */}
            {currentVideo && (
              <div
                onMouseEnter={() => this.openMenu('feedback')}
                onMouseLeave={this.closeMenu}
              >
                {(!this.props.isCoach || tutorId) && (
                  <Nav.Link as={Link} className='mainLink'>{feedbackLabel}</Nav.Link>
                )}
                <div
                  className='dropMenuContainer'
                  style={{ display: `${this.state.feedbackMenuOpen ? 'block' : 'none'}` }}>
                  <Nav.Link as={Link} to={currentVideo ? `/video/${currentVideo}` : "/video"} className="dropLink" onClick={this.closeMenu}><span className='dLinkColor'>Most Recent {lessonLabel}</span></Nav.Link>
                  <Nav.Link as={Link} to="/videos" className="dropLink" onClick={this.closeMenu} ><span className='dLinkColor'>All {lessonLabel}s</span></Nav.Link>
                  <Nav.Link as={Link} to="/trends" className="dropLinkLast" onClick={this.closeMenu}><span className='dLinkColor'>{lessonLabel} Trends</span></Nav.Link>
                </div>
              </div>
            )}

            {/* **************** UPLOAD LINK *********************** */}
            {isAdmin && (
              <div>
                <Nav.Link as={Link} to="/upload" className='mainLink' >Upload Video</Nav.Link>
              </div>
            )}

                
                  {/* **************** ABOUT LINK *********************** */}
                  {/*
                  <div>
                        <Nav.Link as={Link} to="/definitions" className='mainLink'>About Talk Moves</Nav.Link>
                  </div>
                */}
                  {/* About menu is now just a single link 
                  <div
                        onMouseEnter={() => this.openMenu('about')}
                        onMouseLeave={this.closeMenu}
                    >

                        <Nav.Link as={Link} to="/" className='mainLink' onClick={this.closeMenu}>About Talk Moves</Nav.Link>
                        <div
                            className='dropMenuContainer'
                            style={{ display: `${this.state.aboutMenuOpen ? 'block' : 'none'}` }}>
                            <Nav.Link as={Link} to="/definitions" className="dropLink"  onClick={this.closeMenu} ><span className='dLinkColor'>Definitions and Examples</span></Nav.Link>
                            <Nav.Link as={Link} to="/educator-resources" className="dropLinkLast"  onClick={this.closeMenu} ><span className='dLinkColor'> Educator Resources</span></Nav.Link>
                        </div>
                    </div>             
                    */}

                  {/* **************** INFO LINK *********************** */}
                  {/* The application info link is outdated. Remove it -- 14 Feb 2023 - Scott Dooley
                      <div>
                        <Nav.Link as={Link} to="/application-overview" className='mainLink'>Application Info</Nav.Link>
                </div> */}

                  {/* <div
                        onMouseEnter={() => this.openMenu('info')}
                        onMouseLeave={this.closeMenu}
                    >
                        <Nav.Link as={Link} to="/" className='mainLink' onClick={this.closeMenu}>Application Info</Nav.Link>
                        <div
                            className='dropMenuContainer'
                            style={{ display: `${this.state.infoMenuOpen ? 'block' : 'none'}` }}>
                            {/* 
                            <Nav.Link href="https://teacherguide.talkmoves.com#post-12" target="_blank" className="dropLink" onClick={this.closeMenu}><span className='dLinkColor'>Recording with SWIVL
</span></Nav.Link>
                            <Nav.Link href="https://teacherguide.talkmoves.com/index.php/faq/" target="_blank" className="dropLink" onClick={this.closeMenu}><span className='dLinkColor'>Application FAQs</span></Nav.Link>
                            <Nav.Link as={Link} to="/recording-with-swivl" className="dropLink"  onClick={this.closeMenu} ><span className='dLinkColor'> Recording with SWIVL</span></Nav.Link>
                            <Nav.Link as={Link} to="/application-overview" className="dropLink"  onClick={this.closeMenu} ><span className='dLinkColor'> Application Overview</span></Nav.Link>
                            <Nav.Link as={Link} to="/research-and-team" className="dropLinkLast"  onClick={this.closeMenu} ><span className='dLinkColor'>Our Research and Team</span></Nav.Link>


                        </div>
                </div> */}   

                  {/* **************** ADMIN LINKS *********************** */}
                    {isAdminView || isHATResearcher
                        ?
                         <>
                            <Nav.Link as={Link} to="/reports" className='mainLink'>Research</Nav.Link>
                            {!isHATResearcher && (
                            <Nav.Link as={Link} to="/shadow" className='mainLink'>Set User</Nav.Link>
                            )}
                        </>
                        : <>
                            {/* <Nav.Link as={Link} to="/videos">MY VIDEOS</Nav.Link>
                            <Nav.Link href="https://teacherguide.talkmoves.com" target="_blank">TEACHER GUIDE</Nav.Link> */}
                            {isAdmin && (
                                <>
                                    <Nav.Link as={Link} to="/shadow"><span style={{color: '#17a2b8'}}>Set User</span></Nav.Link>
                                    <Nav.Link onClick={this.props.switchToAdmin}><span style={{color: '#17a2b8'}}>Admin View</span></Nav.Link>
                                </>
                            )}
                        </>
                    }
                {/* </Nav>
                <Nav> */}
                    <Dropdown eventkey={9} title="Menu" id="menu-nav-dropdown" variant="light" style={{marginLeft: 24, position: 'absolute', right: 32 }}>
                        <Dropdown.Toggle nocaret="false" variant="light">
                            <i className="fas fa-user" style={{ marginRight: 8 }}></i>
                            {this.props.shadowName ? '' : this.props.fullName}
                            {this.props.shadowName ? <span style={{ color: '#3fc9c4' }}>{`As ${this.props.shadowName}`}</span> : ''}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ left: -10 }}>
                            <Dropdown.Item as={Link} to="/profile" > Profile </Dropdown.Item>
                            {/* <Dropdown.Item  >Settings</Dropdown.Item> */}
                            {this.props.isAdmin && (
                                <Dropdown.Item as={Link} to='/shadow' >Set User </Dropdown.Item>
                            )}
                            <Dropdown.Item onClick={this.props.handleLogout}>Sign out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </>)
    }
    renderVisitorNav() {
        return(
            <>
                <Nav className="mr-auto text link" style={{ paddingLeft: 2 }}>

                {/* **************** HOME LINK *********************** */}
                <Nav.Link as={Link} to="/" className='mainLink'>Home</Nav.Link>
 

                  {/* **************** Sample LINK *********************** */}
                  <div
                        onMouseEnter={() => this.openMenu('sample')}
                        onMouseLeave={this.closeMenu}
                    >
                        <Nav.Link as={Link} to="/" className='mainLink' onClick={this.closeMenu}>Samples</Nav.Link>
                        <div
                            className='dropMenuContainer'
                            style={{ display: `${this.state.sampleMenuOpen ? 'block' : 'none'}` }}>
                            <Nav.Link as={Link} to="/sample-video" className="dropLink" onClick={this.closeMenu}><span className='dLinkColor'>Sample Lesson</span></Nav.Link>
                            <Nav.Link as={Link} to="/sample-trends" className="dropLinkLast" onClick={this.closeMenu}><span className='dLinkColor'>Sample Trends</span></Nav.Link>
                        </div>
                    </div>   

                
                  {/* **************** ABOUT LINK *********************** */}
                  <div>
                        <Nav.Link as={Link} to="/definitions" className='mainLink'>About Talk Moves</Nav.Link>
                  </div>
                  {/* <div
                        onMouseEnter={() => this.openMenu('about')}
                        onMouseLeave={this.closeMenu}
                    >
                        <Nav.Link as={Link} to="/" className='mainLink' onClick={this.closeMenu}>About Talk Moves</Nav.Link>
                        <div
                            className='dropMenuContainer'
                            style={{ display: `${this.state.aboutMenuOpen ? 'block' : 'none'}` }}>
                            <Nav.Link as={Link} to="/definitions" className="dropLink"  onClick={this.closeMenu} ><span className='dLinkColor'>Definitions and Examples</span></Nav.Link>
                            <Nav.Link as={Link} to="/educator-resources" className="dropLinkLast"  onClick={this.closeMenu} ><span className='dLinkColor'> Educator Resources</span></Nav.Link>
                        </div>
                    </div>  */}


                  {/* **************** INFO LINK *********************** */}
                  {/* The application information page is outdated. Remove it -- 2023 02 14 -- Scott Dooley 
                  <div>
                        <Nav.Link as={Link} to="/application-overview" className='mainLink'>Application Info</Nav.Link>
                </div> */ }

                  {/* <div
                        onMouseEnter={() => this.openMenu('info')}
                        onMouseLeave={this.closeMenu}
                    >
                        <Nav.Link as={Link} to="/" className='mainLink' onClick={this.closeMenu}>Application Info</Nav.Link>
                        <div
                            className='dropMenuContainer'
                            style={{ display: `${this.state.infoMenuOpen ? 'block' : 'none'}` }}>
                            <Nav.Link as={Link} to="/recording-with-swivl" className="dropLink"  onClick={this.closeMenu} ><span className='dLinkColor'> Recording with SWIVL</span></Nav.Link>
                            <Nav.Link as={Link} to="/application-overview" className="dropLink"  onClick={this.closeMenu} ><span className='dLinkColor'> Application Overview</span></Nav.Link>
                            <Nav.Link as={Link} to="/research-and-team" className="dropLinkLast"  onClick={this.closeMenu} ><span className='dLinkColor'>Our Research and Team</span></Nav.Link>
                        </div>
                </div>  */ }

                    <div>
                        <Nav.Link as={Link} to="/login" className='mainLink'>Sign In</Nav.Link>
                    </div>
                </Nav>
            </>)
    }
    render() {
        return (
            <div>
            {this.props.isAuthenticated ? 
                this.renderAuthNav()
                : 
                this.renderVisitorNav()
                }
            </div>   
        )
    }
}

TopNavigation.propTypes = {
    isAdmin: PropTypes.any,
    isAdminView: PropTypes.bool,
    switchToAdmin: PropTypes.func,
    handleLogout: PropTypes.func,
    fullName: PropTypes.string,
    shadowName: PropTypes.string,
};

export default TopNavigation;
