import React, { Component } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import LoadingIndicator from '../components/LoadingIndicator';


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x  = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy  + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
		{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

class RenderTeacherStudentPieChart extends Component {
	constructor(props) {
        super(props);
        const stats_data = props.stats_data.tb_feedback;

        this.state = {
            isLoading: false,
			stats_data,
		};
	}
	
	render () {
        if (this.state.isLoading) {
            return (
                <LoadingIndicator message='Loading % talking times...' />
            )
        }

		if (this.state.stats_data !== undefined) {
            const totalTimes = this.state.stats_data.teacher_num + this.state.stats_data.student_num;
            const teacherPercent = totalTimes === 0 ? 0 : Math.round((this.state.stats_data.teacher_num * 100)/ totalTimes);
            const studentPercent = totalTimes === 0 ? 0 : Math.round((this.state.stats_data.student_num * 100)/ totalTimes);

			const DATA = [
				{name: 'Tutor', value: teacherPercent}, 
				{name: 'Student', value: studentPercent}
			];

			const COLORS = ['#c196d6', '#845998'];
			return (
				<PieChart width={320} height={400} onMouseEnter={this.onPieEnter}>
				<Pie
				data={DATA}
				cx={150} 
				cy={200} 
				labelLine={false}
				outerRadius={150} 
				label={renderCustomizedLabel}
				fill="#8884d8"
                dataKey="value"
                isAnimationActive={false}
				>

				{ DATA.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>) }
				</Pie>
				{/* <Legend /> */}
				<Tooltip  />
				</PieChart>
			);
		} return(<div></div>)
	}
}

export default RenderTeacherStudentPieChart;