import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import classNames from 'classnames';

const ReportTable = props => {
  const { columns, data } = props;
  //console.log(columns);
  return (
    <Table striped="columns" bordered  hover size="sm">
      <thead>
        <tr>
          {columns.map((c) => (
            <th key={`table-header-${Math.random()}`} className={'report-cell'}>{c.text}</th>
          ))
          }
        </tr>
      </thead>
      <tbody>
        {data.map((line) => (
          <tr>
            {columns.map((c) =>
              <td key={`table-row-${Math.random()}`} className={`report-cell ${c.min100 ? 'report-cell-min-width100' : ''}`}>{line[c.dataField]}{c.percent ? '%' : ''}</td>
            )}
          </tr>
        ))}
    
      </tbody>
    </Table>
  );
}

export default ReportTable;

