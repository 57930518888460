import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Table } from "react-bootstrap";
import { getCurrentUser } from './../libs/appData';

import "./Profile.css";

export default class Profile extends Component {
    constructor(props) {
        super();
        this.file = null;
        this.state = {
            isLoading: true,
            content: "",
            first_name: '',
            last_name: ''
        };

        const page_data = 'profile';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });
    }

    async componentDidMount() {
        try {
            const names = await this.getName();
            const first_name = names['first_name']
            const last_name = names['last_name']
            this.setState({ first_name, last_name, isLoading: false });
            //console.log(this.state.first_name, this.state.last_name)
        } catch (e) {
            // alert(e);
        }
    }

    getName = async() => {
        const email = encodeURI(getCurrentUser());
        const tmp = email.split('@')
        var first_name = ''
        var last_name = ''
        if (tmp[0].split('.').length === 2) {
            first_name = tmp[0].split('.')[0]
            last_name = tmp[0].split('.')[1]
        } else {
            first_name = tmp[0].split('.')[0]
        }
        //console.log(tmp, first_name, last_name)
        
        const names = {
            "first_name": first_name,
            "last_name": last_name,
        }
        return names;
    }

    validateForm() {
        return this.state.content.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleFileChange = event => {
        this.file = event.target.files[0];
    }

    handleSubmit = async event => {
        event.preventDefault();
    }


    render() {
        return (
            <div className="Profile">
                <div className="page-header">
                    Name: 
                    { this.state.first_name !== '' && (
                        " " + this.state.first_name.toLowerCase().charAt(0).toUpperCase() + this.state.first_name.toLowerCase().slice(1) + " "
                    )}
                    { this.state.last_name !== '' && (
                        this.state.last_name.toLowerCase().charAt(0).toUpperCase() + this.state.last_name.toLowerCase().slice(1)
                    )}
                    {/* {this.state.last_name.toLowerCase().charAt(0).toUpperCase() + this.state.last_name.toLowerCase().slice(1)} */}
              <div style={{ float: 'right' }}>
                <Link to="/change-password" className="btn btn-info btn-lg" style={{ marginBottom: 24 }}>
                  Change Password
                </Link>
              </div>

                </div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Label</th>
                            <th>Data</th>
                            {/* <th>Actions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                            <td className='center'><i className="far fa-user-circle"></i></td>
                            <td>Username</td>
                            <td>ImmaUser</td>
                            <td className='center'><i className="fas fa-edit"></i></td>
                        </tr> */}
                        <tr>
                        <td className='center'><i className="far fa-envelope"></i></td>
                             <td>Email</td>
                            <td>{ encodeURI(getCurrentUser()).toLowerCase() }</td>
                            {/* <td className='center'><i className="fas fa-edit"></i></td> */}
                        </tr>
                        {/* <tr>
                        <td className='center'><i className="fas fa-school"></i></td>
                             <td>School</td>
                            <td>Washington Elementary</td>
                            <td className='center'><i className="fas fa-edit"></i></td>
                        </tr>
                        <tr>
                        <td className='center'><i className="fas fa-chalkboard-teacher"></i></td>
                          <td>Classes</td>
                         <td>3rd grade math (morning), 3rd grade match (afternoon)</td>
                             <td className='center'><i className="fas fa-edit"></i></td>
                         </tr> */}
                    </tbody>
                </Table>
            </div>
        );
    }
}