import React from "react";
import { Button } from "react-bootstrap";

import "./NotFound.css";

export default () => {
  return (
  <div className="NotFound">
    <h3>Sorry, page not found!</h3>
    <p style={{marginTop: 24, marginBottom: 320}}>
    <Button href={'/'} variant="primary" >Home Page</Button>
    </p>
  </div>
  )
}