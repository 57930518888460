import React from 'react';
import '../scss/app.scss';

const LoadingPulser = props => (
  <div style={{margin: 'auto', width: 200, textAlign: 'center'}}>
    <div style={{ margin: '2em auto', width: 200, textAlign: 'center' }}>
      {props.message ? (
        <span className={props.classes} style={{ color: '#555' }}> {props.message} </span>
      ) : null}
    </div>
    <div className="lds-ripple" style={props.style || null }>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default LoadingPulser;
