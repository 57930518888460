export default {
  DEPLOYMENT_STAGE: 'production-talkmoves',  // local, stage-talkmoves, production-talkmoves, stage-TAPS, production-TAPS (use stage for Demo webapp)
  MAX_ATTACHMENT_SIZE: 5000000,
  UPLOAD_FILE_EXT_REGEX: '.[0-9a-z]+$',
  s3: {
    REGION: "us-east-1",
    BUCKET: "talkback-transcribe",
  },
  ManualUploads3: {
    REGION: "us-east-1",
    BUCKET: "guestuploads",
   },
  apiGateway: {
    REGION: "us-east-1",
     URL: {
      local: "http://localhost:1221/stage/",
      "stage-talkmoves": "https://r72ce9nhze.execute-api.us-east-1.amazonaws.com/stage/",
      "production-talkmoves": "https://ue6vr0q6v5.execute-api.us-east-1.amazonaws.com/prod/",
      "stage-TAPS": "https://m8gu86rimh.execute-api.us-east-1.amazonaws.com/stage/talkmoves4-api-stage-",
      "production-TAPS": "/production/talkmoves4-api-production-"
     }
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_HhWcDAXwZ",         // this is for talkmoves v4
    APP_CLIENT_ID: "5i9j7n06jevu2utsb42qgponj2", // talkmoves v4
    IDENTITY_POOL_ID: "us-east-1:b1a5f734-ce7b-4562-a3d5-02517dc599c7" // talkmoves v4
  },
  talkmoves: {
      IDLE_TIMEOUT_MINUTES: 30  // default in case can't read from system data
  }
};
