import React, { Component } from "react";

class RenderDataTable extends Component {
    constructor(props) {
        super()
        this.state = {
            tNames: props.talkMovesNames,
            tMessage: props.message,
            excludedCats: [
                'date', 'shortDate', 'videoId', 'videoDate', 'rigorous_thinking_num', 'content_knowledge_num', 'learning_community_num'
            ]
        }
    }


    render() {
        const totalLessons = this.props.data.length;
        const categories = Object.keys(this.props.data[0]);

        //     const excludedCats = [
        //     'date', 'videoId', 'videoDate', 'rigorous_thinking_num', 'content_knowledge_num', 'learning_community_num'
        // ]
        const tableData = categories.filter(cat => (!this.state.excludedCats.includes(cat))).map(cat => ({ category: cat, sum: 0, average: 0 }));

        const decimalFixed = this.props.isPercent ? 1 : 0;
        if (this.props.isPercent) {
            let totalPercent = 0;
   
            this.props.data.forEach(row => {
                tableData.forEach(item => {
    
                    if (item.category === 'rigorous_thinking_percent') {
                        const val = row.hasOwnProperty(item.category) ? row[item.category] : row.rigorous_thinking_percent;
                        item.sum += val;
                    } else {
                        item.sum += row[item.category];
                    }
                    totalPercent += row[item.category];

                })
            })

            tableData.forEach(item => {
                item.average = (item.sum * 100) / totalPercent;
            })
        } else {

            this.props.data.forEach(row => {
                tableData.forEach(item => {
                    if (item.category === 'rigorous_thinking_percent') {
                        const val = row.hasOwnProperty(item.category) ? row[item.category] : row.rigorous_thinking_percent;

                        item.sum += val;
                    } else {
                        item.sum += row[item.category];
                    }
                    item.average = (item.sum / totalLessons);
                })
            })
        }
        tableData.map(item => {
            this.state.tNames.map(item2 => {
                if (item.category === item2.dataKey) {
                    item.name = item2.name
                    item.color = item2.color
                    item.order = item2.order
                }
            })
        })
        tableData.sort((a, b) => a.order - b.order)
        let trendFinal = null;
        if (!this.props.isPercent) {
            trendFinal = tableData.map((item, index) =>
              <div style={{ margin: '7px 0px' }}><div style={{ display: 'inline-block', width: 40, color: item.color, fontWeight: 600, borderSize: 2, borderColor: item.color, borderStyle: 'solid', padding: '2px 3px', textAlign: 'center' }}> {item.average.toFixed(decimalFixed)}{this.props.isPercent ? '%' : ''}</div>
                <span style={{ color: item.color, fontWeight: 600 }}> {item.name} </span>
              </div>)
        } else {
            trendFinal = tableData.map((item, index) =>
                <div><span style={{ color: this.props.colors[index], fontWeight: 600 }}>{item.average.toFixed(decimalFixed)}{this.props.isPercent ? '%' : ''}</span> are in the <span style={{ color: this.props.colors[index], fontWeight: 600 }}><b>{item.name}</b></span> category</div>)
        }
        console.log(trendFinal);

        return (

            <div style={{ marginLeft: 78, lineHeight: '28px' }}>
                {trendFinal}
            </div>
        )
    }
}


export default RenderDataTable;

