import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts';
import moment from 'moment';

class SimpleBarChart extends Component{
  
    percentTick = (item) => {
        return(`${item}%  `)
    }
    handleDateClick = (event) => {
      const { data, history } = this.props;
      if (history) {
      let url;
      data.map(entry => {
          if(entry.shortDate === event.value) {
              
              url = '/video/' + entry.videoId
          }
      })
      history(url)
      }
  }
	render () {
    const { data, categories, colors, handleCatagoryCaptilization} = this.props;

    const dateList = [];
    data.forEach(item => {
      let itemTime = false;
      if (!item.date.startsWith('202')) {
        itemTime = moment(parseInt(item.date)).format('h A');
        item.date = moment(parseInt(item.date)).format('YYYY-MM-DD');

      }
      if (item.date) {
        // format in mm/dd/yy
        item.shortDate = `${item.date.substr(5, 2)}/${item.date.substr(8, 2)}/${item.date.substr(2, 2)} ${itemTime || ''}`;
        // remove leading 0
        item.shortDate = item.shortDate.replace(/^0/, '');
        // see if there's a time of day in the string
        if(item.date.length > 10) {
          // pop in the time with out the leading 0.. should be 24 hour time... 
          // if we want it shorter, we could just put in the hour like 7am, 11am, 5pm, etc.
          item.shortDate = `${item.shortDate} ${item.date.slice(11,16).replace(/^0/,'')}`;
        }

      } else {
        item.shortDate = `${item.videoDate.replace('th', ', ').replace('st', ',').replace('nd', ',').replace('rd', ', ')} `;
      }
      if (dateList.includes(item.shortDate)) {
        // this date already exists!!!
        item.shortDate += '(2) ';
        if (dateList.includes(item.shortDate)) {
          // this date already exists!!!
          item.shortDate = item.shortDate.replace('(2)', '(3)');
        }
      }
      dateList.push(item.shortDate)

      // update the data to be only 2 digits max
      categories.forEach(cat => {
        item[cat] = Math.round(item[cat] * 10) / 10
      })
      return item;
    });

  	return (
        <ResponsiveContainer width="100%" height={300}>
    	<BarChart  data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <CartesianGrid stroke="#e0e0e0" horizontal={false}/>
       <XAxis dataKey={'shortDate'} interval={0} fontSize={14} angle={-30} height={76} textAnchor="end" onClick={e => this.handleDateClick(e)}/>
       <YAxis tickFormatter={this.percentTick}/>
       <Tooltip/>
       {/* <Legend /> */}
        {
          categories.map((catagory,index) => {
          return <Bar name={handleCatagoryCaptilization(catagory.replace(/_/g," "))}  dataKey={catagory} fill={colors[index]} />  })
        }
       
      </BarChart>
      </ResponsiveContainer>
    );
  }
}
export default SimpleBarChart;