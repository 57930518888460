import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { Table, ProgressBar } from "react-bootstrap";
import LoadingPulser from "../components/LoadingPulser";
import { normalizeProjectTimeStamp, tutorialStatusLabel, showProcessingIncompleteMessage } from "../libs/utils";

const HomeCoach = props => {

  const [teachers, setTeachers] = useState({ list: [] });
  const [isLoadingCoaches, setIsLoadingCoaches] = useState(true);
  const [isLoadingTutors, setIsLoadingTutors] = useState(true);
  const [teacherVideos, setTeacherVideos] = useState({});
  const [coaches, setCoaches] = useState(false);
  const [videosLoaded, setVideosLoaded] = useState(0);
  const [isCoach, setIsCoach] = useState(props.isCoach);
  const { isAdmin, isHATResearcher } = props;
  const [ update, setUpdate ] = useState(false);

  const getTeachers = async () => {
    // get each teacher's info if an admin
    const teachers = await API.get('teachers', `teachers/geteach?source=SAGA${isCoach ? `&coachId=${isCoach}` : ''}${(isAdmin || isHATResearcher) ? `&getCoachesList=true` : ''}`);
    if (teachers) {
      teachers.list.forEach((t) => {t.loaded = false; t.open = false;});
      setTeachers(teachers);
    }
    setIsLoadingTutors(false);
    if (teachers.coaches) {
      setCoaches(teachers.coaches);
      setIsLoadingCoaches(false);
    }

  }

  const getTeacherVideos = async () => {
    if (teachers.list.length > 0) {
      // setIsLoading(true);
      const tv = teacherVideos;
      for (var i = 0; i < teachers.list.length; i++) {
        if (teachers.list[i].loaded === 'loading' && !tv[teachers.list[i].email]) {
          const videos = await API.get("teachers", `teachers/getvideos/${teachers.list[i].email}/nostats`);
          tv[teachers.list[i].email] = videos;
          setTeacherVideos(tv);
          setVideosLoaded(i + 1);
        }
      }
    }
  }

  useEffect(() => {
    getTeachers();
    if (isHATResearcher) { recordPageAction({'eventName': 'allTutors'}) }
  }, []);

  /*
   * useEffect[isCoach]
   *   Note: called even when isCoach is false
   *   Retrieve teachers.
   *   Record a user action based on whether isCoach or isHATResearcher or both are true.
   * 
   */
  useEffect(() => {
    if (isCoach) {
      let userAction = 'initialView';
      if (isHATResearcher) { userAction = 'selectCoach' }
      recordPageAction({'eventName': 'allTutors', 'action': userAction})
    }
    else if (isHATResearcher) { 
      recordPageAction({'eventName': 'allTutors', 'action': 'selectAllCoaches'})
    }
    getTeachers();
  }, [isCoach]);
  
  /* 
   * useEffect[teachers]
   *  the teachers (tutors) have loaded. Get their videos (tutorials).
   */
  useEffect(() => {
    if (teachers.list.length > 0 && videosLoaded === 0) {
         getTeacherVideos();
    }
  }, [teachers]);

  /* 
   * useEffect[videosLoaded]
   *  The tutorials have loaded. Set isLoading to false
   */
  useEffect(() => {
    // if (videosLoaded > 0 && videosLoaded === teachers.list.length) {
    //   setIsLoading(false);
    // }
  }, [videosLoaded])

  const switchToCoach = (coach) => {
    setIsCoach(coach.email);
    sessionStorage.removeItem('mostRecentVideo');
  }

  const switchToOneTutorial = (tutorId, name, videoId, isComplete) => {
    if (isComplete) {
      sessionStorage.removeItem('videosData');
      sessionStorage.setItem('tutorId', tutorId);
      sessionStorage.setItem('tutorName', name);
      const lastVideo = 0;
      if (teacherVideos[tutorId]) {
        sessionStorage.setItem('mostRecentVideo', teacherVideos[tutorId][lastVideo].tb_video_id);
      }
      props.history(`/video/${videoId}`);
    } else {
      showProcessingIncompleteMessage();
    }
  }


  const switchToAllTutorials = (tutorId, name) => {
    sessionStorage.removeItem('videosData');
    sessionStorage.setItem('tutorId', tutorId);
    sessionStorage.setItem('tutorName', name);
    const lastVideo = 0;
    if (teacherVideos[tutorId]) {
      sessionStorage.setItem('mostRecentVideo', teacherVideos[tutorId][lastVideo].tb_video_id);
    }
    props.history(`/videos`);
  }

  const toggleTutorOpen = async (tutor, index) => {
    const newTeachers = teachers;
    let mustLoad = false;
    newTeachers.list[index].open = !teachers.list[index].open;
    if (newTeachers.list[index].open && newTeachers.list[index].loaded === false) {
      newTeachers.list[index].loaded = 'loading';
      mustLoad = true;
    }
    setTeachers(newTeachers);
    setUpdate(!update);
    if(mustLoad) {
      await getTeacherVideos();
    }
  }

  const renderCoachesList = () => {
    return (
      <div>
        <div className='homecoach--tutor-row'>
        <hr style={{ marginTop: 24, background: 'black' }} />
            <h4>Coaches</h4>
          <div className='homecoach--tutor-table'>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th className='header-cell'>Coach Name</th>
                  <th className='header-cell'>Email</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(coaches).map((key) => {
                  const coach = coaches[key];
                  return (
                    <tr key={key} >
                      <td onClick={() => (switchToCoach(coach))}><span className='cell-hover' >
                        {coach.name}</span></td>
                      <td onClick={() => (switchToCoach(coach))}><span className='cell-hover' >
                        {coach.email}
                      </span></td>
                    </tr>
                  )
                }
                )}
                {/* final row to select all coaches (by deslecting any coach */}
                <tr >
                      <td onClick={() => (switchToCoach({name: 'all', email: false}))}><span className='cell-hover' >All Coaches</span></td>
                      <td> </td>
                    </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
  }

  /* recordPageAction
   *    Publish an event to our MixPanel subscriber to track the user, their characteristics
   *    and details of their action.
   *
   *    Input:
   *       objArgs {
   *          'eventName' : String,
   *          'action' : String || null,
   *          'properties' : String || props
   *       }
   *    Output:
   *       None
   *    Side effects:
   *       publishes MixPanel event
   */
  
  const recordPageAction = (objArgs) => {
    const properties = objArgs.properties || props;
      mixpanel.track(objArgs.eventName, {
      'action': objArgs.action || 'initialView',
      'subAction': 'none',
      'userAuthenticated': properties.isAuthenticated,
      'userIsCoach': properties.isCoach && true,
      'userIsResearcher': properties.isHATResearcher && true,
      'userId': sessionStorage.getItem('userId'),
      'userDistrict': sessionStorage.getItem('userDistrict')
    });
  }

  return (
    <div style={{ margin: '25px auto', maxWidth: 900 }}>

      <h3>Select a Tutor or Specific Tutorial</h3>
      {teachers.list.map((teacher, index) => (
        <div className='homecoach--tutor-row' key={`tutor-row=${Math.random()}`}>
           <i className={`fas fa-angle-right homecoach--caret-right ${teacher.open ? 'homecoach--caret-down' : ''}`} onClick={() => {toggleTutorOpen(teacher, index)}}></i>
          
          <div onClick={() => (switchToAllTutorials(teacher.email, teacher.name))} className='homecoach--tutor-name cell-hover'>{teacher.name}</div>
          <div className='homecoach--tutor-count'>{teacher.count} Tutorial(s)</div>
          {(isAdmin || isHATResearcher) && (
            <div onClick={() => (switchToCoach({name: teacher.coachName, email: teacher.coachId}))} className='homecoach--tutor-coach-name cell-hover'>Coach: {teacher.coachName}</div>
          )}
          <div className={teacher.open ? 'homecoach--list-panel-open' : 'homecoach--list-panel'}>
            {!teacherVideos[teacher.email] && teacher.loaded === 'loading' && (
              // <LoadingPulser message="Loading Tutorials..." />
              <ProgressBar animated now={100} label={`loading...`}style={{marginTop: 20}}/>
            )}
            {teacherVideos[teacher.email] && teacherVideos[teacher.email].length === 0 && (
              <div className='homecoach--tutor-table' style={{paddingTop: 20}}>
                No tutorials found.
              </div>
            )}
            {teacherVideos[teacher.email] && teacherVideos[teacher.email].length > 0 && (
              <div className='homecoach--tutor-table'>
                <Table bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Length</th>
                      <th>TM ID</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teacherVideos[teacher.email].map((video, index) => {
                      const isComplete = video.tb_project_state && video.tb_project_state.toLowerCase() === 'completed' ?  true : false; 
                      return (
                      <tr key={`tutor-videos=${Math.random()}`} >
                        <td onClick={() => (switchToOneTutorial(video.tb_teacher_id, video.tb_full_name, video.tb_video_id, isComplete))} style={{textAlign: 'center'}}><span className='cell-hover' >
                             {video.tb_video_title === video.tb_project_title ? 'Untitled' : video.tb_video_title}</span></td>
                        <td onClick={() => (switchToOneTutorial(video.tb_teacher_id, video.tb_full_name, video.tb_video_id, isComplete))} style={{textAlign: 'center'}}><span className='cell-hover' >
                          {normalizeProjectTimeStamp(video.tb_project_date)}
                        </span></td>
                        <td onClick={() => (switchToOneTutorial(video.tb_teacher_id, video.tb_full_name, video.tb_video_id, isComplete))} style={{textAlign: 'center'}}><span className='cell-hover' >{video.tb_audio_length}</span></td>
                        <td onClick={() => (switchToOneTutorial(video.tb_teacher_id, video.tb_full_name, video.tb_video_id, isComplete))} style={{textAlign: 'center'}}><span className='cell-hover' >TM-{video.tb_video_id}</span></td>
                        <td onClick={() => (switchToOneTutorial(video.tb_teacher_id, video.tb_full_name, video.tb_video_id, isComplete))} style={{textAlign: 'center'}}>
                          <span className='cell-hover' style={{color: `${isComplete ? 'green' : 'red'}`}} >
                          {tutorialStatusLabel(video.tb_project_state)}
                          </span></td>
                      </tr>
                    )}
                    )}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
      ))}

      {!isLoadingCoaches && teachers.coaches && (isAdmin || isHATResearcher) && renderCoachesList()}      

      {isLoadingTutors && (
        <div>
          <LoadingPulser message="Loading Tutors..." />
        </div>
      )}
      {isLoadingCoaches && (isAdmin || isHATResearcher) && (
        <div>
          <LoadingPulser message="Loading Coaches..." />
        </div>
      )}
    </div>
  )
}

export default HomeCoach;
