import React, { Component } from "react";
// import BootstrapTable from 'react-bootstrap-table-next';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

import { Table, Button, Tabs, Tab, Modal, FormGroup, FormControl } from "react-bootstrap";
import { getPageParameters } from './../libs/utils';
import { API } from "aws-amplify";
import LoadingIndicator from '../components/LoadingIndicator';

import "./Reports.css";
import LoadingPulser from "../components/LoadingPulser";
import ReportTable from "../components/ReportTable";

import teacherColumnXlate from '../data/teacherreport.json';
import aggTeacherColumnXlate from '../data/agg_teacherreport.json';

import { s3Upload, s3Download, s3Dir } from "../libs/awsLib";

import Moment from 'moment';
import Select from 'react-select';
import ReportDownload from "../components/ReportDownload";


// const { parse } = require('@fast-csv/parse');
const { Parser, transforms: { unwind, flatten } } = require('json2csv');


export default class Reports extends Component {
  constructor(props) {
    super();

    const { start } = getPageParameters(window.location.search);
    const activeTab = start ? start : 'teacher';

    const teacherColumns = [
      {
        dataField: 'id',
        text: '#',
        sort: true,
      },
    ];
    const aggregateColumns = [
      {
        dataField: 'id',
        text: '#',
        sort: true,
      },
    ];

    this.state = {
      //add a new state variable
      isLoading: true,
      activeTab,
      teacherColumns,
      aggregateColumns,
      // otherColumns,
      report: {
        err: null,
        type: activeTab,
        data: []
      },
      showUpload: false,
      showURLs: false,
      isLoadingURLs: false,
      isLoadingTeachers: true,
      selectedVideo: null,
      inDropZone: false,
      fileToUpload: null,

      teachers: [], // [ {label: "teacher1", value: "teacher1@email.com"}, .....]
      selectedTeacher: "All Tutors",
      selectedTeacherEmail: "",
      teacherList: [],
      selectedTeacherId: "",
      userHasSelectedTeacher: false,
      totalVideosInSytem: 0,
    };

    const page_data = 'reports';
    const path = start ? `/${start}` : '';
    // eslint-disable-next-line no-undef
    gtag('event', 'page_view', {
      page_title: page_data,
      page_location: `/${page_data}${path}`,
      page_path: `/${page_data}${path}`,
      send_to: 'UA-91361306-3'
    });
  }

  componentDidMount = async (event) => {
    const list = await this.getTeachersList();
    await this.getReport(this.state.activeTab, list);
  }

  getTeachersList = async () => {
    if (this.state.isLoadingTeachers) {
      const uniqueTeacherNamesAndEmails = [];
      let totalVideos = 0;
      const teacherList = await API.get('teachers', 'teachers/geteach');
      teacherList.list.forEach(function (item) {
        const i = uniqueTeacherNamesAndEmails.findIndex(x => item.name === x.label);
        if (item.name && i <= -1) {
          uniqueTeacherNamesAndEmails.push({ label: item.name, value: item.email });
          totalVideos += item.count;
        }
      });
      uniqueTeacherNamesAndEmails.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      })
      // uniqueTeacherNamesAndEmails.unshift({ label: `All Tutors`, value: "" });
      this.setState({ teachers: uniqueTeacherNamesAndEmails, isLoadingTeachers: false, totalVideosInSytem: totalVideos });
      return (uniqueTeacherNamesAndEmails);
    }
    return false;

  }

  getReport = async (type, teachersList = false) => {
    this.setState({
      report: {
        err: null,
        type: type,
        data: []
      },
      activeTab: type,
      isLoading: true
    });

    const { teacherColumns, aggregateColumns, selectedTeacherEmail, userHasSelectedTeacher } = this.state;
    const tl = teachersList || this.state.teachers;

    let useTeacherEmail = selectedTeacherEmail === '' ? 'full' : selectedTeacherEmail;
    if ( type === 'aggregate') {
      if (tl[0].label !== 'All Tutors') {
        tl.unshift({ label: `All Tutors`, value: "" });
        if (useTeacherEmail === 'full' || userHasSelectedTeacher === false) {
             useTeacherEmail = 'full';    // in case coming from the other report and user hasn't selected a teacher.
             this.setState({ selectedTeacher: tl[0].label, selectedTeacherEmail: tl[0].value } );
        }
      }
    } else {
      if (tl[0].label === 'All Tutors') {
        tl.shift(); // remove it.
      }
      if (useTeacherEmail === 'full') {
           this.setState({ selectedTeacher: tl[0].label, selectedTeacherEmail: tl[0].value } );
           useTeacherEmail = tl[0].value;
      }
    }
    this.setState({ teachers: tl });  // correct list for type of report

    const params = `?id=${useTeacherEmail}`;
    const report = await API.get('teachers', `teachers/getreport/${type}${params}`);
    //const teachId = await API.get('teachers', `teachers/getreport/${type}${params}`);
    //console.log(report);

    const newColumns = [];
    if (type === 'uploads') {
      newColumns.push({
        dataField: 'id',
        text: '#',
        sort: true,
      });
    } else {
      const xlate = type === 'aggregate' ? aggTeacherColumnXlate : teacherColumnXlate;
      xlate.forEach(nextCol => {
        if (nextCol["data field name"] !== 'skip') {
          const percent = nextCol['Column Header'].includes('Percent');
          const colData = {
            text: nextCol["Column Header"],
            dataField: nextCol["data field name"], // key.replace('tb_', '').replace(/_/g, ' ').replace('stats', ''),
            sort: true,
            percent,
            // style: { 'white-space': 'nowrap' }    
            style: {},
            min100: nextCol.min100 || false,
          };

          if (nextCol['width']) {
            colData.style.minWidth = nextCol['width'];
          }
          if (nextCol['centered'] === true || nextCol['Format:'] === 'number') {
            colData.style.textAlign = 'center';
          }
          newColumns.push(colData)
        }
      })
      // now build the rest of the columns....
      for (const key in report.data[0]) {
        // see if this key already in the columns
        let found = false;
      }

    }

    if (report) {
      if (type === 'aggregate') {
        this.setState({ report, aggregateColumns: newColumns, minutesMessage: report.minutesPerClassMessage });
      } else {
        this.setState({ report, teacherColumns: newColumns });
      }
    }

    const path = `transcripts/done/`;
    const csvFiles = false; // TODO = await s3Dir(path);
    this.setState({ csvFiles, isLoading: false });

  }

  //
  // ****************** upload manual transcript *******************
  //
  showUploadTranscriptModal = index => {
    const thisVideo = this.state.report.data[index];
    this.setState({ selectedVideo: thisVideo, fileToUpload: null, showUpload: 'transcript' });
  }

  showUploadAnnotationModal = index => {
    const thisVideo = this.state.report.data[index];
    this.setState({ selectedVideo: thisVideo, fileToUpload: null, showUpload: 'annotation' });
  }

  downloadAnnotation = async () => {

  }

  uploadTranscript = videoId => {
    this.setState({ showUpload: false })
  }

  onDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ inDropZone: true });
  }

  onDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ inDropZone: false });
  }

  onDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // this.setState({inDropZone: true});
  }

  downloadS3File = async (fullFileName, convertToJSON = false) => {

    const { videoData } = this.state;
    const fileParts = fullFileName.split('/');
    console.log('file parts: ', fileParts);
    const filePath = `${fileParts[3]}/${fileParts[4]}/${fileParts[5]}/`;
    const fileName = fileParts[6].replace('_0_', '_-_');
    const saveAsFileName = `${videoData.fullName}_${videoData.videoId}_${convertToJSON ? fileName.replace('.json', '.csv') : fileName}`;


    let fileData = await s3Download(filePath, fileName);
    if (convertToJSON) {
      try {
        //debugger;
        const transforms = [unwind({ paths: ['Sentences', 'results.speaker_labels.segments'] }), flatten({ objects: true, arrays: false })];
        const parser = new Parser({ transforms });
        fileData = parser.parse(JSON.parse(fileData));

      } catch (err) {
        console.error(err);
      }
    }
    const element = document.createElement('a'); // a type a (link) element

    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(fileData)}`
    );

    /* 
      Download attribute supported
    */
    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      const blobObject = new Blob([fileData]);
      window.navigator.msSaveOrOpenBlob(blobObject, saveAsFileName);

      /* 
        Download for Microsoft Edge?
      */
    } else if (typeof element.download !== "undefined") {
      element.setAttribute('download', saveAsFileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    this.setState({ showURLs: false });
  }

  downloadTranscriptCSV = async (csvName) => {
    const csvData = await s3Download('transcripts/done/', csvName.replace('transcripts/done/', ''));

    const element = document.createElement('a'); // a type a (link) element
    //debugger;
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(csvData)}`
    );

    /* 
      Download attribute supported
    */
    if (window.Blob && window.navigator.msSaveOrOpenBlob) {
      const blobObject = new Blob([csvData]);
      window.navigator.msSaveOrOpenBlob(blobObject, csvName);

      /* 
        Download for Microsoft Edge?
      */
    } else if (typeof element.download !== "undefined") {
      element.setAttribute('download', csvName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  processAnnotationCSV = async (fileToUpload) => {
    alert('not implemented at this time. Please contact technical support.');
    return;
  }
  uploadAnnotation = async (fileToUpload, selectedVideo, showUpload, path) => {
 
    const newJSON = await this.processAnnotationCSV(fileToUpload);
    //debugger;
    // upload the json string
    const projectName = selectedVideo.tb_project_title.replace(/ /g, '_').replace(/\?/g, '').replace(/\#/g, '').replace(/\//g, '');
    const fileNameEnd = `-${selectedVideo.tb_video_id}--manual-annotation.json`;
    const uploadResult = await s3Upload(
      JSON.stringify(newJSON),
      path,
      projectName,    // projectName
      `${projectName}-${fileNameEnd}`,  // actual file name (after path)
      "MM", // processType, 
      "MANUAL_ANNOTATION", // fileType, 
      `${projectName} - Manual annotation upload`,    // description 
      selectedVideo.tb_video_id,        // project/video id 
      Moment().format('YYYY-MM-DD'),  // project original date
    );
    console.log(uploadResult);
    alert(`Uploaded annotation with filename: ${uploadResult}`);
    this.setState({ showUpload: false, selectedVideo: null });
  }

  onUploadFile = async () => {
    const { fileToUpload, selectedVideo, showUpload } = this.state;
    //  const path = `stage/transcripts/uploads/`;
    let path = `transcripts/uploads/`;
    if (showUpload === 'annotation') {
      path = `prod/talkback/${selectedVideo.tb_video_id}/`
      this.uploadAnnotation(fileToUpload, selectedVideo, showUpload, path);
      return;
    }

    if (fileToUpload) {
      const projectName = selectedVideo.tb_project_title.replace(/ /g, '_').replace(/\?/g, '').replace(/\#/g, '').replace(/\//g, '');
      const fileNameEnd = showUpload === 'transcript' ? `--project-${selectedVideo.tb_video_id}--manual-transcript.docx` : `--project-${selectedVideo.tb_video_id}--manual-annotation.csv`;
      const uploadResult = await s3Upload(
        fileToUpload,
        path,
        projectName,    // projectName
        `${projectName}-${fileNameEnd}`,  // actual file name (after path)
        showUpload === 'transcript' ? "MA" : "MM", // processType, 
        showUpload === 'transcript' ? "MANUAL_TRANSCRIPT" : "MANUAL_ANNOTATION", // fileType, 
        `${projectName} - Manual ${showUpload} upload`,    // description 
        selectedVideo.tb_video_id,        // project/video id 
        Moment().format('YYYY-MM-DD'),  // project original date
      );
      console.log(uploadResult);
      alert(`Uploaded with filename: ${uploadResult}`);
      this.setState({ showUpload: false, selectedVideo: null });
    }
  }

  onDrop = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const dt = e.dataTransfer;
    const file = dt.files[0];
    const { showUpload } = this.state;

    this.setState({ inDropZone: false });
    //debugger;

    // validate files.
    if (dt.files.length > 1) {
      alert('At this time, you may only upload one file per video');
      return;
    }
    const nameParts = file.name.split('.');
    const extension = nameParts[nameParts.length - 1];
    if ((showUpload === 'transcript' && (extension !== 'docx' && extension !== 'pdf'))
      || (showUpload === 'annotation' && extension !== 'csv')) {
      alert('Sorry, this file type is not accepted for uploading.  For manual transcripts, only .docx and .pdf are accepted.  For annotation uploads, only .csv files are accepted.');
      return;
    }

    this.setState({ fileToUpload: dt.files[0] }, () => {
      this.onUploadFile();
    })
  }


  handleFileChange = event => {
    this.setState({ fileToUpload: event.target.files[0] })

  }

  // ***************** see if csv file is ready to download ********
  //
  displayCSVDownload = (selectedVideo) => {
    const { csvFiles } = this.state;
    if (csvFiles && csvFiles.length > 0 && selectedVideo && selectedVideo.tb_project_title) {
      // example csv name: transcripts/done/what_is_the_whole_11320---project-160--manual-transcript-converted.csv
      const csvName = `transcripts/done/${selectedVideo.tb_project_title.replace(/ /g, '_').replace(/\?/g, '').replace(/#/g, '').replace(/\//g, '')}---project-${selectedVideo.tb_video_id}--manual-transcript-converted.csv`;
      if (csvFiles.includes(csvName)) {
        return (
          <Button
            onClick={() => (this.downloadTranscriptCSV(csvName))}
            size="sm"
            variant="success"
            style={{ marginRight: 24, marginBottom: 12 }}
          >
            Download CSV
          </Button>
        )
      }
    }
    return null;
  }
  //
  // ****************** show urls related to video *******************
  //
  showURLsModal = async (index) => {
    const thisVideo = this.state.report.data[index];
    this.setState({ selectedVideo: thisVideo, showURLs: true, isLoadingURLs: true });

    const email = encodeURI(thisVideo.tb_teacher_id);

    const video_id = thisVideo.tb_video_id;
    const videoData = await API.get(`teachers`, `teachers/getvideo/${email}/${video_id}`);
    videoData.transcript = videoData.transcript.replace('trasnscript', 'transcript');
    const talkmoves = videoData.talkmoves.split('?');
    videoData.talkmoves = talkmoves[0];
    videoData.videoId = thisVideo.tb_video_id;
    videoData.fullName = thisVideo.tb_full_name.replace(/ /g, '_');
    //debugger;
    this.setState({ videoData, isLoadingURLs: false })
  }


  selectTeacherhandleChange = data => {
    // single object from select
    // data: {label: "Teacher1", value:"teacher1@email.com"}
    this.setState({ selectedTeacher: data.label, selectedTeacherEmail: data.value, userHasSelectedTeacher: true }, () => {
      // get the data
      this.getReport(this.state.activeTab);
    });


  };
 
  render() {
    const { isLoading, isLoadingTeachers, isLoadingURLs, selectedVideo, videoData, selectedTeacher, selectedTeacherEmail } = this.state;
    // const { ExportCSVButton } = CSVExport;
    //if (!isLoadingTeachers) {
    //  debugger;
    //}

    const selectValue = {label: selectedTeacher, value: selectedTeacherEmail};

    return (
      <div className="Report Page">
        <div className="page-header">
          <div className="row">
            <div>Research Data</div>
            {this.state.isLoadingTeachers ?
              <div></div>
              :
              <span>
                <div className="teacher-select">
                  <Select 
                    // placeholder={`All Tutors `} 
                    // defaultValue={this.state.teachers[0]} 
                    // inputValue={selectedTeacher}
                    value={selectValue} 
                    options={this.state.teachers} 
                    onChange={this.selectTeacherhandleChange} />
                </div>


                <span className="teacher-select">{this.state.selectedTeacherEmail ? `ID: ${this.state.selectedTeacherEmail}` : ''}</span>
              </span>
            }
          </div>
          <ReportDownload 
              selectedTeacher={this.state.selectedTeacher}
              type={this.state.activeTab}
              data={this.state.report.data}
              columns={this.state.activeTab === 'teacher' ? this.state.teacherColumns : this.state.aggregateColumns}
              totalVideos={this.state.totalVideosInSytem}
              />
        </div>
        <Tabs defaultActiveKey={this.state.activeTab} onSelect={k => this.getReport(k)} >
          <Tab eventKey="teacher" title="Tutorial-by-tutorial">
            <>
              {isLoading && this.state.activeTab === 'teacher' ?
                <LoadingPulser message='Building report...' />
                :
                <ReportTable
                  columns={this.state.teacherColumns}
                  data={this.state.report.data}
                />
                // <ToolkitProvider

                //     keyField="id"
                //     data={this.state.report.data}
                //     columns={this.state.teacherColumns}
                //     // exportCSV={{
                //     //     fileName: 'teachersReport.csv'
                //     // }}
                // >
                //     {
                //         props => (
                //             <div>
                //                 {/* <ExportCSVButton 
                //                 {...props.csvProps} className="btn btn-info btn-sm btn-vert download-btn" >Download Report</ExportCSVButton> */}
                //                 <BootstrapTable {...props.baseProps} striped={true} hover={true} />
                //             </div>
                //         )
                //     }
                // </ToolkitProvider>
              }
            </>
          </Tab>
          <Tab eventKey="aggregate" title="Aggregate">
            <>
              {isLoading && this.state.activeTab === 'aggregate' ?
                <LoadingPulser message='Building report...' />
                :
                <>
                  <div style={{ fontStyle: 'italic', fontSize: 11, paddingTop: 12 }}>* {this.state.minutesMessage}</div>
                  <ReportTable
                    columns={this.state.aggregateColumns}
                    data={this.state.report.data}
                  />
                </>
              }
            </>
          </Tab>

          <Tab disabled={true} eventKey="uploads" title="" > {/* title="Uploads" > */}
            <>
              {isLoading && this.state.activeTab === 'uploads' ?
                <LoadingIndicator message='Gathering teacher data...' />
                :
                <div>
                  {/* This is the format of the table we would like for the dowpdown */}
                  {/* Search react boostrap table*/}
                  {/* https://react-bootstrap.github.io/components/table/*/}
                  <Table striped bordered hover>
                    <thead align="center">
                      <tr>
                        <th >Name</th>
                        <th >Email</th>
                        <th >Date</th>
                        <th >Title</th>
                        <th >Video ID</th>
                        <th >Length</th>
                        {/* <th >Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.report.data.length > 0 && this.state.report.data.map((video, index) => (
                        <tr key={`report-row-${index}`}>
                          <td style={{ maxWidth: 100 }}>{video.tb_full_name}</td>
                          <td style={{ overflow: 'hidden', maxWidth: 150 }}>{video.tb_teacher_id}</td>
                          <td>{video.tb_project_date}</td>
                          <td style={{ maxWidth: 150 }}>{video.tb_project_title}</td>
                          <td style={{ textAlign: 'center' }}>{video.tb_video_id} ({video.tb_type})</td>
                          <td>{video.videoLength}</td>
                          <td>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                </div>
              }
            </>
          </Tab>
        </Tabs>
        {/* upload modal */}
        <Modal
          show={this.state.showUpload}
          onHide={() => this.setState({ showUpload: false })}
          size="lg"
          aria-labelledby="upload-modal-title-vcenter"
          centered
        >
          {selectedVideo && (
            <>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <div>
                    Upload {this.state.showUpload === 'transcript' ? 'Transcript' : 'Annotation'} for Video #{selectedVideo.tb_video_id}</div>
                  <div style={{ fontSize: 14 }}>
                    {selectedVideo.tb_full_name}<br />
                    {selectedVideo.tb_project_title} recorded on {selectedVideo.tb_project_date}

                  </div>
                </Modal.Title>

              </Modal.Header>
              <Modal.Body>

                <div style={{ fontSize: 18, textAlign: 'center', paddingTop: 24 }}>

                  Drop  {this.state.showUpload === 'transcript' ? 'Transcript' : 'Annotation'}
                  <br /><br />or
                  <div style={{ width: 250, margin: '20px auto' }}>
                    <FormGroup controlId="file">
                      <label>Select from Your Computer:</label>
                      {this.state.showUpload === 'transcript'
                        ? <FormControl onChange={this.handleFileChange} type="file" accept=".docx,.pdf" />
                        : <FormControl onChange={this.handleFileChange} type="file" accept=".csv" />

                      }
                    </FormGroup>
                  </div>


                  <div style={{
                    height: 250, minWidth: 250, maxWidth: 400,
                    margin: 'auto', marginTop: -200, borderRadius: 5,
                    border: '1px dotted gray',
                    backgroundColor: `${this.state.inDropZone ? '#ccc' : 'transparent'}`
                  }}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                  >

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="default" onClick={() => this.setState({ showUpload: false })}>Cancel</Button>
                {this.state.fileToUpload && (
                  <Button onClick={this.onUploadFile}>Upload</Button>
                )}
              </Modal.Footer>
            </>
          )}
        </Modal>
        {/* urls modal */}
        <Modal
          show={this.state.showURLs}
          onHide={() => this.setState({ showURLs: false })}
          size="lg"
          aria-labelledby="upload-modal-title-vcenter"
          centered
        >
          {this.state.showURLs && (
            <>
              <Modal.Header closeButton>
                <Modal.Title id="showurls-modal-title-vcenter">
                  <div>S3 Bucket URLs for Video #{selectedVideo.tb_video_id}</div>
                  <div style={{ fontSize: 14 }}>
                    {selectedVideo.tb_full_name}<br />
                    {selectedVideo.tb_project_title} recorded on {selectedVideo.tb_project_date}

                  </div>
                </Modal.Title>

              </Modal.Header>
              <Modal.Body>
                <div style={{ fontSize: 14, fontWeight: 600 }}><span style={{ fontSize: 18 }}>URLs</span>
                  <div style={{ minHeight: 250, maxWidth: '80%', margin: 24, overflowWrap: 'break-word' }}>
                    {isLoadingURLs ?
                      <LoadingIndicator message='Looking up URLs...' />
                      : <div>
                        <h3>Download by clicking on the links below</h3>

                        <div style={{ marginBottom: 12 }} onClick={() => (this.downloadS3File(videoData.transcript))}>
                          AWS TRANSCRIPT:  <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>{videoData.transcript}</a>
                        </div>

                        <div style={{ marginBottom: 12 }} onClick={() => (this.downloadS3File(videoData.sentencesJSON))}>
                          SENTENCES IN JSON:  <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>{videoData.sentencesJSON}</a>
                        </div>
                        <div style={{ marginBottom: 12, marginLeft: 24 }} onClick={() => (this.downloadS3File(videoData.sentencesJSON, true))}>
                          <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>Download as CSV</a>

                        </div>
                        <div style={{ marginBottom: 12 }} onClick={() => (this.downloadS3File(videoData.sentencesVTT))}>
                          SENTENCES IN VTT:  <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>{videoData.sentencesVTT}</a>
                        </div>
                        <div style={{ marginBottom: 12 }} onClick={() => (this.downloadS3File(videoData.talkmoves))}>
                          TALKMOVES:  <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>{videoData.talkmoves}</a>
                        </div>
                        <div style={{ marginBottom: 12, marginLeft: 24 }} onClick={() => (this.downloadS3File(videoData.talkmoves, true))}>
                          <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>Download as CSV</a>

                        </div>
                        <div style={{ marginBottom: 12 }} onClick={() => (this.downloadS3File(videoData.sentencesJSON.replace('sentences.json', 'feedback.json')))}>
                          TALKMOVES FEEDBACK:  <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>{videoData.sentencesJSON.replace('sentences.json', 'feedback.json')}</a>
                        </div>
                        <div style={{ marginBottom: 12, marginLeft: 24 }} onClick={() => (this.downloadS3File(videoData.sentencesJSON.replace('sentences.json', 'feedback.json'), true))}>
                          <a href={'#'} style={{ fontWeight: 400, marginLeft: 6 }}>Download as CSV</a>

                        </div>
                      </div>}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.setState({ showURLs: false })}>Close</Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </div>
    );
  }
}
