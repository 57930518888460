import React, { Component } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import LoadingIndicator from '../components/LoadingIndicator';



const RADIAN = Math.PI / 180;
const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x  = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy  + radius * Math.sin(-midAngle * RADIAN);

	return (
		// <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'}dominantBaseline="central">
		// {`${(percent * 100).toFixed(0)}%`}
        // </text>
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'}dominantBaseline="central">
		{`${Math.round(percent*100)}%`}
		</text>
	);
};

class RenderTalkmovesPieChart extends Component {
	constructor(props) {
		super();

        this.state = {
            isLoading: false,
			video_id: [],
			stats_data: props.stats_data.tb_feedback.category,
		};
	}

   	
	render () {
        if (this.state.isLoading) {
            return (
                <LoadingIndicator message='Loading talk moves...' />
            )
        }
        let DATA;
        if( this.props.students) {
            DATA = [
                {name: 'Learning Community', value: Math.round(this.state.stats_data.student.learning_community_percent)}, 
                {name: 'Content Knowledge', value: Math.round(this.state.stats_data.student.content_knowledge_percent)},
                {name: 'Rigorous Thinking', value: Math.round(this.state.stats_data.student.rigorous_thinking_percent)},
            ];
        } else {
            DATA = [
                {name: 'Learning Community', value: Math.round(this.state.stats_data.teacher.learning_community_percent)}, 
                {name: 'Content Knowledge', value: Math.round(this.state.stats_data.teacher.content_knowledge_percent)},
                {name: 'Rigorous Thinking', value: Math.round(this.state.stats_data.teacher.rigorous_thinking_percent)},
            ];
        }


		const COLORS = ['#f6cc46', '#3dc9c4', '#ff7359'];
		return (
            // <PieChart width={350} height={260} onMouseEnter={this.onPieEnter}>
            <PieChart width={400} height={280} onMouseEnter={this.onPieEnter}>
			<Pie
			  data={DATA} 
			  dataKey="value"
			  cx={175} 
			  cy={120} 
			  labelLine={false}
			  label={renderCustomizedLabel2}
			  outerRadius={120} 
              fill="#8884d8"
              dataKey="value"
              isAnimationActive={false}
			>
			{ DATA.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>) }
			</Pie>
			{/* <Legend /> */}
			<Tooltip />
			</PieChart>
		);
	}
}

export default RenderTalkmovesPieChart;