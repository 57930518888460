import React, { Component } from "react";
import { API } from "aws-amplify";
import { Table } from "react-bootstrap";
import LoadingPulser from "../components/LoadingPulser";
export default class Shadow extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            teachers: { totalTeachers: 0, totalVideos: 0, list: [] },
            isLoading: true,
        };

    }

    componentDidMount() {
        this.getTeachers();
    }

    getTeachers = async () => {
        // get each teacher's info if an admin
        const teachers = await API.get('teachers', 'teachers/geteach');
        if (teachers) {
            this.setState({ teachers, isLoading: false });

        }
    }

    switchTo = (userId, name) => {
        const admin = sessionStorage.getItem('isAdmin');
        if ( admin === userId ) {
            sessionStorage.removeItem('shadowId');
            sessionStorage.removeItem('shadowName');
            this.props.setShadowName(null);
        } else {
            sessionStorage.setItem('shadowId', userId);
            sessionStorage.setItem('shadowName', name);
            this.props.setShadowName(name);
        }
        sessionStorage.removeItem('videosData');
        //alert(`Switching to: ${name}`);
        this.props.history('/');
    }

    render() {
        const { teachers } = this.state;
        return (
            <div style={{ margin: '85px auto', maxWidth: 900 }}>

                <div className="page-header">Set User</div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{this.props.isCoach ? 'Tutor' : 'Teacher/Tutor'}</th>
                            <th>Email</th>
                            <th>Videos</th>
                            <th>Last Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teachers.list.map((teacher, index) => (
                            <tr >
                                <td onClick={()=>(this.switchTo(teacher.email, teacher.name))}align="center"><span className='cell-hover' >{index + 1}</span></td>
                                <td onClick={()=>(this.switchTo(teacher.email, teacher.name))}><span className='cell-hover' >{teacher.name}</span></td>
                                <td onClick={()=>(this.switchTo(teacher.email, teacher.name))}><span className='cell-hover' >{teacher.email}</span></td>
                                <td align="center">{teacher.count}</td>
                                <td>{teacher.lastAccess}</td>
                            </tr>

                        ))}
                    </tbody>
                </Table>
                {this.state.isLoading && (
                  <div>
                     <LoadingPulser message={this.props.isCoach ? 'Loading tutors...' : 'Loading teachers...'} />
                  </div>
                )}
            </div>
        );

    }
}
