import queryString from 'query-string';
import moment from 'moment';

export function getPageParameters(qs) {
    if (typeof qs !== 'string') return {};
    return queryString.parse(qs, {
      parseNumbers: true,
      parseBooleans: true
    });
  }

export function trackEvent(eventName, data = {}) {

    let username = sessionStorage.getItem('cname');
    if (!username) username = 'No user';
    data.userId = username;
    // eslint-disable-next-line no-undef
    gtag('event', eventName, data );
}


/*  normalizeProjectTimeStamp(time)
 *
 *    The timestamps recorded in the projects table have
 *    historically, come in different formats. Now (2023-04) we
 *    have settled, for better or worse, on a date string of the
 *    form "2023-03-23 18:30" where UTC is assumed. Because of
 *    this, you see the "+ ':00Z'" in the code below.
 *
 *    We want to localize the timestamp to the user's time zone,
 *    so for the modern case, we use Date.toLocaleString().
 *
 *    Returns a localized, human readable date and time string.
 */
export function normalizeProjectTimeStamp(time) {

  if (!time) {
    return '';
  }
  if (time.startsWith('202')) {
    if (time.length === 10) { 
      return ((moment.utc(time).format('MMMM DD, YYYY')))
    }
    else {
      const dateOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }      
      const returnDate = new Date(time + ':00Z').toLocaleString('en-US', dateOptions);
      return returnDate;
    }
  }
  else {
    return (moment.utc(parseInt(time))).format('MMMM DD, YYYY - H:mm A');
  }
}


/* tutorialStatusLabel(projectState)
 *   Utility function to return a human readable label associated with the
 *   current state of tutorial processing.
 * 
 *   Returns: 'Ready' | 'Processing'
 */
export function tutorialStatusLabel(projectState) {
  return projectState && projectState.toLowerCase() === 'completed' ?  'Ready' : 'Processing';
}

/* showProcessingIncompleteMessage()
 * 
 *   Tell the user that the tutorial they have selected does not
 *   yet have feedback associated with it.
 */
export function showProcessingIncompleteMessage() {
  alert(`Processing for this tutorial is not yet complete.`);
}