import React, { Component } from "react";
//import { Link } from "react-router-dom";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Container, Row, Col, Image } from "react-bootstrap";

// import { getPageParameters } from './../libs/utils';
//import { API } from "aws-amplify";
//import axios from 'axios';
//import { getCurrentUser } from './../libs/appData';
//import SimpleBarChart from "../components/RenderSimpleBarChart";
import "./HomeV2.css";
//import LoadingIndicator from "../components/LoadingIndicator";

const pdfURL = 'https://talkmoves-content.s3.amazonaws.com/Talk+Moves+Defined+(teacher+%26+student)-test.pdf';
const swivlcomp = require('../img/images/swivl-components.png')
const swivlProduct = require('../img/images/swivl-product-img.png')
const swivlsetup = require('../img/images/swivl2.png')
const swivlipad = require('../img/images/swivl3.png')
const swivllens = require('../img/images/swivl4.png')
const swivlmarkers = require('../img/images/swivl5.png')
export default class Recording extends Component {

    constructor(props) {
        super();
        this.ref = React.createRef();
        this.state = {
            navMargin: 0,
            navSelected: 0,
            topHeight: 0,
            menuOffset: 75
        }
        this.navClick = this.navClick.bind(this);

        const page_data = 'recording-with-swivl';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });
    }
    navClick(e) {
        this.setState(
        {
            navSelected: e.currentTarget.id
        });
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0,  behavior: 'smooth'});
        window.addEventListener('scroll', this.handleScroll.bind(this));
    } 
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
    }
    handleScroll(e) {
        const scrollPosition = window.scrollY;

            if (scrollPosition > this.state.topHeight) {
                this.setState({ navMargin: scrollPosition - this.state.topHeight})
            } else {
                this.setState({ navMargin: 0 })
            }
            if(this.ref.current != null) {
                this.setState({ topHeight: this.ref.current.offsetHeight, menuOffset: this.ref.current.offsetTop});
            }
    }

    scrollTo = id => {
        const scrollToElement = document.getElementById(id);
        scrollToElement.scrollIntoView({alignToTop: 'true', behavior: 'smooth' });
    };


    render() {
        return (
            <Container fluid>
                    {/* SWIVL Menu*/}
                    <Row ref={this.ref}>
                        <Col ></Col>
                        <Col xs={12} sm={11}  className="light-grey center round quoteShadow sans" style={{margin: '2rem'}} >
                            <Row noGutters="true" >
                                <Col sm={5} className="margin-auto">
                                    <Image fluid src={swivlProduct} style={{marginLeft: '2rem'}} />
                                </Col>
                                <Col sm={7} className="margin-auto">
                                    <h1 className="sans">Recording with SWIVL</h1>
                                </Col>
                            </Row>
                        </Col>
                        <Col ></Col>
                    </Row>
                    <Row>
                        <Col sm={3} className="d-none d-sm-block leftFaq blueText" style={{ marginTop: this.state.navMargin }}>
                            <h4 id="1" onClick={this.navClick} className={this.state.navSelected === '1' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-what') }}>What is a SWIVL?</a>
                            </h4>
                            <h4 id="2" onClick={this.navClick} className={this.state.navSelected === '2' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-setup') }}>How do I set up the SWIVL?</a>
                            </h4>
                            <h4 id="3" onClick={this.navClick} className={this.state.navSelected === '3' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-ipad') }}>How do I set up the ipad?</a>
                            </h4>
                            <h4 id="4" onClick={this.navClick} className={this.state.navSelected === '4' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-lens') }}>Putting the expanded lens on the ipad</a>
                            </h4>
                            <h4 id="5" onClick={this.navClick} className={this.state.navSelected === '5' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-markers') }}>How do I set up the markers?</a>
                            </h4>
                            <h4 id="6" onClick={this.navClick} className={this.state.navSelected === '6' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-record') }}>How do I record and upload videos?</a>
                            </h4>
                            <h4 id="7" onClick={this.navClick} className={this.state.navSelected === '7' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('swivl-help') }}>Who do I contact if I need help or have questions?</a>
                            </h4>
                        </Col>
                        <Col xs={12} sm={9} className="content" >
                        <div id="swivl-what" className="anchor"> </div>
                            <div>
                                <h4>What is a SWIVL?</h4>
                                <p >The SWIVL is a robotic device that tracks the speaker. You will use it together with an iPad to record your lessons.</p>
                                <Image src={swivlcomp} fluid className="record-img-margin"style={{width:'100%', maxWidth:700}} 
                                />
                            </div>
                        <div id="swivl-setup" className="anchor"></div>
                            <div>
                                <h4>How do I set up SWIVL?</h4>
                                    <p >The SWIVL can be put on a tripod, or it can sit on a flat surface. We recommend putting the SWIVL on a tripod and positioning the tripod near the middle of the classroom. </p>
                                    <Image src={swivlsetup} className="record-img-margin" fluid style={{width: '100%', maxWidth:700 }}/>
                                    <p>To charge the SWIVL: Connect the black AC adapter to the charging block via USB port, and plug the charger into the back of the SWIVL.</p>
                                    <p>To turn on the SWIVL: Push the power button. Both lights in the front should turn green. If they don’t, try turning the SWIVL off & on or checking that everything is plugged in. You can also do a “hard reboot” by holding down the power button for 8 seconds.</p>
                                    <p>Sometimes, the individual markers need to be updated, though this typically isn’t necessary. When updating the markers, you simply put them individually in the dock of the SWIVL, and this automatically updates them, as you will see on the i-Pad. </p>
                            </div>
                        <div id="swivl-ipad" className="anchor"></div>
                            <div>
                                <h4>How do I set up the iPad</h4>
                                    <p>Make sure that the iPad is charged before use. It will partially charge when connected to the SWIVL, but if the charge is very low to begin with, it won’t work.</p>
                                    <p>Connect the iPad and the SWIVL: Using the white cord, connect one end into the iPad and the other into the back of the SWIVL.</p>
                                    <Image src={swivlipad} fluid className="record-img-margin" style={{width: '100%', maxWidth:700 }}/>
                                    <p>Open the SWIVL application. Make sure the iPad is connected to the internet (go to Settings -&gt; Wifi), otherwise SWIVL will not work.</p>
                            </div>
                        <div id="swivl-lens" className="anchor"></div>
                            <div>
                                <h4>Putting the expanded lens on the iPad</h4>
                                <Row>
                                    <Col xs={12} sm={4}>
                                        <Image src={swivllens} className="record-img-margin" fluid style={{width: '100%', maxWidth:250 }}/>
                                    </Col>
                                    <Col xs={12} sm={8} style={{margin:'auto'}}>
                                        <p>Put the expanded lens on the ipad over the camera & take the cap off. This isn’t absolutely necessary but it will provide a much better view of the classroom.Put the expanded lens on the ipad over the camera & take the cap off. This isn’t absolutely necessary but it will provide a much better view of the classroom</p>
                                    </Col>
                                    
                                </Row>
                            </div>
                        <div id="swivl-markers" className="anchor"></div>
                            <div>
                                <h4>How do I set up the markers?</h4>

                                <p>Each SWIVL comes with five markers which are used to capture the audio. The primary marker tells the SWIVL where to turn. You can tell which one is the primary marker because it has a longer lanyard, which should be worn by the teacher around their neck. </p> 

                                <p>The other markers should be placed around the room, so we get good audio quality from the students. Use your best judgement about where to place these, but ideally they will be at equal distances around the room near students (i.e., on their desks). Instruct the students not to tap, turn off, or touch any of the buttons on the markers.</p>

                                <p>Before recording, turn on each marker by holding down the power button (the top button on the side). The top light should</p>

                                <p>After recording, turn off all the markers so as to not wear down the batteries.</p>

                                <p>To check the amount of battery life left in each marker, go to the SWIVL app and click on the marker icon. That will visually indicate the battery life for each color marker. It is important to do this BEFORE filming, as you can not charge the markers while recording.</p>

                                <p>To charge the markers: It’s a good idea to charge the markers after each use. Connect the black cord with the jack at the end into the charging block via a USB port, and insert the jack into the markers. There are two of these charging cords - so you can charge 4 markers with them. The fifth marker can go in the base of the SWIVL, and it will charge if the SWIVL is charging.</p>
                                <Image src={swivlmarkers} className="record-img-margin" fluid style={{width: '100%', maxWidth:700 }}/>

                            </div>
                        <div id="swivl-record" className="anchor"></div>
                            <div>
                            <h4>How do I record and upload videos?</h4>
                                <p>To record, press the capture button and/or the record (circle) button on the iPad using the SWIVL app. You’ll know it’s recording if the circle shows a red square in the middle. To stop recording, press the record button again. </p>

                                <p>Once you are finished recording, the SWIVL will automatically want to upload the video. (You do have the option to delete the video, if you’d like.) To upload, give the video a name by changing the TITLE. You can just leave the description as it is (or you can change it). You do not need to Preview & Trim. Then press DONE, and it will upload within a few minutes.</p>

                                <p>To view the video, exit out of the capture screen & go to the SWIVL Library. The most recently uploaded video will be at the top. </p>

                            </div>
                        <div id="swivl-help" className="anchor"></div>
                            <div>
                            <h4>Who do I contact if I need help or have questions?</h4>
                                <p>The <a targert="_blank" rel="noopener noreferrer" href="https://www.swivl.com">SWIVL</a> website is very helpful, as are the folks at <a href="https://swivl.zendesk.com/hc/en-us" target="_blank"rel="noopener noreferrer" >SWIVL’s customer service.</a></p>
                                <p>The web also has lots of info and user supports related to SWIVLs, for example on Youtube.</p>
                            </div>
                    </Col>
                </Row>
        </Container>
        );
    }
}