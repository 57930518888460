import { Storage } from "aws-amplify";
// import $ from 'jquery';

/*
await Storage.get(key: string, config: {
  level?: private | protected | public, // defaults to `public`
  identityId?: string, // id of another user, if `level: protected`
  download?: boolean, // defaults to false
  expires?: number, // validity, in seconds. defaults to no expiry
  contentType?: string // set return content type, eg "text/html"
})

EXAMPLE
const data = await Storage.get(`filename.txt`, { download: true })

// data.Body is a Blob
data.Body.text().then(string => { 
  // handle the String data return String 
})

UPLOAD POLICY - https://console.aws.amazon.com/iam/home#/policies/arn:aws:iam::813736767038:policy/play_and_upload_videos$jsonEditor
*/


export async function s3Upload(file, path, projectName, filename, processType, fileType, description = 'none', videoId = 0, date = 'unknown') {
    //  const filename = `${Date.now()}-${file.name}`;

    const isJSON = file.size ? false : true;
    const fileSize = isJSON ? file.length : file.size;
    const fileTextType = isJSON ? "application/json" : file.type;

    const teacher = sessionStorage.getItem('email');
    const now = new Date().toString().split('-');
    const metadata = { 
        'tb-file-description': description,
        'tb-bucket-key': `${path}${filename}`,
        'tb-source-system': 'talkmoves',
        'tb-source-version': "1",
        'tb-source-content-length': `${fileSize}`,
        'tb-bucket-key-base': `${path}${projectName}`,
        'tb-project-date': date,
        'tb-teacher-id': teacher,
        'tb-last-modified': now[0],     // date through GMT
        'tb-file-type': fileType.toUpperCase(),
        'tb-video-id': videoId,
        'tb-file-name': filename,
        'tb-process-type': processType.toUpperCase(),
        'tb-type': processType.toUpperCase()
     };

    // NOTE: use Storage.valut.put() for private files.
    try {

        const fullFileName =  `${path}${filename}`;
        const stored = await Storage.put(fullFileName, file, {
            contentType: fileTextType,
            metadata,
            customPrefix: { public:''},
            resumable: true
        });

        console.log(`File upload data: ${stored}`)
        return stored.key;
    } catch (err) {
        console.log(`s3 upload error: ${err}`);

        return null;
    }
}

export async function s3Dir(path) {
  // to do: remove
  alert('upload needs to be fixed');
    const s3 = new AWS.S3({
        useAccelerateEndpoint: true
    });
    const params = {
        Bucket: "talkback-transcribe",
        Prefix: path
        // Key: "transcripts/done/",
        // Expires: expire_seconds
    };
    const allKeys = [];

    try {
        const data = await s3.listObjectsV2(params).promise();
        var contents = data.Contents;
        contents.forEach(function (content) {
            if (content.Key !== path) {
                allKeys.push(content.Key);
            }
        });


        return allKeys;

    } catch (err) {
        console.log(`s3 dir error: ${err}`);
        return [];
    }
}

const AWS = require('aws-sdk');

export async function s3Download(path, filename) {
    //  const filename = `${Date.now()}-${file.name}`;
  alert('to do: refactor');
  return;
    const s3 = new AWS.S3({
      accessKeyId : config.s3.accessKeyId, 
                            secretAccessKey : config.s3.secretAccessKey, 
                            useAccelerateEndpoint: true});
    try {
        const fullFileName = `${path}${filename}`;
        let data = null;
        // const data = await Storage.get(fullFileName, { download: true });
        const expire_seconds = 60 * 60;


        const params = {
            Bucket: "talkback-transcribe",
            Key: fullFileName, // "stage/transcripts/done/what_is_the_whole_11320-manual-transcript-converted.csv", 
            Expires: expire_seconds
        };

        const dataSignature = s3.getSignedUrl('getObject', params);
       const fileData = await $.get(dataSignature);

        return fileData;
        // , function (filedata) {
        //     const f = filedata;
        // });
        // await $.getJSON(all_data['stats'], function(data){
        //     stats_data = JSON.parse(JSON.stringify(data));
        // });

        // return data;
    } catch (err) {
        console.log(`s3 download error:`, err);
        return null;
    }
}

export async function s3GuestVideoUpload(file, path, fileName, videoId =0, date, setProgress) {
  console.log('manual Upload trigger')
    //  const filename = `${Date.now()}-${file.name}`;
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const isJSON = file.size ? false : true;
    const fileSize = isJSON ? file.length : file[0].size;
    const fileType = isJSON ? "mp4/video" : file.type;
    const revisedFileName = fileName.replace(/[^a-zA-Z0-9.-_]/gi, '_');
    const sizeMB = formatBytes(file[0].size);
    const teacher = sessionStorage.getItem('email');
    const fullName = sessionStorage.getItem('fullName');
    const now = new Date().toString().split('-');
    const metadata = { 
        'tb-file-description': `${revisedFileName} (HD ${sizeMB})`,
        'tb-bucket-key': ``,
        'tb-source-system': 'manual',
        'tb-source-version': '1',
        'tb-bucket-key-base': ``,
        'tb-content-length': `${file[0].size}`,
        'tb-teacher-id': teacher,
        'tb-last-modified': now[0],     // date through GMT
        'tb-swivl-project-code': '',
        'tb-file-type': 'VIDEO',
        'tb-video-id': '',
        'tb-file-name': revisedFileName,
        'tb-full-name': fullName
     };
    //const body = JSON.stringify(metadata);
    // NOTE: use Storage.valut.put() for private files.
    try {
        const progressCallback = (progress) => {
            const progressInPercent = Math.round(progress.loaded * 100 / progress.total);
            setProgress(progressInPercent);
        }

        const fullFileName =  `${path}${revisedFileName}`;
        const stored = await Storage.put(fullFileName, file[0], {
            contentType: fileType,
            progressCallback,
            metadata,
            customPrefix: { public:''}
        })

        console.log(`File upload data: ${stored}`)
        return stored.key;
    } catch (err) {
        console.log(`s3 upload error: ${err}`);

        return null;
    }
}
