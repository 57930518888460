import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router } from "react-router-dom";
import {Amplify} from "aws-amplify";
import config from "./config";
import * as serviceWorker from './serviceWorker';
import { CacheBuster } from 'react-cache-buster/dist/CacheBuster';
import { c10CacheBuster } from './libs/c10CacheBuster';
import LoadingIndicator from './components/LoadingIndicator';
import packageJSON from '../package.json';

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
    },
    API: {
      endpoints: [
          {
            name: "settings",
            endpoint: config.apiGateway.URL[config.DEPLOYMENT_STAGE],
            region: config.apiGateway.REGION
          },
          {
            name: "teachers",
            endpoint: config.apiGateway.URL[config.DEPLOYMENT_STAGE],
            region: config.apiGateway.REGION
          },
        {
          name: "users",
          endpoint: config.apiGateway.URL[config.DEPLOYMENT_STAGE],
          region: config.apiGateway.REGION
        },
        {
          name: "videos",
          endpoint: config.apiGateway.URL[config.DEPLOYMENT_STAGE],
          region: config.apiGateway.REGION
        },
        // {
        //   name: "listsettings",
        //   endpoint: config.apiGateway.URL[config.DEPLOYMENT_STAGE],
        //   region: config.apiGateway.REGION
        // },
      ]
    }
  });


const domNode = document.getElementById('root');
const root = ReactDOM.createRoot(domNode);
const isProduction = true || config.DEPLOYMENT_STAGE === 'production-talkmoves';
console.log('version', packageJSON.version, ' stage: ', config.DEPLOYMENT_STAGE);
c10CacheBuster(packageJSON.version);
root.render(
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <App />
  </Router>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
