import React, { Component } from "react";
//import { Link } from "react-router-dom";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Container, Row, Col, Image, Card } from "react-bootstrap";

// import { getPageParameters } from './../libs/utils';
//import { API } from "aws-amplify";
//import axios from 'axios';
//import { getCurrentUser } from './../libs/appData';
//import SimpleBarChart from "../components/RenderSimpleBarChart";
import "./HomeV2.css";
//import LoadingIndicator from "../components/LoadingIndicator";

//const pdfURL = 'https://talkmoves-content.s3.amazonaws.com/Talk+Moves+Defined+(teacher+%26+student)-test.pdf';
const faqGraphic = require('../img/images/faq1.png')
const faqSteps = require('../img/images/faq2.png')
export default class Overview extends Component {

    constructor(props) {
        super();

        this.state = {
            navMargin: 0,
        }
        const page_data = 'overview';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });

    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0,  behavior: 'smooth'});
        window.addEventListener('scroll', (e) => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 100) {
                this.setState({ navMargin: scrollPosition-150 })
            } else {
                this.setState({ navMargin: 0 })
            }
        });
    }



    scrollTo = id => {
        const scrollToElement = document.getElementById(id);
        scrollToElement.scrollIntoView({ behavior: 'smooth' });
    };


    render() {
        return (
            <Container>
                <Row className="quoteShadow round" noGutters="true" style={{margin:'2rem 0px 3rem 0px', backgroundColor: '#e9ecef'}}>
                    <Col sm={3}>
                        <Image src={faqGraphic} className="round-left headline-image" fluid />
                    </Col>
                    <Col sm={9}>
                        <h1 className="light-grey center round headline-even sans">Real-time feedback helping mathematics teachers foster effective class discussion</h1>
                    </Col>
                </Row>
                <Row className="single-column">
                    <Col className="content" >
                        <div style={{marginBottom:'1rem'}}>
                            <h4>How does the TalkMoves application work, in a nutshell?</h4>
                            <p>The TalkMoves application automatically analyzes classroom recordings and generates information about teacher and student talk moves. The application relies on a series of sophisticated algorithms to produce feedback on each uploaded lesson from invited teachers using a SWIVL recording device.</p>
                            <p>First the system uses automated speech recognition (ASR) to turn the audio track into a written transcript. The written transcript is broken into sentences, and each sentence is designated as originating from the teacher or a student. Deep learning models then determine which sentences contain a talk move. Additional analytics are applied to calculate, for example, how much talk came from the teacher versus the students. Finally the data is visually displayed on the feedback dashboard.</p>
                            <Image src={faqSteps} fluid style={{padding: '1rem'}} />
                        </div>
                        <div>
                            <h4>But is it accurate? – How much error is in the Talk Moves system?</h4>
                            <p>There is definitely some error in the TalkBack system, which can arise from almost any step in the process described above. For example, errors can come from the recording quality, the speech-text algorithms, or the classification of talk moves. Even humans do not always agree on what someone said during a lesson or whether a given sentence has a talk move.</p>

                            <Card className="callout-blue">
                                <h3 className="sans">Our team is continually working to decrease the error rates in all aspects of the system so that, ideally, it will be as accurate as a highly trained human.</h3>
                            </Card>
                            
                            <p>While some inaccuracies are to be expected, we are striving to minimize their frequency and impact on the feedback provided.</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}