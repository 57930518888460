import React, { Component } from "react";
//import { Link } from "react-router-dom";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Container, Row, Col, Card, Button, Image, CardGroup } from "react-bootstrap";

// import { getPageParameters } from './../libs/utils';
//import { API } from "aws-amplify";
//import axios from 'axios';
//import { getCurrentUser } from './../libs/appData';
//import SimpleBarChart from "../components/RenderSimpleBarChart";
import "./HomeV2.css";
//import LoadingIndicator from "../components/LoadingIndicator";

//image imports
const spaceImg = require('../img/images/team1.jpg')
const journalOneImg = require('../img/images/research1.jpg')
const journalTwoImg = require('../img/images/research2.jpg')
const journalThreeImg = require('../img/images/research3.jpg')
const journalFourImg = require('../img/images/research4.jpg')
const nsfLogo = require('../img/images/nsf-logo.png')
const cuLogo = require('../img/images/boulder-logo-transparent.png')
const teamPhoto = require('../img/images/team-photo.jpg')

export default class ResearchAndTeam extends Component {

    constructor(props) {
        super();

        this.state = {
            navMargin: 0,
        }

        const page_data = 'research';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });

    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0,  behavior: 'smooth'});
        window.addEventListener('scroll', (e) => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 100) {
                this.setState({ navMargin: scrollPosition-150 })
            } else {
                this.setState({ navMargin: 0 })
            }
        });
    }



    scrollTo = id => {
        const scrollToElement = document.getElementById(id);
        scrollToElement.scrollIntoView({ behavior: 'smooth' });
    };


    render() {
        return (
           <Container>
                <Row className="quoteShadow round" noGutters="true" style={{margin:'2rem 0 3rem 0', backgroundColor: '#e9ecef',}}>
                    
                    <Col sm={5} >
                        <Image src={spaceImg} className="round-left" fluid style={{display: 'block', margin: 'auto'}} />
                    </Col>
                    <Col sm={7} style={{ display: 'flex', flexDirection: 'column', margin: 'auto',padding: '2rem'}}>
                        <h1 className="center headline-even sans">“Research is creating new knowledge.”</h1>
                        <h4 className="sans center margin-auto"> - Neil Armstrong</h4>
                    </Col>
                    
                </Row>
                <Row style={{margin:'auto', width: '100%', maxWidth: 800}}>
                    <Col className="content">
                    <div className="questionContent">
                        
                        <h4>What research has been done so far on the TalkMoves application?</h4>
    
                        <p>We have published several papers on the TalkMoves application, mostly focused on the development of the application. Here are links to some of those papers.</p>
                        
                            <CardGroup >
                                <Card style={{margin:'1rem'}}>
                                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1rW60wihCSEgJ3nh8Y0WJqDD340gLvzGR/view?usp=sharing">
                                    <Card.Img variant="top" src={journalOneImg} /></a>
                                    <Card.Body>
                                        <Card.Title style={{fontStyle: 'italic'}}>Suresh, A., Sumner, T., Jacobs, J., Foland, B. & Ward, W. (2019). Automating analysis and feedback to improve mathematics teachers’ classroom discourse. </Card.Title>
                                        <Card.Text>
                                        In Proceedings of the AAAI Conference on Artificial Intelligence (Vol. 33, pp. 9721-9728).
                                        </Card.Text>
                                        <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1rW60wihCSEgJ3nh8Y0WJqDD340gLvzGR/view?usp=sharing"><Button variant="info">Read Paper</Button></a>
                                    </Card.Body>
                                </Card>

                                <Card style={{margin:'1rem'}}>
                                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1s6m72bgKBEpsVISabfzv3rixFExav7bA/view?usp=sharing" >
                                    <Card.Img variant="top" src={journalTwoImg} /></a>
                                    <Card.Body>
                                        <Card.Title style={{fontStyle: 'italic'}}>Suresh, A., Sumner, T., Huang, I., Jacobs, J., Foland, B., & Ward, W. (2018, December). Using deep learning to automatically detect talk moves in teachers' mathematics lessons.</Card.Title>
                                        <Card.Text>
                                        In 2018 IEEE International Conference on Big Data (Big Data) (pp. 5445-5447). &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Card.Text>
                                        <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1s6m72bgKBEpsVISabfzv3rixFExav7bA/view?usp=sharing" ><Button variant="info">Read Paper</Button></a>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                            <CardGroup>
                                <Card style={{margin:'1rem'}}>
                                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1tV5eXkJEtmzJGaBqNXkPXy328qYtZUjR/view">
                                    <Card.Img variant="top" src={journalThreeImg} /></a>
                                    <Card.Body>
                                        <Card.Title style={{fontStyle: 'italic'}}>Suresh, A., Jacobs, J., Clevenger, C., Lai, V., Tan, C., Martin, J., & Sumner, T. (2021, June). Using AI to Promote Equitable Classroom Discussions: The TalkMoves Application. </Card.Title>
                                        <Card.Text>
                                            In International Conference on Artificial Intelligence in Education (pp. 344-348). Springer, Cham.
                                        </Card.Text>
                                        <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1tV5eXkJEtmzJGaBqNXkPXy328qYtZUjR/view">
                                            <Button variant="info" style={{position: 'absolute', bottom: 20, left: 20}}>Read Paper</Button>
                                        </a>
                                    </Card.Body>
                                </Card>

                                <Card style={{margin:'1rem'}}>
                                    <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1MKnN7bb3nPnSZ4UEcToSj0E7qlivGydu/view">
                                    <Card.Img variant="top" src={journalFourImg} /></a>
                                    <Card.Body>
                                        <Card.Title style={{fontStyle: 'italic'}}>Suresh, A., Jacobs, J., Lai, V., Tan, C., Ward, W., Martin, J., Sumner, T. (2021). Using transformers to provide teachers with personalized feedback on their classroom discourse: The TalkMoves Application. </Card.Title>
                                        <Card.Text>
                                        Paper presented to the 2021 conference of the Association for the Advancement of Artificial Intelligence.
                                        </Card.Text>
                                        <a target="_blank" rel="noopener noreferrer" href="https://drive.google.com/file/d/1MKnN7bb3nPnSZ4UEcToSj0E7qlivGydu/view">
                                            <Button variant="info">Read Paper</Button>
                                        </a>
                                    </Card.Body>
                                </Card>

                            </CardGroup>
                        
                            <p>Jacobs, J., Scornavacco, K., Harty, C., Suresh, A., Lai, V. & Sumner, T. (2022). Promoting rich discussions in mathematics classrooms: Using personalized, automated feedback to support reflection and instructional change. Teaching and Teacher Education, 122. https://doi.org/10.1016/j.tate.2022.103631  (Please contact us for a copy for this article.)</p>
                    </div>

                    <div className="questionContent">
                            <h4>You can also read about TalkMoves in the news! </h4>
                            <a href="http://www.ifl-news.pitt.edu/2021/06/talkmoves-com-an-application-of-real-time-evidence-of-their-mathematics-discussions/">University of Pittsburgh’s Institute for Learning’s blog post (June 2021)</a><br></br>
                            <a href="https://blogs.nvidia.com/blog/2021/07/08/ai-analyzes-classroom-discussions/">NVidia’s blog post (July 2021)</a><br></br>
                            <a href="https://www.datanami.com/2021/07/13/new-ai-tool-aims-to-analyze-and-improve-classroom-discussions/">Datanami news article (July 2021)</a>
                    </div>

                    <div className="questionContent">
                            <h4>Who is using the TalkMoves application?</h4>
                            <p>We are grateful for the insights of the math teachers in our current study. Twenty teachers across seven elementary, middle and high schools in two school districts are participating in an extensive user study that provides feedback to the research team regarding the application’s design and usefulness. Each of the classroom recordings also helps the team improve the accuracy and reliability of the application. With additional data to work from, the experts in computer science at the University of Colorado Boulder can better ensure fewer errors and more detailed information on classroom discourse. The experts in the classroom - the teachers - can then use this data for personalized, continued reflection and learning.</p>
                    </div>

                
                        <div className="questionContent">
                            <h4>How is TalkMoves funded?</h4>
                            <div style={{display:'flex'}}>
                                <Image src={nsfLogo} style={{width: '100px', height: '100px'}} />
                                <p style={{marginLeft: '2rem'}}>Development of the TalkMoves application and related research was funded by the National Science Foundation (awards 1600325 and 1837986). The awards were provided to an interdisciplinary team of researchers at CU Boulder, listed below.</p> 
                            </div>
                            <p><a target="_blank" rel="noopener noreferrer" href="https://curve10.com/">Curve 10</a>, a consulting agency, contributed to the application’s design, infrastructure, and front end development.</p>
                            
                        </div>


                        <div>
                        <Image src={cuLogo} className="cu-logo" fluid />
                        <h3 className="center sans" style={{marginTop: '-1rem', letterSpacing: -0.5}}>Research Team</h3>
                            <Image fluid src={teamPhoto} style={{padding: '1rem'}} />
                              
                        <Row>
                        <Col xs={1} />
                        <Col xs={12} sm={5} className="padding-left-three">             
                            <span className="heavy">Principal Investigators</span>
                            <ul>
                                <li> Tamara Sumner</li>
                                <li>Jennifer Jacobs</li>
                                <li>Jim Martin</li>
                                <li>Chenhao Tan</li>
                                <li>Wayne Ward</li>
                            </ul>
                        </Col>
                        <Col auto className="padding-left-three">
                            <span className="heavy">Research Associates</span>
                            <ul>
                                
                                <li>Karla Scornavacco</li>
                                <li>Charis Clevenger</li>
                                <li>Amanda Howard</li>
                            </ul>
                            <span className="heavy">Graduate Student Researchers</span>
                            <ul>
                                
                                <li>Abhijit Suresh</li>
                                <li>Vivian Lai</li> 
                            </ul>
                        </Col> 
                        </Row>     
                        </div>
                    </Col>
                </Row>
           </Container>
        );
    }
}