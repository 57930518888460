import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Home.css";
import "./Global.css";

const logo = require('../img/logo/logo.png');

export default class Home extends Component {
    constructor(props) {
        super();

        this.state = {
            isLoading: true,
            notes: [],
        };

        const page_data = 'latest-lesson';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });
    }

    async componentDidMount() {
        try {
            // this.setState({ notes });
        } catch (e) {
            alert(e);
        }
        this.setState({ isLoading: false });
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>
                    <img src={logo} alt='TalkMove Logo' style={{ display: 'inline-block' }} />
                </h1>
                <p>Moving feedback on how math teachers talk</p>
                <div>
                    <Link to="/login" className="btn btn-info btn-lg">
                        Sign in
                    </Link>
                    {/* <Link to="/signup" className="btn btn-success btn-lg">
                        Register
                    </Link> */}
                </div>
            </div>
        );
    }

    renderPage() {
        return (
            <div className="notes">
                {/*
                <ListGroup>
                  {!this.state.isLoading && this.renderPageList(this.state.notes)}
                </ListGroup>
                */}
                <div style={{margin:'auto', maxWidth:900}}>
                    <div className="page-header">Teacher's Guide</div>
                    <div className="text folder">
                    What is accountable talk?<br /><br />
                    
                    <p>Michaels, O’Connor and colleagues developed an approach to classroom discourse called
                    “accountable” or “academically productive” talk (O’Connor, Michaels & Chapin, 2015). 
                    At the heart of accountable talk is the notion that teachers should organize classroom 
                    discussions that promote students’ equitable participation in a rigorous learning environment. 
                    By utilizing talk moves, teachers can help ensure that the discussions will be purposeful, 
                    coherent, and productive (Michaels, O’Connor, Hall & Resnick, 2010). </p>

                    <p>The use of talk moves to orchestrate classroom discussions is an "important and universally 
                    recognized dimension of teaching" (Correnti et al, 2015, pg 306; Stein et al, 2008; Munter et al, 2015), 
                    and prior research has established strong linkages between productive classroom discourse and student 
                    achievement (e.g., Boston, 2012; Munter, 2014; Resnick, Michaels & O’Connor, 2010; Walshaw & Anthony, 2008; 
                    Webb et al., 2014). </p>

                    What is a talk move?<br /><br />
                    
                    <p>talk moves refer to discussion strategies that promote students' 
                    equitable participation in a rigorous classroom learning environment. 
                    For instance, when teachers revoice an idea posed by a student, press students to provide 
                    their reasoning, or ask students how one idea relates to another, they are 
                    using talk moves to promote and scaffold student participation in mathematical discussions. </p>

                    <p>Broadly speaking the TalkBack system includes two types of talk moves:
                    
                    <ul>
                    <li>Teacher utterances that are meant to elicit a student response (regardless of whether they actually elicit a response).</li>
                    <li>Teacher utterances that repeat, elaborate on, or build off student contributions. </li>
                    </ul>
                    </p>
                    
                    What talk moves did the system provide feedback on?<br /><br />

                    <p>TalkBack system provides feedback on six talk moves. These talk moves can be 
                    organized within three categories: (1) Accountability to the Learning Community, 
                    (2) Ensuring Productive Group Discussion, and (3) Accountability to Rigorous Thinking. </p>

                    Accountability to the Learning Community <br /><br />
                    
                    <i>Keeping Everyone Together</i><br />
                    <p>Questions asking students to be active listeners are considered Keeping Everyone 
                        Together. This talk move also corresponds to teachers’ yes/no questions 
                        (unless they are asking students to make a mathematical contribution) 
                        and managing student turns or interactions. Asking the students if they 
                        heard or can repeat what another student said also falls under the 
                        category of Keeping Everything Together.<br/><br/>
                    
                    Examples:
                    <ul>
                    <li>"What did Eliza just say her equation was?"</li>
                    <li>"Is everyone finished?"</li>
                    <li>"Kevin?"</li>
                    <li>"Did you want to say something?"</li>
                    </ul>
                    </p>

                    <i>Getting Students to Relate to Another’s Ideas</i><br />
                    <p>Teacher requests for a student contribution that relates to another 
                    student’s idea (reacting to what a classmate said or did, beyond just 
                    repeating the contribution) is considered Getting Students to Relate. 
                    For example, the teachers might ask students to comment or elaborate on other 
                    students’ contributions or to say whether they agree/disagree with another student.
                    <br/><br/>
                    
                    Examples:
                    <ul>
                    <li>"Do you agree with Juan that the answer is 7/10?"</li>
                    <li>"Can someone add on to what Emma said?"</li>
                    <li>"Who did this the same way as Abdul?"</li>
                    <li>"Who used a different method?"</li>
                    </ul>
                    </p>

                    Ensuring Productive Group Discussion<br /><br />

                    <i>Restating</i><br />
                    <p>Restating is when a teacher repeats all or part of what a student 
                    says word for word without adding anything or changing any of the 
                    wording. Sometimes the teachers’ repetition of a student utterance is 
                    NOT considered restating, such as when the teacher repeats exactly 
                    what the student says but infers she wants students to say more or 
                    voices the restatement as a question.<br/><br/>
                    
                    Examples:
                    <ul>
                    <li>Student: "Add two here."</li>
                    <li>Teacher: "Add two here."</li>

                    <li>Student: "Ten square yards."</li>
                    <li>Teacher: "Ten square yards."</li>
                    </ul></p>

                    <i>Revoicing</i><br />
                    <p>
                    Revoicing occurs when a teacher repeats what a student says but adds 
                    on to it or changes the wording. Even a slight change in the wording 
                    is coded as Revoicing. If the teacher uses at least one key mathematical word
                    or key idea from the student’s utterance or repeats the student’s utterance 
                    in another language this is considered Revoicing.<br/><br/>
                    
                    Examples:
                    Student: “Add two here.”
                    Teacher: “Julia told us she would add two here.”

                    Student: “They have four sides.”
                    Teacher: “Yes, a rectangle has four sides.”
                    </p>


                    Accountability to Rigorous Thinking<br/><br/>

                    <i>Pressing for Accuracy</i><br />
                    <p>
                    When teachers prompt students to provide mathematical information that does not involve evidence or reasoning, this is coded as Pressing for Accuracy. 
                    The following types of prompts are examples of Pressing for Accuracy:<br /><br />

                    <ul>
                    <li>Students are asked to provide a short math-related answer. </li>
                        <ul>
                        <li>"Can you give an example of an ordered pair?"</li>
                        <li>"How many pennies do you need to make four cents?"</li>
                        <li>"So X times X equals…."</li>
                        </ul>

                    <li>Students are asked to provide an answer related to a procedure.</li>
                        <ul>
                        <li>"What information in our story is going to help us find the answer?"</li>
                        <li>"What do we need to find?"</li>
                        </ul>

                    <li>Students are asked to talk through a procedure or possible variations on a problem.</li>
                        <ul>
                        <li>"What’s our first step going to be?"</li>
                        <li>"So if you take away ten from this side, you have to do what to the other side?"</li>
                        </ul>

                    <li>Students are prompted to provide precise mathematical language. </li>
                        <ul>
                        <li>"What is that property called?"</li>
                        <li>"Tell me what you mean by 70 percent?"</li>
                        </ul>

                    <li>Students are asked to define elements in an equation. </li>
                        <ul>
                        <li>"What does the three-fourths represent in this story?"</li>
                        <li>"So the x stands for what?"</li>
                        </ul>

                    <li>Students are asked to demonstrate or indicate an answer on the board during a classroom discussion.</li>
                        <ul>
                        <li>"Show me with your fingers how many you see."</li>
                        <li>"Can someone go to the board and point to the denominator?"</li>
                        </ul>
                    </ul> 
                    </p>
                    
                    <i>Pressing for Reasoning</i><br />
                    <p>
                    Pressing for Reasoning occurs when the teacher elicits evidence, prompts students to explain, or asks a student for their thinking behind a decision. <br/>
                    <ul>
                    <li>“Why did you pick 7?”</li>
                    <li>“What’s your logic here?”</li>
                    </ul>

                    The following types of prompts are also examples of Pressing for Reasoning:
                    <ul>
                    <li>Asking students to connect two or more ideas or how a concept is related to the problem.</li>
                        <ul><li>“Why wouldn’t we just use the Pythagorean theorem?”</li></ul>

                    <li>Asking students to compare across mathematical representations</li>
                    <ul>
                    <li>“Why should the slope be increasing?”</li>
                    <li>“Where is the rate in the graph?”</li>
                    </ul>

                    <li>Asking student to extend their ideas or consider a hypothetical situation</li>
                    <ul><li>“What if I changed this number to a four?”</li></ul>
                    </ul>
                    </p>

                    How does the TalkBack system work?<br/><br/>
                    <p>The TalkBack system uses a series of sophisticated algorithms to produce feedback based on each uploaded lesson. First the system is automated to turns the audio track into a written transcript. The written transcript is broken into sentences, and each sentence is designated as originating from the teacher or a student. All teacher sentences are then run through deep learning models to determine whether they contain one of the six talk moves. Finally, additional analytics are applied to calculate, for example, how much talk came from the teacher versus the students. </p>

                    <p>For more detailed information, we have written several technical papers about how the TalkBack system works. It is important to note that we are continually upgrading the processes and deep learning models, which may not be reflected in these papers.</p>
                    
                    How much error is in the TalkBack system?<br/><br/>
                    <p>There is inevitably SOME error in the TalkBack system. For example, errors can come from problems with videotaping or picking up soft voices, the speech-text algorithms being inaccurate, or the deep-learning models not accurately detecting talk moves. The potential for error in the TalkBack system is cumulative in nature and therefore difficult to calculate, but perhaps as high as 20%. </p>

                    <p>Our team is continually working to decrease the error rates in all aspects of the system. However, users may find they disagree with how the system has transcribed a portion of the video or assigned a talk move to a sentence. Our goal is for the system to be as accurate as a highly trained human; in other words, some inaccuracies are to be expected but hopefully they are minimal and relatively inconsequential given enough data.</p>

                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderPage() : this.renderLander()}
            </div>
        );
    }
}