import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";

import { Nav, Navbar, Dropdown } from "react-bootstrap";
import LoadingPulser from "./components/LoadingPulser";
import LoadingIndicator from "./components/LoadingIndicator";
import TopNavigation from "./components/Navigation/nav-v2";

import "./App.css";
import "./scss/app.scss";
import AppRoutes from "./AppRoutes";
import config from "./config";
import packageJSON from "../package.json";

import Footer from "./components/Footer";
import { c10CacheBuster } from "./libs/c10CacheBuster";
import { IdleTimerProvider } from "react-idle-timer";

const logo = require("./img/logo/logo.png");

const App = props => {
  const [state, setState] = useState({
    userId: null,
    userDistrict: null,
    isAuthenticated: false,
    isAuthenticating: true,
    isAdmin: sessionStorage.getItem("isAdmin"),
    isCoach: false,
    isHATResearcher: false,
    lessonLabel: 'Lesson',
    fullName: "",
    shadowName: null,
    idleTimeOut: config.talkmoves.IDLE_TIMEOUT_MINUTES, // default
    isMobile:
      navigator.userAgent.includes("iPhone") ||
      navigator.userAgent.includes("Android") ||
      navigator.userAgent.includes("IEMobile"),
  })
  const history = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const authData = await Auth.currentSession();

        let authEmail = null;
        const nowStamp = Math.floor(new Date().getTime() / 1000);
        if (authData && authData.idToken.payload.email) {
          authEmail = authData.idToken.payload.email;
  
          sessionStorage.setItem("email", authEmail);
          await userHasAuthenticated(true);
          const versionTime = await API.get(`users`, `users/version`);
          const t1 = new Date(
            authData.idToken.payload.auth_time * 1000
          ).toString();
          const t2 = new Date(authData.idToken.payload.exp * 1000).toString();
          const now = new Date().toString();
  
          const expired = authData.idToken.payload.exp < nowStamp;
          const newVersion = authData.idToken.payload.auth_time < versionTime;
          if (expired || newVersion) {
            await Auth.signOut();
            sessionStorage.clear();
            userHasAuthenticated(false);
            history(`/login/t/${nowStamp}`);
          } 
        } else {
          await Auth.signOut();
          sessionStorage.clear();
          userHasAuthenticated(false);
          history(`/login/t/${nowStamp}`);
        }
      } catch (e) {
        if (e !== "No current user") {
          alert(`system error: ${e}`);
        }
      }
      setState(oldState => ({...oldState, isAuthenticating: false }));
    }
    loadData();
  },[]);

    useEffect(() => {
    c10CacheBuster(packageJSON.version);
  }, [window.location.pathname]);

  const userHasAuthenticated = async (authenticated) => {
    if (authenticated) {
      setState(oldState => ({...oldState, 
        userId: sessionStorage.getItem('userId'),
        userDistrict: sessionStorage.getItem('userDistrict'),        
        isAdmin: sessionStorage.getItem("isAdmin"),
        isCoach: sessionStorage.getItem("isCoach"),
        isHATResearcher: sessionStorage.getItem("isHATResearcher"),
        lessonLabel: (sessionStorage.getItem("isCoach") || sessionStorage.getItem('isHATResearcher')) ? 'Tutorial' : 'Lesson',
        fullName: sessionStorage.getItem("fullName"),
        shadowName: sessionStorage.getItem("shadowName"),
        isAuthenticated: true,
      }));
    } else {
      setState(oldState => ({...oldState, isAuthenticated: false, }));
    }
  };

  const handleLogout = async (event) => {
    await Auth.signOut();
    sessionStorage.clear();
    userHasAuthenticated(false);
    history("/");
  };

  const setShadowName = (name) => {
    setState(oldState => ({...oldState, shadowName: name }));
  };

  const switchToAdmin = () => {
    sessionStorage.removeItem("shadowId");
    sessionStorage.removeItem("shadowName");
    setShadowName(null);

    history("/research-dashboard");
  };

  const scrollToTop = () => {
    const scrollToElement = document.getElementById("verytop");
    scrollToElement.scrollIntoView({ behavior: "smooth" });
  }

    const childProps = {
      isAuthenticated: state.isAuthenticated,
      userHasAuthenticated: userHasAuthenticated,
      isAdmin: state.isAdmin,
      isCoach: state.isCoach,
      isHATResearcher: state.isHATResearcher,
      lessonLabel: state.lessonLabel,
      shadowName: state.shadowName,
      setShadowName: setShadowName,
      history,
    };
    const { isAdmin, isCoach, shadowName, isHATResearcher, lessonLabel } = state;
    const isAdminView = (isAdmin && !shadowName) || false;
    const homeLink = isCoach ? '/coach-home' : isHATResearcher ? '/hat-researcher-home' : '/';

    const onIdle = () => {
      handleLogout();
    }

    const getIdleTime = () => {
      const time = sessionStorage.getItem('idleTimeOut') || state.idleTimeOut;
      return (1000 * 60 * time);
    }
    return (
      <div id="verytop" style={{ paddingTop: 100 }}>
        <div id="anchor-top"></div>
        {!state.isAuthenticating ? (
          <div className="App container">
            {scrollToTop()}
            <Navbar
              collapseOnSelect
              expand="lg"
              style={{
                position: "fixed",
                width: "100vw",
                paddingTop: 10,
                top: 0,
                left: 0,
                zIndex: 1000,
                background: "white",
                marginBottom: 48,
                borderBottom: "1px solid #ddd",
              }}
            >
              <Link to={homeLink} className="text link">
                <img
                  src={logo}
                  height={40}
                  alt="TalkMove Logo"
                  style={{ display: "inline-block", marginRight: 6 }}
                />
              </Link>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                {/* ************************* USER MENU */}
                <TopNavigation
                  isAdmin={isAdmin}
                  isAdminView={isAdminView}
                  isCoach={isCoach}
                  isHATResearcher={isHATResearcher}
                  lessonLabel={lessonLabel}
                  switchToAdmin={switchToAdmin}
                  handleLogout={handleLogout}
                  fullName={state.fullName}
                  shadowName={state.shadowName}
                  isAuthenticated={state.isAuthenticated}
                />
              </Navbar.Collapse>
            </Navbar>
              <IdleTimerProvider
                timeout={getIdleTime()} // 1000*60 == 1 minute
                onIdle={onIdle}
              // unused options:
              // onPresenceChange={onPresenceChange}
              // onPrompt={onPrompt}
              //  onActive={onActive}
              // onAction={onAction}
              >
                <div id="afternav">
                  <AppRoutes {...childProps} />
                  <Footer childProps={childProps} />
                </div>
              </IdleTimerProvider>   
          </div>
        ) : (
          <div style={{ margin: 48, paddingTop: 100 }}>
            <LoadingPulser message="Authenticating..." />
          </div>
        )}
      </div>
    );
  }
export default App;
