import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import LoadingIndicator from '../components/LoadingIndicator';

class RenderStackedBarChart extends Component {
	constructor(props) {
		super(props);

        this.state = {
            isLoading: false,
			video_id: [],
			stats_data: props.stats_data.tb_feedback.quarter,
		};
	}

	render () {
        if (this.state.isLoading) {
            return (
                <LoadingIndicator message='Loading talk moves...' />
            )
        }
        const {stats_data} = this.state;
        let DATA;
        if( this.props.students ) {
            DATA = [
                {name: 'QTR 1', 'Learning Community': stats_data.student.learning_community_1, 'Content Knowledge': stats_data.student.content_knowledge_1, 'Rigorous Thinking': stats_data.student.rigorous_thinking_1},
                {name: 'QTR 2', 'Learning Community':stats_data.student.learning_community_2, 'Content Knowledge': stats_data.student.content_knowledge_2, 'Rigorous Thinking': stats_data.student.rigorous_thinking_2},
                {name: 'QTR 3', 'Learning Community': stats_data.student.learning_community_3, 'Content Knowledge': stats_data.student.content_knowledge_3, 'Rigorous Thinking': stats_data.student.rigorous_thinking_3},
                {name: 'QTR 4', 'Learning Community': stats_data.student.learning_community_4, 'Content Knowledge': stats_data.student.content_knowledge_4, 'Rigorous Thinking': stats_data.student.rigorous_thinking_4},
            ];
        } else {
            DATA = [
                {name: 'QTR 1', 'Learning Community': stats_data.teacher.learning_community_1, 'Content Knowledge': stats_data.teacher.content_knowledge_1, 'Rigorous Thinking': stats_data.teacher.rigorous_thinking_1},
                {name: 'QTR 2', 'Learning Community':stats_data.teacher.learning_community_2, 'Content Knowledge': stats_data.teacher.content_knowledge_2, 'Rigorous Thinking': stats_data.teacher.rigorous_thinking_2},
                {name: 'QTR 3', 'Learning Community': stats_data.teacher.learning_community_3, 'Content Knowledge': stats_data.teacher.content_knowledge_3, 'Rigorous Thinking': stats_data.teacher.rigorous_thinking_3},
                {name: 'QTR 4', 'Learning Community': stats_data.teacher.learning_community_4, 'Content Knowledge': stats_data.teacher.content_knowledge_4, 'Rigorous Thinking': stats_data.teacher.rigorous_thinking_4},
            ];
        }


		// const DATA = [
		// 	{name: 'first', 'Learning Community': 34, 'Content Knowledge': 12, 'Rigorous Thinking': 51},
		// 	{name: 'second', 'Learning Community': 45, 'Content Knowledge': 23, 'Rigorous Thinking': 25},
		// 	{name: 'third', 'Learning Community': 36, 'Content Knowledge': 45, 'Rigorous Thinking': 26},
		// 	{name: 'fourth', 'Learning Community': 23, 'Content Knowledge': 32, 'Rigorous Thinking': 37},
		// ];
        return (
            <div>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={DATA} >
                    <CartesianGrid stroke="#e0e0e0" vertical={false} />
                    <XAxis dataKey="name" height={40} /* label={{ value: 'Quarter', angle: 0, position: 'insideBottom', offset: 0 }} */ />
                    <YAxis width={100} label={{ value: 'Talk Moves Frequency', angle: -90, position: 'outsideMiddleLeft' }} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="Learning Community" stackId="a" barSize={40} fill="#f6cc46" />
                    <Bar dataKey="Content Knowledge" stackId="a" barSize={40} fill="#3dc9c4" />
                    <Bar dataKey="Rigorous Thinking" stackId="a" barSize={40} fill="#ff7359" />
                </BarChart>

            </ResponsiveContainer>
            <span style={{color: 'black'}}>Talk moves categories per quarter of the tutorial</span>
            </div>
        );
  	}
}

export default RenderStackedBarChart;