import React from "react";

import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import packageJSON from "../../package.json";

const Footer = ({ childProps }) => (
  <div className="Footer">
    <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
      {childProps.isAuthenticated ? (
        <span></span>
      ) : (
        <span>
        <Nav.Link
          as={Link}
          to="/sample-video"
          style={{ display: "inline-block" }}
        >
          Sample {childProps.lessonLabel}
        </Nav.Link>
        <Nav.Link as={Link} to="/definitions" style={{ display: "inline-block" }}>
          About Talk Moves
        </Nav.Link>        
        </span>        
      )}
    </div>
    <div className="copyright">
      TalkMoves © Copyright {new Date().getFullYear()} University of Colorado Boulder
    </div>
    <div className="version">version {packageJSON.version}</div>
  </div>
);
export default Footer;
