import React from 'react';
import '../scss/app.scss';

const LoadingIndicator = props => (
  <div>
    <div style={{ margin: '2em auto', width: 200, textAlign: 'center' }}>
      {props.message ? (
        <span className={props.classes} style={{ color: '#555' }}> {props.message} </span>
      ) : null}
    </div>
    <div className="loading-indicator" style={props.style || undefined}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default LoadingIndicator;