import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { getMe } from './../libs/appData';

import { FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ControlLabel from "../components/ControlLabel";
import "./Login.css";

export default class Login extends Component {
    constructor(props) {
        super(props);

        // see if this is a deep link to a video
        const pathParts = window.location.pathname.split('/video/');
        let video_id = false;
        let coach_id = false;
        if (pathParts.length > 1) {
          const parameters = pathParts[1].split('/');
          video_id = parameters[0];
          if (parameters.length > 1) {
            coach_id = parameters[1];
            sessionStorage.setItem("deepLink", window.location.pathname);
          } else {
            sessionStorage.setItem("deepLink", window.location.pathname);
          }
          console.log( video_id, coach_id);
        }

        // see if this is 
    
        this.state = {
            isLoading: false,
            email: "",
            password: "",
        };

    }

    getVersion = async() => {
        const versionTime = await API.get(`settings`, `settings`);
        if (versionTime.output.idleTime) {
          sessionStorage.setItem('idleTimeOut', versionTime.output.idleTime);
        }
    }
    componentDidMount() {
        // if (this.props.isAuthenticated) {
        //     this.props.handleLogout();
        // }
        this.getVersion();
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ isLoading: true });

        try {
            sessionStorage.setItem("email", this.state.email);
            const authData = await Auth.signIn(this.state.email, this.state.password);
            const userAuthName = authData.username;
          Auth.currentAuthenticatedUser()
            .then((user) => {
              const u = user;
            })
            .then((data) => console.log(data))
            .catch((err) => console.log(err));

            sessionStorage.setItem('cname', userAuthName);
            const me = await getMe(this.state.email);
            this.props.userHasAuthenticated(true);
            let newUrl = '/';

            if (authData.challengeName === 'NEW_PASSWORD_REQUIRED') {
              sessionStorage.setItem('resetUser', JSON.stringify(authData));
              newUrl = '/change-password';
            } else {
              if (sessionStorage.getItem("deepLink")) {
                const dl = sessionStorage.getItem("deepLink");
                sessionStorage.removeItem("deepLink");
                newUrl = dl;
              } else if (me.isAdmin) {
                newUrl = '/research-dashboard'
              } else if (me.isHATResearcher ) {
                newUrl = '/hat-researcher-home';
              } else if (me.isCoach) {
                newUrl = '/coach-home';
              } else {
                newUrl = '/';
              }
            }
            this.props.history(newUrl);
            // now read in user's data
        } catch (e) {
            alert(e.message);
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div className="Login Page">
                <h2 className="center">Sign In</h2>
                <form onSubmit={this.handleSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            placeholder="email"
                        />
                    </FormGroup>

                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                            placeholder="password"
                        />
                    </FormGroup>

                    <div>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Sign In"
                        loadingText="Signing in…"
                    />
                    </div>
                    <Link to="/login/reset">Forgot password?</Link>
                </form>
            </div>
        );
    }
}
