import React, { Component } from "react";
//import { Link } from "react-router-dom";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import "./HomeV2.css";
import codedPreview from '../img/images/coded_transcript_preview.png'; 

const inquiryLogo = require('../img/images/inquiry_logo.png')
const edutopiaLogo = require('../img/images/edutopia-logo-nobg.png')
const accountableTalkCover = require('../img/images/accountable-cover.png')
const recitationCover = require('../img/images/recitation-cover.png')
const orchestratingCover = require('../img/images/orchestrating-cover.png')
const microphone = require('../img/images/microphone.png')
const iflLogo = require('../img/images/IFL_logo.png')
const vid1 = require('../img/images/vid1.jpeg')
const vid2 = require('../img/images/vid2.png')
const vid3 = require('../img/images/vid3.jpeg')
const classRoom = require('../img/images/classroom-neonbrand-unsplash.png')
const umLogo = require('../img/images/um_logo.jpeg')
const pdfDl1 = 'https://talkmoves-content.s3.amazonaws.com/Orchestrating+Productive+Mathematical+Discussions+Five+Practices+for+Helping+Teachers+Move+Beyond+Show+and+Tell.pdf'
const pdfDl2 =  "https://talkmoves-content.s3.amazonaws.com/Michaels+%26+O'Connor+-+Chpt.+14+of+Helping+Students+Make+Sense+of+the+World+-+2017.pdf"

export default class Resources extends Component {

    constructor(props) {
        super();
        this.ref = React.createRef();
        this.state = {
            navMargin: 0,
            navSelected: 0,
            topHeight: 0,
            menuOffset: 75
        }
        this.navClick = this.navClick.bind(this);

        const page_data = 'resources';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });
    }

    navClick(e) {
        this.setState(
        {
            navSelected: e.currentTarget.id
        });
    }
    
    componentDidMount() {
        window.scrollTo({top: 0, left: 0,  behavior: 'smooth'});
        window.addEventListener('scroll', this.handleScroll.bind(this));
    } 
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
    }
    handleScroll(e) {
        const scrollPosition = window.scrollY;

            if (scrollPosition > this.state.topHeight) {
                this.setState({ navMargin: scrollPosition - this.state.topHeight})
            } else {
                this.setState({ navMargin: 0 })
            }
            if(this.ref.current != null) {
                this.setState({ topHeight: this.ref.current.offsetHeight, menuOffset: this.ref.current.offsetTop});
            }
    }
    scrollTo = id => {
        const scrollToElement = document.getElementById(id);
        scrollToElement.scrollIntoView({ alignToTop: 'true', behavior: 'smooth' });
    };
    

    render() {
        return (
             <Container fluid>
                    <Row style={{marginTop: '1rem', marginBottom: '2rem'}} ref={this.ref}>
                        <Col auto></Col> 
                        <Col xs={12} sm={11} >
                            <Row className="quoteShadow round light-grey" noGutters="true" >
                            <Col sm={5}>
                                <Image src={classRoom} className="round-left" fluid ></Image>
                            </Col>
                            <Col sm={7} className="light-grey center round" >
                            <h1 className="align-center sans" style={{paddingTop: '1rem'}}>Educator Resources</h1>
                                <div className="margin-auto" style={{padding: '1rem 2rem .5rem 2rem', }}>
                                    <h5 className="sans">We have curated some resources you might find useful for learning more about the talk moves featured in the TalkMoves application.</h5>
                                    <p style={{fontSize: 14, }}>Please note that the info in these resources may not exactly match the talk move names and definitions in the TalkMoves application.</p>
                                </div>
                            </Col>
                            </Row>
                        </Col>
                        <Col auto />
                        </Row>
                    <Row>
                        <Col sm={2} className="d-none d-sm-block leftFaq" style={{ marginTop: this.state.navMargin}}>
                            <h4 id="1" onClick={this.navClick} className={this.state.navSelected === '1' ? "blueText" : "greyText"} >
                                <a onClick={() => { this.scrollTo('videos') }}>Videos</a>
                            </h4>
                            <h4 id="2" onClick={this.navClick} className={this.state.navSelected === '2' ? "blueText" : "greyText"}>
                                <a onClick={() => { this.scrollTo('readings') }}>Readings</a>
                            </h4>
                            <h4 id="3" onClick={this.navClick} className={this.state.navSelected === '3' ? "blueText" : "greyText"}>
                                <a onClick={() => { this.scrollTo('podcasts') }}>Podcasts</a>
                            </h4>
                        </Col>
                        <Col auto style={{padding:'0 50px'}}>
                            <div >
                                    <div className="anchor" id="videos"></div>
                                    <h2>Videos</h2>
                                    <Card className='grey-btm-border'>
                                    {/*<a target="_blank"  rel="noopener noreferrer" href="https://deepblue.lib.umich.edu/handle/2027.42/134321"><Image src={vid1} className="vid-link" /></a>
                                    */}
                                    <div className="flex-column-nowrap">
                                   
                                    {/* <iframe id="kaltura_player_1600925694" src="https://cdnapisec.kaltura.com/p/1038472/sp/103847200/embedIframeJs/uiconf_id/46290962/partner_id/1038472?iframeembed=true&playerId=kaltura_player_1600925694&entry_id=1_s4wld1zv" width="360" height="239" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-Card *" frameborder="0"></iframe>
                                    <a target="_blank" rel="noopener noreferrer" href="https://tle.soe.umich.edu">
                                        <img src={umLogo} style={{maxWidth: 350, padding: '2rem'}} alt="The Inquiry Project logo. Seeing the world through a scientist's eyes" /></a> */}

                                    <iframe id="kaltura_player_1601404743" src="https://cdnapisec.kaltura.com/p/1038472/sp/103847200/embedIframeJs/uiconf_id/46424261/partner_id/1038472?iframeembed=true&playerId=kaltura_player_1601404743&entry_id=1_b8p97z5r" 
                                        width="400" height="282" allowfullscreen webkitallowfullscreen mozAllowFullScreen 
                                        allow="autoplay *; fullscreen *; encrypted-Card *" frameborder="0"></iframe>           
                                    <a target="_blank" rel="noopener noreferrer" href="https://tle.soe.umich.edu">
                                        <img src={umLogo} style={{maxWidth: 350, padding: '2rem'}} alt="The Inquiry Project logo. Seeing the world through a scientist's eyes" /></a> 
                                    
                                    </div>
                                        <Card.Body className="padding-left-three">
                                            <h5>Talk Moves In Action!</h5>
                                            <p>
                                            In this video, rising fifth graders from a summer mathematics class discuss a “warm up problem” where they identify fractions as points on a number line. Note the ways that the instructor, Dr. Deborah Ball, encourages students to share their thinking and respond directly to each other. Student voices play a key role, with both the instructor and students using a variety of talk moves. </p>
                                            
                                                <a target="_blank" rel="noopener noreferrer" href="https://talkmoves-content.s3.amazonaws.com/Talkmoves+in+Action+-+Coded+Transcript.pdf">
                                                    <Image src={codedPreview} fluid /></a>
                                                <div style={{marginTop: '1.5rem'}}>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://deepblue.lib.umich.edu/handle/2027.42/134321"><Button variant="outline-info" style={{margin: '1rem'}}>Watch the Video</Button></a>
                                                    <a target="_blank" rel="noopener noreferrer" href="https://talkmoves-content.s3.amazonaws.com/Talkmoves+in+Action+-+Coded+Transcript.pdf"><Button variant="outline-secondary" >View the Coded Transcript</Button></a>
                                                </div>
                                            
                                        </Card.Body>
                                    </Card>
                                    <Card className='grey-btm-border'>
                                    <div className="flex-column-nowrap">
                                        <a target="_blank" rel="noopener noreferrer" href="https://inquiryproject.terc.edu/prof_dev/resources/video_cases/video_case.cfm%3F&case_type=tp&case_grade=4&case_num=1&case_return=library&case_step=step1.html"><Image src={vid2} className="vid-link" /></a>
                                        {/*<iframe src="https://player.vimeo.com/video/27475545?color=1f6ca8&title=0&byline=0&portrait=0" width="360" height="211" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true" frameborder="0" ></iframe>*/}
                                        <a target="_blank" rel="noopener noreferrer" href="https://inquiryproject.terc.edu/prof_dev/resources/video_cases/video_case.cfm%3F&case_type=tp&case_grade=4&case_num=1&case_return=library&case_step=step1.html">
                                            <img src={inquiryLogo} style={{maxWidth: 350, padding: '2rem'}} alt="The Inquiry Project logo. Seeing the world through a scientist's eyes" /></a>
                                    </div>
                                        <Card.Body className="padding-left-three">
                                            <h5>WHAT IS PRODUCTIVE TALK?</h5>
                                            <p>
                                            The Inquiry Project collected a series of videos on productive talk in science classrooms. Though different from math classes, the videos highlight what teachers can ‘look for’ – and intentionally teach – when fostering student-centered, whole class discussions. </p>
 
                                            <p>You may want to start with the first video on their site, which gives an explanation of what the authors mean by productive talk. The second video, “Classroom Discussion,” shows a discussion in action. The video is about 3 minutes long and worth reviewing on your own or with colleagues to notice the many characteristics of productive talk in this class. You can even show it (along with the Dr. Deborah Ball video above) to your students so they can see students using talk moves to support each other and deepen their own thinking. </p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://inquiryproject.terc.edu/prof_dev/resources/video_cases/video_case.cfm%3F&case_type=tp&case_grade=4&case_num=1&case_return=library&case_step=step1.html">
                                                <Button variant="outline-info" style={{marginRight: '1rem'}}>Watch the Video</Button></a>
                                        </Card.Body>
                                    </Card>
                                    <Card className='green-btm-border'>
                                        <div className="flex-column-nowrap">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.edutopia.org/video/encouraging-academic-conversations-talk-moves"><Image src={vid3} className="vid-link" /></a>
                                        {/*<iframe width="360" height="211" src="https://www.youtube-nocookie.com/embed/kSI4imt0dXg" modestbranding="1" controls="1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-Card; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>*/}
                                        
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.edutopia.org/video/encouraging-academic-conversations-talk-moves">
                                            <img src={edutopiaLogo} style={{backgroundColor: '#ff4c00', width: '200px', padding: '1rem 2rem', margin: '2rem'}} alt="The Inquiry Project logo. Seeing the world through a scientist's eyes" /></a>
                                        </div>   
                                        <Card.Body className="padding-left-three">
                                            <h5>ENCOURAGING CONVERSATIONS WITH TALK MOVES</h5>
                                            <p>
                                            Edutopia features this video and corresponding resources with insights and tips on building students’ confidence and use of talk moves. The content area featured in the video is language arts, yet the instructional moves the teacher makes are applicable to math classrooms. Note the ways the teacher makes talk moves an explicit part of the learning environment.
                                            </p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.edutopia.org/video/encouraging-academic-conversations-talk-moves">
                                                <Button variant="outline-info" style={{marginRight: '1rem'}}>Watch the Video + Transcript</Button></a>
                                        </Card.Body>
                                    </Card>
                                
                                <div id="readings" className="anchor"></div>
                                    <h2>Readings</h2>
                                    <Card className='grey-btm-border'>
                                        <a target="_blank" rel="noopener noreferrer" href="https://ifl.pitt.edu/how-we-work/sourcebook.cshtml"><Image fluid src={accountableTalkCover} className="reading-cover" alt="Institute for Learning Accountable Talk Sourcebook cover" /></a>
                                        <Card.Body className="padding-left-three">
                                            <h5>ACCOUNTABLE TALK® SOURCEBOOK</h5>
                                            <p>
                                            The Talk Moves application builds from earlier research in Accountable Talk, an approach to classroom discourse that promotes students’ equitable participation in a rigorous learning environment.</p>
                                            <p>Check out this free Accountable Talk Sourcebook from the Institute for Learning at the University of Pittsburgh for an extensive introduction to the purposes of Accountable Talk, including examples of classroom practices and specific talk moves.</p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://ifl.pitt.edu/how-we-work/sourcebook.cshtml">
                                            <Button variant="outline-info" style={{marginRight: '1rem'}}>Download the Sourcebook</Button></a>
                                            
                                        </Card.Body>
                                    </Card>
                                    <Card className='grey-btm-border'>
                                        <div className="flex-nowrap-column">
                                            <a target="_blank" rel="noopener noreferrer" href={pdfDl2}><Image fluid src={recitationCover} className="reading-cover" alt="chapter cover - from recitation to reasoning. supporting scientific and engineering practices through talk"/></a>
                                            <p className="text-small-narrow">Michaels, S. & O’Connor, C.  (2017). From recitation to reasoning: Supporting scientific and engineering practices through talk. In C.V. Schwarz, C. Passmore, B. Reiser (Eds.), Helping students make sense of the world using next generation science and engineering practices, NSTA Press (311-336). NSTA.</p>
                                        </div>
                                        <Card.Body className="padding-left-three">
                                            <h5>FROM RECITATION TO REASONING</h5>
                                            <p>This book chapter offers ideas for fostering academically productive talk in classrooms. The chapter focuses on science classrooms, though the tips and examples can easily be transferred to mathematics. The authors of the chapter, Sarah Michaels and Catherine O’Connor, are two of the lead authors of the Accountable Talk Sourcebook.</p> 
 
                                            <p>You may want to use this chapter for a shared reading with your grade-level team or for self-initiated professional learning across departments. The chapter opens with an explanation of why educators should focus on student talk, and then gives examples of what Academically Productive Talk might look like. The section on Classroom Norms can be helpful to review and use as a guidepost in your own teaching.</p>
                                            <a target="_blank" rel="noopener noreferrer" href={pdfDl2} download>
                                                <Button variant="outline-info" style={{marginRight: '1rem'}}>Download the Chapter</Button></a>
                                        </Card.Body>
                                    </Card>
                                    <Card className='green-btm-border'>
                                    
                                    <div className="flex-column-nowrap">
                                        <a target="_blank" rel="noopener noreferrer" href={pdfDl1}>
                                            <Image fluid src={orchestratingCover} className="reading-cover"/></a>
                                            <p className="text-small-narrow">Stein, M.K., Engle, R. Smith, M. & Hughes, E. (2008). Orchestrating productive mathematical discussions: Five practices for helping teachers move beyond show & tell. Mathematical Thinking and Learning, 10, 4, 314-330.</p>
                                    </div>
                                        <Card.Body className="padding-left-three">
                                            <h5>ORCHESTRATING PRODUCTIVE MATHEMATICAL DISCUSSIONS</h5>
                                            <p>This research-based article provides a model for strengthening content-rich, student-centered whole class discussions. The article is not geared specifically toward the talk moves featured in the TalkMoves application, though it does define and elaborate on underlying, key practices for facilitating effective classroom discussions: anticipating, monitoring, selecting, sequencing, and making connections between student responses.</p>
 
                                            <p>You can check out this article to learn more about links between theory and practice for mathematical reforms that, in the words of the authors, “develop and then build on the personal and collective sensemaking of students rather than to simply sanction particular approaches as being correct or demonstrate procedures for solving predictable tasks.“ (p.315).</p> 
                                            <a target="_blank" rel="noopener noreferrer" href={pdfDl1} download>
                                                <Button variant="outline-info" style={{marginRight: '1rem'}}>Download the Article</Button> </a>
                                            
                                        </Card.Body>
                                        
                                    </Card>
                                <div id="podcasts" className="anchor"> </div>
                                    <h2>Podcasts</h2>
                                    <Card>
                                        <div className='flex-column-nowrap'>
                                            <a target="_blank" rel="noopener noreferrer" href="https://ifl.pitt.edu/how-we-work/podcasts.cshtml">
                                            <Image fluid className="reading-cover" src={microphone} /></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://ifl.pitt.edu/how-we-work/podcasts.cshtml">
                                            <Image src={iflLogo} style={{width: '250px', padding: '1rem', margin: '2rem'}} alt="Institute for learning - university of pittsburgh logo"/></a>
                                        </div>
                                        <Card.Body className="padding-left-three">
                                            <h5>ACCOUNTABLE TALK PODCASTS</h5>
                                                <p>In these podcasts, researchers from the Institute for Learning provide information on how to relate typical classroom management to the practice of Accountable Talk. The segments discuss the importance of Accountable Talk in the classroom while delving into how to create an environment where Accountable Talk is the norm for both teachers and students.</p> 
                                                <p>Take a look at the menu of options, and start with the one you are most interested in. You may want listen with your colleagues and discuss what resonates with you and what specific instructional moves you may want to try in your own classrooms.</p>
                                            <a target="_blank" rel="noopener noreferrer" href="https://ifl.pitt.edu/how-we-work/podcasts.cshtml">
                                            <Button variant="outline-info" style={{marginRight: '1rem'}}>Listen to the Podcasts</Button></a>
                                        </Card.Body>
                                    </Card>
                                    
                                
                            </div>
                        </Col>
                    </Row>
            </Container>
                    
            
        );
    }
}
{/*  
                    <Container>
                        <iframe 
                           src={'https://curve10.narrasys.com/#/story/5efe40d293d34fdace0cb14e/5efe40d293d34fdace0cb150'}
                           style={{height: "calc(100vw * .8)"}} width="100%"
                           title="Example Video"
                            />    
                            <iframe
                                        src={'https://cdnapisec.kaltura.com/p/1038472/embedPlaykitJs/uiconf_id/33084471?iframeembed=true&entry_id=1_s4wld1zv&config[playback]={"autoplay":true}'}
                                        style={{width: 640, height: 360}}
                                        allowfullscreen
  webkitallowfullscreen
  mozAllowFullScreen>
                                    </iframe>    
                                    <script src={"https://cdnapisec.kaltura.com/p/1038472/sp/103847200/embedIframeJs/uiconf_id/33084471/partner_id/1038472?autoembed=true&amp;entry_id=1_s4wld1zv&amp;playerId=kaltura_player_01&amp;cache_st=1455309475&amp;width=400&amp;height=330&amp;flashvars[streamerType]=auto"}></script>  
                                    <iframe width="360" height="211" type="text/javascript" src='https://cdnapisec.kaltura.com/p/1038472/embedPlaykitJs/uiconf_id/33084471?iframeembed=true&entry_id=1_s4wld1zv&config[playback]={"autoplay":true}' >
                                    </iframe>         
                    </Container> */}