import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import {
  FormGroup,
  FormControl,
  FormCheck
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ControlLabel from "../components/ControlLabel";
import "./ResetPassword.css";

export default class ChangePassword extends Component {
  constructor(props) {
    super();

    const resetUserString = sessionStorage.getItem('resetUser');
    const resetUser = resetUserString ? JSON.parse(resetUserString) : false;

    this.state = {
      code: "",
      email: "",
      oldPassword: "",
      password: "",
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      resetUser,
      showPasswords: false,
      pwMin8: false,
      pwLC1: false,
      pwUC1: false,
      pwNum1: false,
      pwSC1: false,
      passwordsMatch: false,
      formIsValid: false,
      noSpaces: false,
    };

    const page_data = 'reset-password';
    // eslint-disable-next-line no-undef
    gtag('event', 'page_view', {
      page_title: page_data,
      page_location: `/${page_data}`,
      page_path: `/${page_data}`,
      send_to: 'UA-91361306-3'
    });
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    const { password, confirmPassword, oldPassword } = this.state;

    const pwLC1_regex = /.*[a-z].*/;
    const pwUC1_regex = /.*[A-Z].*/;
    const pwNum1_regex = /.*\d.*/;
    const pwSC1_regex = /.*\W.*/;
    let formIsValid = false;

    const testPassword = password || ' ';

    const passwordsMatch = testPassword === confirmPassword;
    const pwMin8 = testPassword.length > 7;
    const pwLC1 = pwLC1_regex.test(testPassword);
    const pwUC1 = pwUC1_regex.test(testPassword);
    const pwNum1 = pwNum1_regex.test(testPassword);
    const pwSC1 = testPassword !== ' ' && pwSC1_regex.test(testPassword);
    formIsValid = passwordsMatch && pwLC1 && pwUC1 && pwNum1 && pwSC1;
    this.setState({ passwordsMatch, pwMin8, pwLC1, pwUC1, pwNum1, pwSC1, formIsValid });
  }

  handleChange = event => {
    if (event.target.id === 'password' && event.target.value.includes(' ')) {
      this.setState({noSpaces: true})
      return;
    }
    this.setState({
      [event.target.id]: event.target.value, noSpaces: false
    }, () => {
      this.validateResetForm();
    });

  };

  handleConfirmClick = async event => {
    event.preventDefault();
    this.setState({ isConfirming: true });
    try {
      const user = this.state.resetUser ? this.state.resetUser : await Auth.currentAuthenticatedUser();

      if (this.state.resetUser) {
        const email = sessionStorage.getItem('email');
        const newUser = await Auth.signIn(email, this.state.oldPassword);
        if (newUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(
            newUser, // the Cognito User Object
            this.state.password, // the new password
          );
        } else {
          // other situations
        }
      } else {
        // used for when user is simply changing password from the Profile page.
        await Auth.changePassword(
          user,
          this.state.oldPassword,
          this.state.password
        );
      }

      this.setState({ confirmed: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isConfirming: false });
    }
  };

  renderChangePasswordForm() {
    const { password, confirmPassword, showPasswords, pwMin8, pwLC1, pwUC1, pwNum1, pwSC1, formIsValid, passwordsMatch } = this.state;

    return (
      <div>
        <form onSubmit={this.handleConfirmClick}>
          <h2 className="center">Change Your Password</h2>
          <hr />
          <FormGroup controlId="oldPassword">
            <label className='tm-label'>Old Password</label>
            <FormControl
              type={showPasswords ? 'text' : 'password'}
              placeholder="old password"
              value={this.state.oldPassword}
              onChange={this.handleChange}
              autoComplete="-password"
            />
          </FormGroup>

            <FormGroup controlId="password">
              <label className='tm-label'>New Password</label>
              <FormControl
                type={showPasswords ? 'text' : 'password'}
                placeholder="new password"
                value={password}
                onChange={this.handleChange}
                autoComplete="your-new-password"
              />
            </FormGroup>

          {this.state.noSpaces && (
            <div style={{color: 'red'}}>Spaces not allowed in passwords</div>
          )}

          <FormGroup controlId="confirmPassword">
            <label className='tm-label'>Confirm Password</label>
            <FormControl
              type={showPasswords ? 'text' : 'password'}
              placeholder="confirm password"
              onChange={this.handleChange}
              value={confirmPassword}
              autoComplete="new-password"
            />
            {password && confirmPassword && !passwordsMatch && (
              <div style={{color: 'red'}}>Passwords don't match</div>
            )}
          </FormGroup>

          <FormGroup>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={showPasswords}
                onChange={() => this.setState({ showPasswords: !showPasswords })}
              />
              <span>Show Passwords</span>
            </div>

          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            text="Confirm"
            loadingText="Confirm…"
            isLoading={this.state.isConfirming}
            disabled={!formIsValid}
          />
        </form>

        {/* ************** panel to show password progress *********************** */}
        <div className='password-prompter'>
          <b>New Password Requires: </b>
          <div className='password-prompter-inside'>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwMin8}
              />
              Min 8 Characters</div>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwLC1}
              />
              1 lower case letter</div>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwUC1}
              /> 1 upper case letter</div>

            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwNum1}
              /> 1 number</div>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwSC1}
              /> 1 special character</div>
          </div>
        </div>
      </div>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <h3>
          <i className="far fa-check-circle"></i>
          &nbsp;Your password has been changed.
        </h3>
        <p>
          <Link to="/login" className="btn btn-info btn-lg">
            Sign in
          </Link>
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className="ResetPassword Page">
        {!this.state.confirmed
          ? this.renderChangePasswordForm()
          : this.renderSuccessMessage()}
      </div>
    );
  }
}