

const refreshCacheAndReload = async () => {
  try {
    if (window?.caches) {
      const { caches } = window;
      const cacheNames = await caches.keys();
      const cacheDeletionPromises = cacheNames.map((n) => caches.delete(n));

      await Promise.all(cacheDeletionPromises);

      console.log('The cache has been deleted.');
      // @ts-ignore: Firefox still has a `forceReload` parameter.
      window.location.reload(true);
    }
  } catch (error) {
    console.log('An error occurred while deleting the cache.', true);
    console.log(error, true);
  }
};


export async function c10CacheBuster(currentVersion) {

 if (!currentVersion) return;

 try {
  const res = await fetch(`/meta.json`);
  const { version: metaVersion } = await res.json();
  console.log('metaVersion:', metaVersion);

  const shouldForceRefresh = metaVersion > currentVersion; // straight string compare for now
  if (shouldForceRefresh) {
    console.log(`New version (v${metaVersion}) > current version (v${currentVersion}). Forcing a refresh.`);
    refreshCacheAndReload();
  } 
  //else {
  //  console.log('There is no new version. No cache refresh needed.');
  //}
} catch (error) {
  console.log('An error occurred while checking cache status.', true);
  console.log(error, true);
}

}
