import React, { Component } from "react";
import _ from "lodash";
import moment from 'moment';

import { LineChart, ResponsiveContainer, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, Surface, Symbols } from 'recharts';

import talkmovesnames from '../data/talkmovesnames.json';

const TiltedAxisTick = (props) => {

    const { x, y, stroke, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-45)">
                <br></br>{payload.value}
            </text>
        </g>
    );

};

class SimpleLineChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: [],
            talkmoveColors: talkmovesnames,

        };
    };

    handleClick = dataKey => {
        if (_.includes(this.state.disabled, dataKey)) {
            this.setState({
                disabled: this.state.disabled.filter(obj => obj !== dataKey)
            });
        } else {
            this.setState({ disabled: this.state.disabled.concat(dataKey) });
        }
    }
    handleDateClick = (event) => {
        const { data, history } = this.props;
        if (history) {
        let url;
        data.map(entry => {
            if(entry.shortDate === event.value) {
                url = '/video/' + entry.videoId
            }
        })
        history(url)
        }
    }

    renderCustomizedLegend = ({ payload }) => {
        return (
            <div className="customized-legend" >
                {payload.map(entry => {
                    const { color, name, dataKey, showSelector, type } = entry;
                    if (showSelector && type === this.props.type) {
                        const inActive = _.includes(this.state.disabled, dataKey);
                        const style = {
                            // marginRight: 10,
                            color: inActive ? "#AAA" : "#000",
                            marginLeft: 18,
                            textIndent: -13,
                        };
                        const checkIcon = inActive ? 'fa-square' : 'fa-check-square';
                        return (
                            <div
                                //   className="legend-item"
                                onClick={() => this.handleClick(dataKey)}
                                style={style}
                            >
                                   <span><i className={`far ${checkIcon}`} style={{ color: color, marginRight: 8, fontSize: 16 }}></i>  {name}</span>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        );
    };


    findColor = (data, move) => {
        return (
            data.find((index) => index.dataKey === move).color
        )
    }

    findLabel = (data, move) => {
        return (
            data.find((index) => index.dataKey === move).name
        )
    }

    render() {
        const { yLabel, xLabel, categories, colors, data, labelPosition, showLegend, handleCatagoryCaptilization, type } = this.props;
        const position = labelPosition ? labelPosition : 'center';
        const dateList = [];

        const orderedCategories = !type ? categories : this.state.talkmoveColors.filter(entry => {
            const { dataKey, showSelector, type } = entry;
            if (type === this.props.type) {
                return(dataKey)
            } else {
                return null;
            }
        }).map( entry => (entry.dataKey));

        data.forEach(item => {
            let itemTime = false;
            if (!item.date.startsWith('202')) {
              // deal with unix epoch time  
              itemTime = moment.utc(parseInt(item.date)).format('h A');
              item.date = moment.utc(parseInt(item.date)).format('YYYY-MM-DD');
            }
            if (item.date) {
                // date is YYYY-MM-DD HH:mm. Remove the year; insert / for -
                item.shortDate = item.date.substring(5).replace("-", "/"); 
            }

            if (dateList.includes(item.shortDate)) {
                // this date already exists!!!
                item.shortDate += '(2) ';
                if (dateList.includes(item.shortDate)) {
                    // this date already exists!!!
                    item.shortDate = item.shortDate.replace('(2)', '(3)');
                }
            } 
            dateList.push(item.shortDate)

            // update the data to be only 2 digits max
            orderedCategories.forEach(cat => {
                item[cat] = Math.round(item[cat] * 10) / 10
            })
            return item;
        });

        return (
            <ResponsiveContainer width="100%" height={440}>
                <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 10 }}>
                    {/* TODO: Getting Id and date on the tick
          Searched a few diffrent ways but does not seem possiable based on my current understanding. Abandoning for now.
          Concat custom tick seems like best so far but is really clunky looking
       */}
                    {/* <XAxis label={{label:xLabel}} dataKey="videoDate" tick={<TiltedAxisTick />}/>  */}
                    <XAxis dataKey={'shortDate'} label={{ label: xLabel }} interval={0} fontSize={14} angle={-45} height={90} textAnchor="end" onClick={e => this.handleDateClick(e)}/>

                    <YAxis label={{ value: yLabel, angle: -90, position: position }} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    {showLegend && (<Legend
                        layout='vertical'
                        align='right'
                        verticalAlign='top'
                        margin={{ top: 0, left: 20 }}
                        wrapperStyle={{ right: 0, top: 12, width: 120, textAlign: 'left', fontSize: 12 }}

                        payload={this.state.talkmoveColors.map(tm => tm)}
                        content={this.renderCustomizedLegend}
                        onClick={this.handleClick}
                    />)}

                    {/* Render out all the line components for each catagory */}
                    {
                        orderedCategories.filter(catagory => { return !_.includes(this.state.disabled, catagory) }).map((catagory, index) => {
                            if (catagory !== 'total')
                                // going to need to run a find inorder to grab the color. 
                   //             return <Line type="monotone" isAnimationActive={false} name={handleCatagoryCaptilization(catagory.replace(/_/g, " "))} dataKey={catagory} stroke={this.findColor(this.state.talkmoveColors, catagory)} activeDot={{ r: 8 }} />
                           return <Line type="monotone" isAnimationActive={false} name={this.findLabel(this.state.talkmoveColors, catagory)} dataKey={catagory} stroke={this.findColor(this.state.talkmoveColors, catagory)} activeDot={{ r: 8 }} />
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default SimpleLineChart;
