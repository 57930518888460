import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { Table, Row } from "react-bootstrap";

import "./Home.css";
import "./Global.css";
import LoadingIndicator from "../components/LoadingIndicator";
import { getCurrentUser } from './../libs/appData';
import { normalizeProjectTimeStamp, tutorialStatusLabel, showProcessingIncompleteMessage } from "../libs/utils";

const logo = require('../img/logo/logo.png');

export default class Videos extends Component {
    constructor(props) {
        super();

        this.state = {
            isLoading: true,
            lessons: [],
            lessonVideos: false,
        };

        const page_data = 'all-lessons';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });
    }

    async componentDidMount() {
        try {
            const lessons = await this.getLessons();
            //console.log(lessons['Items'].sort())
            if (lessons === -1 ) {
                this.setState({ lessonVideos: false, isLoading: false });               
            }
            lessons.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.tb_project_date) - new Date(a.tb_project_date);
            });
            this.setState({ lessonVideos: lessons, isLoading: false });
            this.recordPageAction( {        
                'eventName': 'allTutorialsForTutor',
              })
        } catch (e) {
            // alert(e);
        }

    }

    /* recordPageAction
     *    Publish an event to our MixPanel subscriber to track the user, their characteristics
     *    and details of their action.
     *
     *    Input:
     *       objArgs {
     *          'eventName' : String,
     *          'action' : String || null,
     *          'properties' : String || this.props
     *       }
     *    Output:
     *       None
     *    Side effects:
     *       publishes MixPanel event
     */
    recordPageAction(objArgs) {
        const properties = objArgs.properties || this.props;
        mixpanel.track(objArgs.eventName, {
            'action': objArgs.action || 'initialView',
            'subAction': 'none',
            'userAuthenticated': properties.isAuthenticated,
            'userIsCoach': properties.isCoach && true,
            'userIsResearcher': properties.isHATResearcher && true,
            'userId': sessionStorage.getItem('userId'),
            'userDistrict': sessionStorage.getItem('userDistrict'),      
            'tutorId': sessionStorage.getItem('tutorId') || 'none',
            'tutorName': sessionStorage.getItem('tutorName') || 'none'
        });
    }


    getLessons = async () => {
        const email = encodeURI(getCurrentUser());
        const videos = await API.get("teachers", `teachers/getvideos/${email}/nostats`);
        if (!videos || videos.length === 0) {
            return -1
        }
        const lastVideo = 0;   
        // insure that there is a mostRecentVideo!
        sessionStorage.setItem('mostRecentVideo', videos[lastVideo].tb_video_id);
        return videos;
    }

    goToVideo = (url, tutorialState) => {
      if (tutorialState && tutorialState.toLowerCase() === 'completed') {
        this.props.history(url);
      }
      else {
        showProcessingIncompleteMessage();
      }
    }

    getStatus(status) {
        var statuses = {};
        statuses['none'] = "uploading"
        statuses['browser'] = "uploading"
        statuses['swivl'] = "uploading"
        statuses['uploaded'] = "uploaded"
        statuses['transcoder_started'] = "in-process"
        statuses['transcoded'] = "in-process"
        statuses['transcribe_started'] = "in-process"
        statuses['transcribed'] = "in-process"
        statuses['transcript_sentences'] = "in-process"
        statuses['transcript_vtt'] = "in-process"
        statuses['talkmoves_categorized'] = "analyzing"
        statuses['talkmoves_feedback'] = "analyzing"
        statuses['talkmoves_wordcloud'] = "analyzing"
        statuses['completed'] = "completed"
        return statuses[status]
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>
                    <img src={logo} alt='TalkMove Logo' style={{ display: 'inline-block' }} />
                </h1>
                <p>Moving feedback on how math teachers talk</p>
                <div>
                    <Link to="/login" className="btn btn-info btn-lg">
                        Sign in
                    </Link>
                    {/* <Link to="/signup" className="btn btn-success btn-lg">
                        Register
                    </Link> */}
                </div>
            </div>
        );
    }

    renderPage() {
        const {lessonVideos }= this.state;
        const tutorName = sessionStorage.getItem('tutorName');
        const tutorId = sessionStorage.getItem('tutorId');
        const isCoach = this.props.isCoach || this.props.isHATResearcher;

        return (
            <div className="notes">
                <div style={{ margin: 'auto', maxWidth: 1000 }}>
                    <div className="page-header">All {this.props.lessonLabel}s
                      {isCoach ? ` for tutor: ${tutorName || tutorId}` : ''} 
                    </div>
                    {this.state.isLoading ?
                        <LoadingIndicator message='Loading your videos' />
                        :
                        <>
                            {this.state.lessonVideos === false && (
                                <Row><div>You do not have any {this.props.lessonLabel.toLowerCase()}s uploaded yet.</div></Row>
                            )}

                            {lessonVideos && this.state.lessons !== -1 && (
                                <>
                                <Table bordered hover>
                                    <thead align="center">
                                        <tr>
                                            <th >{this.props.lessonLabel}</th>
                                            <th >Date</th>
                                            <th >Length</th>  
                                            <th >TM ID</th>
                                            <th >Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.lessonVideos.map((video) => (
                                        <tr key={`video-${Math.random()}`}>
                                            <td onClick={() => this.goToVideo(`/video/${video.tb_video_id}`, video.tb_project_state)}>
                                            {
                                              <>
                                               {isCoach
                                               ?
                                               <span className='cell-hover' >
                                                {(video.tb_video_title == video.tb_project_title) ? "Untitled" : video.tb_video_title}
                                               </span>
                                               :
                                               <span className='cell-hover' >{video.tb_project_title}</span>
                                              }
                                              </>
                                            }
                           
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                                <div>
                                                    {normalizeProjectTimeStamp(video.tb_project_date)}
                                                </div>
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                            <div style={{width: 70, margin: 'auto', textAlign: 'left'}}>
                                                    {video.tb_time}
                                                </div>
                                            </td>
                                            {/* <td>
                                            <div style={{width: 100, margin: 'auto', textAlign: 'left'}}>
                                                    {video.tb_source_length}
                                                </div>
                                            </td> */}
                                           
                                            <td style={{textAlign: 'center'}}>tm-{video.tb_video_id}</td>
                                            <td style={{textAlign: 'center'}}>
                                               <span style={{color: `${video.tb_project_state === 'COMPLETED' ? 'green' : 'red'}`}}>
                                                    {tutorialStatusLabel(video.tb_project_state)}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    </Table>
                                </>
                            )}
                        </>
                    }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderPage() : this.renderLander()}
            </div>
        );
    }
}
