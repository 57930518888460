import React, { Component } from "react";

// import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";

import {
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { getPageParameters, trackEvent, normalizeProjectTimeStamp } from "../libs/utils";
import { API } from "aws-amplify";
import { getCurrentUser } from "./../libs/appData";

import SimpleBarChart from "../components/RenderTrendsSimpleBarChart";
import SimpleLineChart from "../components/RenderSimpleLineChart";
import StackedChart from "../components/RenderTrendsStackedChart";
import talkmovesnames from "../data/talkmovesnames.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./Trends.css";
import Moment from "moment";
import RenderDataTable from "../components/RenderDataTable";
import moment from "moment";

const trendsMenu = [
  {
    name: "Tutor Trends",
    color: "#0f0",
    id: "menu-ttm",
    anchor: "ttm",
    menuItems: [
      {
        name: "Talk Moves",
        id: "menu-tm-talkmoves",
        anchor: "top",
      },
      {
        name: "Talk Move Categories",
        id: "menu-tm-categories",
        anchor: "ttm-categories",
      },
    ],
  },
  {
    name: "Student Trends",
    color: "#f00",
    id: "menu-stm",
    anchor: "page-stm",
    menuItems: [
      {
        name: "Talk Moves",
        id: "menu-sm-talkmoves",
        anchor: "top",
      },
      {
        name: "Talk Move Categories",
        id: "menu-sm-categories",
        anchor: "stm-categories",
      },
    ],
  },
  {
    name: "Other Discourse Trends",
    color: "#00f",
    id: "menu-otm",
    anchor: "page-otm",
    menuItems: [
      {
        name: "Amount of Student Talk",
        id: "menu-om-student-talk",
        anchor: "top",
      },
      {
        name: "More than One-word Answers",
        id: "menu-om-one-word",
        anchor: "otm-one-word",
      },
      {
        name: "Time to Think",
        id: "menu-om-think",
        anchor: "otm-think",
      },
      {
        name: "Frequency of Math Terms",
        id: "menu-om-math-terms",
        anchor: "otm-math-terms",
      },
    ],
  },
];

export default class Trends extends Component {
  constructor(props) {
    super();
    const { start } = getPageParameters(window.location.search);
    const activeTab = start ? start : "teacher";
    const isCoach = props.isCoach || props.isHATResearcher;

    this.state = {
      isLoading: true,
      activeTab: "trend1",
      videosData: null,
      allVideos: true,
      talkmovesNames: talkmovesnames,
      demoData: false,
      isCoach,
      noDataAvailable: false,
      selectedStartDate: '2023-01-01 01:00',
      selectedEndDate: moment().format('YYYY-DD-YY HH:MM'),
      lastDate: moment().format('YYYY-DD-YY HH:MM'),
    };

    const page_data = "trends";
    // eslint-disable-next-line no-undef
    gtag("event", "page_view", {
      page_title: page_data,
      page_location: `/${page_data}`,
      page_path: `/${page_data}`,
      send_to: "UA-91361306-3",
    });

  }

  async componentDidMount() {
    // if (!this.props.isAuthenticated) {
    //     return;
    // }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    try {
      const settings = await API.get(`settings`, `settings`);
      this.setState({ demoData: settings.output.demoData }, async () => {
        let videosData = await this.getData(this.state.activeTab);

        videosData.forEach((vd) => {
          if (!vd.tb_project_date.startsWith('202')) {
            // deal with epoch timestamp
            vd.tb_project_date = moment.utc(vd.tb_project_data).format('YYYY-MM-DD HH:mm');
          }
          else {
             // convert from UTC
             const tmpDate = new Date(vd.tb_project_date + ':00Z');
             const year  = tmpDate.toLocaleString('en-US', { year: 'numeric'});
             const month = tmpDate.toLocaleString('en-US', { month: '2-digit'});
             const day   = tmpDate.toLocaleString('en-US', { day: '2-digit'});
             const time  = tmpDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false});
             vd.tb_project_date = year + "-" + month + "-" + day + " " + time;
          }
        })
        if(videosData) {
          videosData.sort((a, b) => {
            if (a.tb_project_date < b.tb_project_date) return -1;
            if (a.tb_project_date > b.tb_project_date) return 1;
            return 0;
          });
          const earliestDate = (videosData[0].tb_project_date.slice(0, 10)) || '2023-01-01 01:00';
          let lastDate = (videosData[videosData.length - 1].tb_project_date.slice(0, 10));
          if (lastDate.length === 10) {
            lastDate = `${lastDate} 01:00`
          }
          // const realLastDay = Moment(lastDate)
          //   .add(1, "days")
          //   .format("YYYY-MM-DD");
          //   const ed = videosData[0].tb_project_date;
          const realLastDay= lastDate;
          sessionStorage.setItem("videosData", JSON.stringify(videosData.Items));
          const avgStudentTalk = videosData[0].tb_stats.average_student_talk;
          this.setState({
            videosData: videosData,
            earliestDate,
            selectedStartDate: earliestDate,
            lastDate: realLastDay,
            selectedEndDate: realLastDay,
            isLoading: false,
            avgStudentTalk,
          });

          this.recordPageAction( {        
            'eventName': 'trendsForTutor',
            'projectData': videosData[0]   // looking for project data such as teacher_id
          })
	    
        } else {
          this.setState({ videosData: null });
        }
      });
  
    } catch (e) {
      // alert(e);
    }
  }

  /* recordPageAction
   *    Publish an event to our MixPanel subscriber to track the user, their characteristics
   *    and details of their action.
   *
   *    Input:
   *       objArgs {
   *          'eventName' : String,
   *          'action' : String || null,
   *          'projectData' : { <project data struct> }
   *          'properties' : { } || this.props
   *       }
   *    Output:
   *       None
   *    Side effects:
   *       publishes MixPanel event
   */
  recordPageAction(objArgs) {
    const properties = objArgs.properties || this.props;
    const projectData = objArgs.projectData || this.state.videosData[0];
    mixpanel.track(objArgs.eventName, {
      'action': objArgs.action || 'initialView',
      'subAction': this.state.subMenuSelected || 'none',
      'userAuthenticated': properties.isAuthenticated,
      'userIsCoach': properties.isCoach && true,
      'userIsResearcher': properties.isHATResearcher && true,	
      'userId': sessionStorage.getItem('userId'),
      'userDistrict': sessionStorage.getItem('userDistrict'),      
      'tutorId': projectData.tb_teacher_id,
      'tutorName': projectData.tb_full_name
    });
  }


  getData = async () => {
    const email = this.props.isAuthenticated
      ? encodeURI(getCurrentUser())
      : encodeURI(this.state.demoData.teacher);
    const data = await API.get(
      "teachers",
      `teachers/getvideos/${email}/feedback`
    );
    return data;
  };

  getTrend = async (tab) => {
    this.setState({ activeTab: tab });
  };

  handleSwitch = (e) => {
    this.setState({ allVideos: !this.state.allVideos, isLoading: true }, () => {
      let { selectedStartDate, selectedEndDate } = this.state;
      if (this.state.allVideos) {
        // force the starting/ending dates
        selectedStartDate = this.state.earliestDate;
        selectedEndDate = this.state.lastDate;
      }
      this.setState({ isLoading: false, selectedStartDate, selectedEndDate });
    });
  };

  scrollTo = (id) => {
    if (id === "top") {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return;
    }
    const scrollToElement = document.getElementById(id);
    if (scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  selectTopLevelNav(id, anchor, name) {
    this.setState({ topMenuSelected: id, subMenuSelected: false });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const page_data = `trends`;
    const path = name.toLowerCase().replace(" ", "-");
    // eslint-disable-next-line no-undef
    gtag("event", "page_view", {
      page_title: `${page_data}-${name.replace(" ", "-")}`,
      page_location: `/${page_data}/${path}`,
      page_path: `/${page_data}/${path}`,
      send_to: "UA-91361306-3",
    });
  }

  selectMenuItem(id, anchor, name) {
    this.setState({ subMenuSelected: id });
    this.scrollTo(anchor);

    trackEvent(`Current Lesson: ${name}`, { videoId: this.state.video_id });
  }

  formatDate = (date) => Moment(date).format("MMM Do, YYYY").toString();

  /* TODO: For all my filters we need to format the project date for display */
  filterFrequency = (videosData, groupType) => {
    const response = videosData.map((data) => {
      // return {
      //     ...data.tb_feedback.frequency.teacher,
      //     videoId:data.tb_video_id,
      //     videoDate: this.formatDate(data.tb_project_date),
      //     date: data.tb_project_date
      // }
      const d = data;
      const displayData = {
        ...data.tb_feedback.frequency.standardized[groupType],
        videoId: data.tb_video_id,
        date: (data.tb_project_date),
        videoDate: this.formatDate(data.tb_project_date),
      };
      if (groupType === "teacher") {
        delete displayData.total_student_talkmoves;
      } else {
        delete displayData.total_teacher_talkmoves;
      }

      return displayData;
    });
    return response;
  };

  filterStats = (videoData) => {
    return videoData.map((data) => {
      return {
        ...data.tb_stats,
        videoId: data.tb_video_id,
        videoDate: this.formatDate(data.tb_project_date),
        date: data.tb_project_date,
      };
    });
  };

  filterCategories = (videoData, groupType) => {
    return videoData.map((data) => {
      return {
        ...data.tb_feedback.category[groupType],
        videoId: data.tb_video_id,
        videoDate: this.formatDate(data.tb_project_date),
        date: data.tb_project_date,
      };
    });
  };

  filterPercentTalk = (videoData) => {
    return videoData.map((data) => {
      return {
        ...data.tb_feedback,
        videoId: data.tb_video_id,
        videoDate: this.formatDate(data.tb_project_date),
        date: data.tb_project_date,
      };
    });
  };

  filterUtterances = (videoData) => {
    return videoData.map((data) => {
      return {
        ...data.tb_feedback.utterances,
        videoId: data.tb_video_id,
        videoDate: this.formatDate(data.tb_project_date),
        date: data.tb_project_date,
      };
    });
  };

  filterMathTerms = (videoData) => {
    return videoData.map((data) => {
      return {
        ...data.tb_feedback.math_terms,
        videoId: data.tb_video_id,
        videoDate: this.formatDate(data.tb_project_date),
        date: data.tb_project_date,
        total_student: data.tb_feedback.math_terms.student.total_student,
        total_teacher: data.tb_feedback.math_terms.teacher.total_teacher,
      };
    });
  };

  // TODO: Need to filter by a set data range in preperation for calendar intergration.
  filterByDate = (videoData) => {
    const { selectedStartDate, selectedEndDate } = this.state;
    const extendedEndDate = `${selectedEndDate.slice(0,10)} 24:00`;
    const data = videoData.filter((item) => {
      if (item.date >= selectedStartDate && item.date <= extendedEndDate) {
        return true;
      }
      return false;
    });
    return data;
  };

  setStartDate = (startDate) => {
    const selectedStartDate = Moment(startDate).format("YYYY-MM-DD");
    let newEndDate = this.state.selectedEndDate;
    if (selectedStartDate > newEndDate) {
      newEndDate = selectedStartDate;
    }
    this.setState({ isLoading: true, selectedStartDate, selectedEndDate: newEndDate }, () => {
      this.setState({ isLoading: false });
    });
  };

  setEndDate = (endDate) => {
    const selectedEndDate = Moment(endDate).format("YYYY-MM-DD");
    this.setState({ isLoading: true, selectedEndDate }, () => {
      this.setState({ isLoading: false });
    });
  };

  handleCatagoryCaptilization = (string) =>
    string
      .split(" ")
      .map((str) => str[0].toUpperCase() + str.slice(1) + " ")
      .join(" ");

  renderLeftNav() {
    const { topMenuSelected, subMenuSelected } = this.state;
    const { isAdmin, isHATResearcher} = this.props;

    return (
      <div className="video-fixed-nav">
        {trendsMenu.map((topMenu) => (
          <>
    {(!topMenu.restrictedTo || isAdmin || isHATResearcher) && (
            <>
            <h4
              id={topMenu.id}
              className={`video-topmenu ${
                topMenuSelected === topMenu.id ? "active" : ""
              }`}
              onClick={() =>
                this.selectTopLevelNav(topMenu.id, topMenu.anchor, topMenu.name)
              }
            >
              {topMenu.name}
              {/* </span> */}
            </h4>
            {topMenu.menuItems &&
              topMenuSelected === topMenu.id &&
              topMenu.menuItems.map((item) => (
                <>
                  <div
                    id={item.id}
                    className="video-menuitem"
                    onClick={() =>
                      this.selectMenuItem(item.id, item.anchor, item.name)
                    }
                  >
                    <span
                      style={{
                        fontWeight: `${
                          subMenuSelected === item.id ? 600 : 400
                        }`,
                        color: `${
                          subMenuSelected === item.id ? "#17a2b8" : "#474B4E"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      {item.name}
                    </span>
                  </div>
                </>
              ))}
          </>
            )}
          </>
        ))}
      </div>
    );
  }

  renderNoDataAvailable = () => {
    return (
      <div style={{width: '100%', textAlign: 'center'}}>No data available for this time range.<br />There are {this.props.lessonLabel.toLowerCase()}s between {this.state.earliestDate} and {this.state.lastDate.slice(0,10)}</div>
    )
  }
  renderTalkByStudentsTable = () => {
    const totalLessons = this.state.videosData.length;
    const tabData = this.filterByDate(
      this.filterPercentTalk(this.state.videosData)
    );

    if( tabData.length === 0 ) {
      return (this.renderNoDataAvailable());
    }

    let totalSum = 0;
    tabData.forEach((row) => {
      totalSum += row.student_percent;
    });
    let localAverage = totalSum / totalLessons;

    return (
      <div>
        <span>
          On average, the students talk <b>{localAverage.toFixed(2)}%</b> of the
          time.
        </span>
        {!this.state.isCoach &&
        <p>
          On average for all the tutors in our data set, students talk{" "}
          <b>{this.state.avgStudentTalk}%</b> of the time.
        </p>
        }
      </div>
    );
  };


  // renderTable for below chart
  renderSingleDataStat = (data, stat, message) => {
    const totalLessons = data.length;
    const categories = Object.keys(data[0]);

    // const tableData = categories.filter(cat => (cat === stat)).map(cat => ({value: cat[stat], sum: 0, average: 0}));
    let sum = 0;

    data.forEach((row) => {
      sum += row[stat];
    });

    const average = sum / totalLessons;

    const decimalFixed = 0;
    return (
      <div style={{ marginLeft: 78, lineHeight: "28px" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: message.replace(
              "!!average!!",
              `${average.toFixed(decimalFixed)}`
            ),
          }}
        ></div>
      </div>
    );
  };

  // talk moves chart
  renderChart1 = (type) => {
    const COLORS = [
      "#f6cc46",
      "#fcdb71",
      "#fae5a2",
      /* '#3dc9c4', */ "#9ee4e1",
      "#ff7359",
      "#ffb9ac",
    ];
    const feedbackGroup =
      this.state.topMenuSelected === "menu-ttm" ? "teacher" : "student";
    const data = this.filterByDate(
      this.filterFrequency(this.state.videosData, feedbackGroup)
    );

    if( data.length === 0 ) {
      return (this.renderNoDataAvailable());
    }

    const title = `How Many Talk Moves ${
      feedbackGroup === "teacher" ? "Does the Tutor" : "Do the Students"
      } Use, on Average?`;

    const yLabel = `Frequency of each talk move for ${
        feedbackGroup === "teacher" ? " the Tutor" : "Students"}`;

    return (
      <div>
        <div className="trend-content">
          <div>
            <SimpleLineChart
              yLabel={yLabel}
              data={data}
              categories={Object.keys(
                this.state.videosData[0].tb_feedback.frequency[feedbackGroup]
              )}
              labelPosition="insideBottomLeft"
              handleCatagoryCaptilization={this.handleCatagoryCaptilization}
              showLegend={true}
              colors={COLORS}
              type={type}
              history={this.props.history}
            ></SimpleLineChart>
          </div>
          <div
            style={{
              margin: "12px 48px 48px 78px",
              border: "1px solid gray",
              borderRadius: 3,
              padding: 18,
            }}
          >
            <div
              style={{
                fontWeight: 600,
                color: "black",
                fontSize: 18,
                width: "100%",
                textAlign: "center",
                marginBottom: 18,
              }}
            >
              {title}
            </div>
            <RenderDataTable
              data={data}
              feedback={feedbackGroup}
              isPercent={false}
              talkMovesNames={this.state.talkmovesNames}
              colors={COLORS}
              lessonLabel={this.props.lessonLabel}
              isCoach={this.state.isCoach}
            ></RenderDataTable>
          </div>
          <div>* All values are standarized to a 55 minute class length</div>
        </div>
      </div>
    );
  };

  // Stacked Bar Chart
  renderChart2 = () => {
    const feedbackGroup =
      this.state.topMenuSelected === "menu-ttm" ? "teacher" : "student";
    const data = this.filterByDate(
      this.filterCategories(this.state.videosData, feedbackGroup)
    );
    const title = `How Often ${
      feedbackGroup === "teacher" ? "Does the Tutor" : "Do the Students"
    } Use Each Talk Move Category, On Average?`;

    if( data.length === 0 ) {
      return (this.renderNoDataAvailable());
    }
    return (
      <div>
        <div className="trend-content">
          <div>
            <StackedChart
              data={data}
              categories={[
                "learning_community_percent",
                "content_knowledge_percent",
                "rigorous_thinking_percent",
              ]}
              legendNames={[
                "Learning Community",
                "Content Knowledge",
                "Rigorous Thinking",
              ]}
              handleCatagoryCaptilization={this.handleCatagoryCaptilization}
              colors={["#f6cc46", "#3cc9c5", "#ff7358"]}
              history={this.props.history}
            ></StackedChart>
          </div>
          <div
            style={{
              margin: "12px 48px 48px 78px",
              border: "1px solid gray",
              borderRadius: 3,
              padding: 18,
            }}
          >
            <div
              style={{
                fontWeight: 600,
                color: "black",
                fontSize: 18,
                width: "100%",
                textAlign: "center",
                marginBottom: 18,
              }}
            >
              {title}
            </div>
            <RenderDataTable
              data={data}
              feedback={feedbackGroup}
              isPercent={true}
              talkMovesNames={this.state.talkmovesNames}
              colors={["#f6cc46", "#3cc9c5", "#ff7358"]}
              lessonLabel={this.props.lessonLabel}
              isCoach={this.state.isCoach}
            ></RenderDataTable>
          </div>
        </div>
      </div>
    );
  };

  // Simple Bar Chart
  renderChart3 = () => {
    const data = this.filterByDate(
      this.filterPercentTalk(this.state.videosData)
    );

    if( data.length === 0 ) {
      return (this.renderNoDataAvailable());
    }

    return (
      <div>
        <div className="trend-content">
          <div
            style={{
              fontStyle: "italic",
              width: "100%",
              textAlign: "center",
              marginBottom: 18,
            }}
          >
            Percent of classroom talk by students.
          </div>
          <div>
            <SimpleBarChart
              data={data}
              categories={["student_percent"]}
              handleCatagoryCaptilization={this.handleCatagoryCaptilization}
              colors={["#8884d8"]}
              history={this.props.history}
            ></SimpleBarChart>
          </div>
          <div
            style={{
              margin: "12px 48px 48px 78px",
              border: "1px solid gray",
              borderRadius: 3,
              padding: 18,
            }}
          >
            {this.renderTalkByStudentsTable()}
          </div>
        </div>
      </div>
    );
  };

  renderChart4 = () => {
    // one word answers
    const COLORS = ["#f6cc46", "#ff7359", "#ffb9ac"];
    //   const feedbackGroup = this.state.topMenuSelected === 'menu-ttm' ? 'teacher' : 'student';
    const message = `On average, <b>!!average!!%</b> of the students' utterances contained more than one word`;
    const chartData = this.filterByDate(
      this.filterUtterances(this.state.videosData)
    );

    if( chartData.length === 0 ) {
      return (this.renderNoDataAvailable());
    }

    return (
      <div>
        <div className="trend-content">
          <div
            style={{
              fontStyle: "italic",
              width: "100%",
              textAlign: "center",
              marginBottom: 18,
            }}
          >
            Percentage of student utterances with more than one word
          </div>
          <div>
            <SimpleLineChart
              yLabel={"Percentage"}
              labelPosition="insideBottomLeft"
              data={chartData}
              categories={["student_more_one_word_percent"]}
              handleCatagoryCaptilization={this.handleCatagoryCaptilization}
              colors={COLORS}
              history={this.props.history}
            ></SimpleLineChart>
          </div>
          <div
            style={{
              margin: "12px 48px 48px 78px",
              border: "1px solid gray",
              borderRadius: 3,
              padding: 18,
            }}
          >
            {this.renderSingleDataStat(
              chartData,
              "student_more_one_word_percent",
              message,
              true
            )}
          </div>
        </div>
      </div>
    );
  };

  renderChart5 = () => {
    // teacher wait time
    const COLORS = ["#f6cc46", "#ff7359", "#ffb9ac"];
    const chartData = this.filterByDate(
      this.filterUtterances(this.state.videosData)
    );

    if( chartData.length === 0 ) {
      return (this.renderNoDataAvailable());
    }

    const message = `On average, <b>!!average!!%</b> of the tutor's sentences include at least three seconds wait time`;
    return (
      <div>
        <div className="trend-content">
          <div
            style={{
              fontStyle: "italic",
              width: "100%",
              textAlign: "center",
              marginBottom: 18,
            }}
          >
            Percentage of teacher sentences with at least 3 seconds wait time
          </div>
          <div>
            <SimpleLineChart
              yLabel={"Percentage"}
              labelPosition="insideBottomLeft"
              data={chartData}
              categories={["teacher_wait_time_percent"]}
              handleCatagoryCaptilization={this.handleCatagoryCaptilization}
              colors={COLORS}
              history={this.props.history}
            ></SimpleLineChart>
          </div>
          <div
            style={{
              margin: "12px 48px 48px 78px",
              border: "1px solid gray",
              borderRadius: 3,
              padding: 18,
            }}
          >
            {this.renderSingleDataStat(
              chartData,
              "teacher_wait_time_percent",
              message,
              true
            )}
          </div>
          <div>
            {/* <p className="amount-footnote">
              * The TalkMoves application identifies the teacher as the person
              who speaks the most. It will not provide accurate feedback if the
              students talk the majority of the time.
            </p> */}
          </div>
        </div>
      </div>
    );
  };

  renderMathTermsChart = () => {
    const COLORS = ["#f6cc46", "#ff7359", "#ffb9ac"];
    const chartData = this.filterByDate(
      this.filterMathTerms(this.state.videosData)
    );

    if( chartData.length === 0 ) {
      return (this.renderNoDataAvailable());
    }
    const message = `On average, <b>!!average!!</b> math terms used per ${this.props.lessonLabel.toLowerCase()}`;

    return (
      <div>
        <div className="trend-content">
          <div
            style={{
              fontStyle: "italic",
              width: "100%",
              textAlign: "center",
              marginBottom: 18,
            }}
          >
            Unique Math Terms Used During {this.props.lessonLabel}s
          </div>
          <div>
            <SimpleLineChart
              // yLabel={'Percentage'}
              // labelPosition={false}
              data={chartData}
              categories={["total_count", "total_student", "total_teacher"]}
              handleCatagoryCaptilization={this.handleCatagoryCaptilization}
              colors={COLORS}
              history={this.props.history}
            ></SimpleLineChart>
          </div>
          <div style={{ marginLeft: 100 }}>
            <i
              className="fas fa-square"
              style={{ marginRight: 8, color: "#17a2b8" }}
            ></i>
            Total Math Terms
            <i
              className="fas fa-square"
              style={{ marginRight: 8, marginLeft: 12, color: "#c59c16" }}
            ></i>
            Student Math Terms
            <i
              className="fas fa-square"
              style={{
                marginRight: 8,
                marginLeft: 12,
                marginBottom: 18,
                color: "#cb5b47",
              }}
            ></i>
            Teacher Math Terms
          </div>
          <div
            style={{
              margin: "12px 48px 48px 78px",
              border: "1px solid gray",
              borderRadius: 3,
              padding: 18,
            }}
          >
            {this.renderSingleDataStat(chartData, "total_count", message, true)}
          </div>
          <div>
            {/* <p className="amount-footnote">
              * The TalkMoves application identifies the teacher as the person
              who speaks the most. It will not provide accurate feedback if the
              students talk the majority of the time.
            </p> */}
          </div>
        </div>
      </div>
    );
  };

  renderFeedbackButtons() {
    return (
      <Row className="lesson-panel-row">
        <h4 style={{ width: "100%", textAlign: "center", marginBottom: 24 }}>
          See Talk Moves Feedback for:
        </h4>
        {/* <Col sm={4} style={{textAlign: 'center'}}><Button size='md' className="lesson-CTA-button" onClick={() => this.selectTopLevelNav('menu-ttm', 'lca')}><div className="lesson-CTA">Teacher</div> Trends</Button></Col>
            <Col sm={4} style={{textAlign: 'center'}}><Button size='md' className="lesson-CTA-button" onClick={() => this.selectTopLevelNav('menu-stm', 'lca')}><div className="lesson-CTA">Student</div> Trends</Button></Col>
            <Col sm={4} style={{textAlign: 'center'}}><Button size='md' className="lesson-CTA-button" onClick={() => this.selectTopLevelNav('menu-otm', 'lca')}><div className="lesson-CTA">Discourse</div> Trends</Button></Col>
     */}
        <Col sm={6} style={{ textAlign: "center" }}>
          <div
            className="lesson-CTA-link"
            onClick={() =>
              this.selectTopLevelNav("menu-ttm", "lca", "Tutor Trends")
            }
          >
            Tutor Trends
          </div>
        </Col>
        <Col sm={2} style={{ textAlign: "center" }}>
          <div
            className="lesson-CTA-link"
            onClick={() =>
              this.selectTopLevelNav("menu-stm", "lca", "Student Trends")
            }
            >
            Student Trends
          </div>
        </Col>
        <Col sm={6} style={{ textAlign: "center" }}>
          <div
            className="lesson-CTA-link"
            onClick={() =>
              this.selectTopLevelNav("menu-otm", "lca", "Other Discourse Trends")
            }
          >
            Other Discourse Trends
          </div>
        </Col>
      </Row>
    );
  }

  renderPageTop() {
    const maxDate = new Date(this.state.lastDate);
    const sDay = this.state.selectedStartDate.length === 10 ? new Date(`${this.state.selectedStartDate} 01:00`) : new Date(this.state.selectedStartDate);
    const eDay = this.state.selectedEndDate.length === 10 ? new Date(`${this.state.selectedEndDate} 01:00`) : new Date(this.state.selectedEndDate);

    return (
      <Row id="trends-content" className="lesson-panel-toprow">
        <h3 style={{ textAlign: "center", width: "100%" }}>
          How Has the Tutor's Instruction Changed Over Time?
        </h3>

        <div
          style={{
            minWidth: "50%",
            margin: "24px auto",
            borderRadius: 8,
            border: "1px solid gray",
            padding: 18,
            textAlign: "center",
          }}
        >
          <div>Provide a date range time or unselect for All Tutorials:</div>
          <div>
            Include All {this.props.lessonLabel}s, or
            <span
              className="calendar-item"
              style={{ marginTop: 18, marginLeft: 15 }}
            >
              <Form.Check
                type="switch"
                checked={!this.state.allVideos}
                onChange={this.handleSwitch}
                id="custom-switch"
                label={` ${this.props.lessonLabel} Date Range`}
              />
            </span>
          </div>
          {!this.state.allVideos && (
            <div>
              <div className="calendar-item">
                From: &nbsp;&nbsp;
                <DatePicker
                  selected={sDay}
                  minDate={new Date(this.state.earliestDate)}
                  maxDate={maxDate}
                  onChange={(selectedStartDate) =>
                    this.setStartDate(selectedStartDate)
                  }
                  dateFormat="yyyy-MM-dd"
                  className="calendar-input-box"
                />
              </div>
              <div className="calendar-item">
                To: &nbsp;&nbsp;
                <DatePicker
                  selected={eDay}
                  minDate={sDay}
                  maxDate={maxDate}
                  onChange={(selectedEndDate) =>
                    this.setEndDate(selectedEndDate)
                  }
                  dateFormat="yyyy-MM-dd"
                  todayButton="Today"
                  className="calendar-input-box"
                />
              </div>
            </div>
          )}
        </div>
      </Row>
    );
  }

  renderTeacherPanel() {

    this.recordPageAction( {        
      'eventName': 'trendsForTutor',
      'action': 'talkMovesTrends',	
    })

    return (
      <div id="ttm" className="video-lesson lesson-feedback">
        {this.state.isLoading === false && (
          <>
            <Row id="ttm-talkmoves">
              {" "}
              {/* ********************** talk moves used ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 34,
                  }}
                >
                  <h4>
                    <b>How Have the Tutor's Talk Moves Changed Over Time?</b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart1("teachers")}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>

            <Row id="ttm-categories" className="anchor-margin">
              {" "}
              {/* ********************** talk moves used ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>
                      How Did the Tutor's Use of Talk Moves Categories Change Over Time?
                    </b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart2("teachers")}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }

  renderStudentPanel() {

    this.recordPageAction( {        
      'eventName': 'trendsForTutor',
      'action': 'studentTrends',	
    })
			    
    return (
      <div id="stm" className="video-lesson  lesson-feedback">
        {this.state.isLoading === false && (
          <>
            <Row id="stm-talkmoves">
              {" "}
              {/* ********************** talk moves used ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 34,
                  }}
                >
                  <h4>
                    <b>How Did the Students' Talk Moves Change Over Time?</b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart1("students")}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>

            <Row id="stm-categories">
              {" "}
              {/* ********************** talk moves used ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>
                      How Did the Students' Use of Talk Moves Categories Change
                      Over Time?
                    </b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart2("students")}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }

  renderOtherPanel() {

    this.recordPageAction( {        
      'eventName': 'trendsForTutor',
      'action': 'otherDiscourseTrends',
    })

    return (
      <div id="otm" className="video-lesson  lesson-feedback">
        {this.state.isLoading === false && (
          <>
            <Row id="otm-student-talk">
              {" "}
              {/* ********************** amount (%) of student talk ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 34,
                  }}
                >
                  <h4>
                    <b>How is the Amount of Student Talk Changing Over Time?</b>
                  </h4>
                  {/* <i>Percent of classroom talk by students.</i> */}
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart3()}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>

            <Row id="otm-one-word">
              {" "}
              {/* ********************** one word answers ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>
                      How Often Are the Students Giving More than One-word
                      Answers?
                    </b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart4()}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>
            <Row id="otm-think">
              {" "}
              {/* ********************** Time to think ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>How Often Does the Tutor Give Students Time To Think?</b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderChart5()}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>
            <Row id="otm-math-terms">
              {" "}
              {/* ********************** Time to think ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>How Often Is Math Language Used?</b>
                  </h4>
                </div>

                <div style={{ width: "100%", marginTop: 24 }}>
                  <div style={{ width: "100%", display: "inline-block" }}>
                    {this.renderMathTermsChart()}
                    {/* <div style={{textAlign: 'center'}}>Number of Talk Moves Used</div> */}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }

  renderPage() {
    const { topMenuSelected } = this.state;
    return (
      <div id="lca" className="lessons-page">
        {this.renderLeftNav()}

        <div className={`lessons-content-area`}>
          {this.renderPageTop()}
          {/* { (!topMenuSelected || topMenuSelected === 'this-lesson') && this.renderTopPanel()} */}
          {topMenuSelected === "menu-ttm" && this.renderTeacherPanel()}
          {topMenuSelected === "menu-stm" && this.renderStudentPanel()}
          {topMenuSelected === "menu-otm" && this.renderOtherPanel()}
          {this.renderFeedbackButtons()}
        </div>
      </div>
    );
  }

  render() {
    return <div className="">{this.renderPage()}</div>;
  }
}
