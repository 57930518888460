import React, { Component } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, } from 'recharts';

import LoadingIndicator from '../components/LoadingIndicator';
import "../containers/Videos.css";

const matchTalkMoves = (label) => {
    let bodyContent;
    //Teacher Tips
        if(label === "Keep Together") {
            bodyContent = `Keeping everyone together.
            Prompting students to be active listeners.
            Orienting students to each other.`
        }
        if(label === "Students Relate") {
            bodyContent = `Getting students to relate to another’s idea.
            Prompting students to react to what a classmate said.`
        }
        if(label === "Restate") {
            bodyContent = `Repeating all or part of what a student says word for word.`
        }
        if(label === "Accuracy") {
            bodyContent = `Pressing for accuracy.
            Prompting students to make a mathematical contribution or use mathematical language.`
        }
        if(label === "Revoice") {
            bodyContent = `Repeating what a student says but adding on to it or changing the wording.
            Using at least one key mathematical word or key idea from what a student said.`
        }
        if(label === "Reasoning") {
            bodyContent = `Pressing for reasoning.
            Prompting students to explain or provide evidence, share their thinking behind a decision, or connect ideas or representations.`
        }
        //Student Tips
        if(label === "Asking For More Information") {
            bodyContent = `Student requests more information about a math topic.
            Student says they are confused or need help.`
        }
        if(label === "Relating to Others") {
            bodyContent = `Use classmate’s ideas as a basis for own idea.
            Comment on another student’s idea.
            Ask questions about another student’s idea.`
        }
        if(label === "Making a Claim") {
            bodyContent = `Student makes a mathematical claim.
            Student makes a factual statement about a math concept.
            Student lists one step in how they arrived at their answer.`
        }
        if(label === "Providing Evidence") {
            bodyContent = `Student explains their thinking.
            Student provides evidence for a mathematical claim.
            Student talks about their reasoning.`
        }
        return bodyContent;
}

const FeedbackTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="video-tooltip" >
          <p className="label">{`${label} : ${payload[0].value}`}</p>
          <p className="desc">{matchTalkMoves(label)}</p>
        </div>
      );
    }
  
    return null;
  };

class RenderSimpleBarChart extends Component {
	constructor(props) {
		super();
        this.state = {
            isLoading: false,
            isStudent: props.student,
			video_id: props.video_id,
            stats_data: props.stats_data.tb_feedback.frequency,
            isVertical: true,           // this is for horizontal bars!!!
        };
        
    }
    handleYClick = (event) => {
        const val = event.value.replace(/ /g,'')
        const links = ({
            KeepTogether: "/definitions#ttm-together",
            StudentsRelate: "/definitions#ttm-relating",
            Restate: "/definitions#ttm-restating",
            Accuracy: "/definitions#ttm-accuracy",
            Revoice: "/definitions#ttm-revoicing",
            Reasoning: "/definitions#ttm-reasoning",
            RelatingtoOthers: "/definitions#stm-relating",
            AskingForMoreInformation: "/definitions#stm-asking",
            MakingaClaim: "/definitions#stm-claim",
            ProvidingEvidence: "/definitions#stm-evidence",
        });
        let url = links[val];
        const { history } = this.props;
        if (history) {
        history(url)
        }
    }
	render () {
        if (this.state.isLoading) {
            return (
                <LoadingIndicator message='Loading talk moves...' />
            )
        }
        
		if (this.state.stats_data !== undefined) {
            const {stats_data}  = this.state;

			let chartData = [
				{name: 'Keep Together', frequency: stats_data.teacher['keeping_everyone_together'], key: 'chart-keep-together'},
				{name: 'Students Relate', frequency: stats_data.teacher['getting_students_to_relate'],key: 'chart-studets-relate'},
                {name: 'Restate', frequency: stats_data.teacher['restating'], key: 'chart-restate'},
                {name: 'Accuracy', frequency: stats_data.teacher['press_for_accuracy'], key: 'chart-accuracy'},
				{name: 'Revoice', frequency: stats_data.teacher['revoicing'], key: 'chart-revoice'},
                {name: 'Reasoning', frequency: stats_data.teacher['press_for_reasoning'], key: 'chart-reasoning'}
            ];
            let COLORS = ['#f6cc46', '#fcdb71', '#fae5a2', /* '#3dc9c4', */'#9ee4e1', '#ff7359', '#ffb9ac'];
            if (this.state.isStudent) {
                chartData = [
                    { name: 'Relating to Others', frequency: stats_data.student['relating_to_another_student'] },
                    { name: 'Asking For More Information', frequency: stats_data.student['asking_for_more_information'] },
                    { name: 'Making a Claim', frequency: stats_data.student['making_a_claim'] },
                    { name: 'Providing Evidence', frequency: stats_data.student['providing_evidence'] },
                ];
                COLORS = [ '#fcdb71', '#f6cc46',  '#9ee4e1', '#ff7359', ];
            }
			return (
                <div>
				<ResponsiveContainer width="100%" height={300} > 
                        {this.state.isVertical ?
                            <BarChart
                                data={chartData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                layout="vertical"
                            >
                                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                <CartesianGrid stroke="#e0e0e0" horizontal={false} />
                             
                                
                                <XAxis type="number" />
                                <YAxis tickSize={8} tickMargin={8} type="category" dataKey={'name'} interval={0} fontSize={14} offset={-20} angle={-30} height={76} textAnchor="end" onClick={e => this.handleYClick(e)} />
                                <Tooltip content={ <FeedbackTooltip /> } />
                                <Bar dataKey="frequency" barSize={40} fill="#000000" >
                                    {
                                        chartData.map((entry, index) => {
                                            const color = COLORS[index]
                                            return <Cell key={entry.key} fill={color} />;
                                        })
                                    }
                                </Bar>
                            </BarChart>
                            :
                            <BarChart
                                data={chartData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey={'name'} interval={0} fontSize={14} angle={-30} height={76} textAnchor="end" />

                                <YAxis width={60} label={{ value: `# Used In ${this.props.lessonLabel}`, angle: -90, position: 'insideBottomLeft' }} />
                               <Tooltip />
                                <Bar dataKey="frequency" barSize={40} fill="#000000">
                                    {
                                        chartData.map((entry, index) => {
                                            const color = COLORS[index]
                                            return <Cell key={entry.key} fill={color} />;
                                        })
                                    }
                                    {}
                                </Bar>
                            </BarChart>
                        }
				</ResponsiveContainer>
                </div>
			);
		}
	}
}

export default RenderSimpleBarChart;