import React, { Component } from "react";
import { Auth, API } from "aws-amplify";
import {
  FormGroup,
  FormControl,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import ControlLabel from "../components/ControlLabel";

import "./Signup.css";

export default class Signup extends Component {
  constructor(props) {
    super();

    this.state = {
      isLoading: false,
      firstName: "",
      lastName: "",
      district: "",
      school: "",
      email: "",
      emailAgain: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      registrationCode: "",
      newUser: null,
      validForm: true,
      errors: {
        firstName: false,
        lastName: false,
        school:false,
        district:false,
        email:false,
        emailAgain:false,
        registrationCode:false,
        password:false,
        confirmPassword:false,
      },
      dirty: {
        firstName: false,
        lastName: false,
        school:false,
        district:false,
        email:false,
        emailAgain:false,
        registrationCode:false,
        password:false,
        confirmPassword:false,
      },

      newUser: null, 
      validpasswordForm: true,

      showPasswords: false,
      pwMin8: false,
      pwLC1: false,
      pwUC1: false,
      pwNum1: false,
      pwSC1: false,
      passwordsMatch: false,
      noSpaces: false,
    };

    const page_data = 'signup';
    // eslint-disable-next-line no-undef
    gtag('event', 'page_view', {
        page_title: page_data,
        page_location: `/${page_data}`,
        page_path: `/${page_data}`,
        send_to: 'UA-91361306-3'
      });
  }

  validatePassword() {
    const { password, confirmPassword, oldPassword } = this.state;

    const pwLC1_regex = /.*[a-z].*/;
    const pwUC1_regex = /.*[A-Z].*/;
    const pwNum1_regex = /.*\d.*/;
    const pwSC1_regex = /.*\W.*/;
    let passwordIsValid = false;

    const testPassword = password  || ' ';

      const match = testPassword === confirmPassword;
      const pwMin8 = testPassword.length > 7;
      const pwLC1 = pwLC1_regex.test(testPassword);
      const pwUC1 = pwUC1_regex.test(testPassword);
      const pwNum1 = pwNum1_regex.test(testPassword);
      const pwSC1 = testPassword !== ' ' && pwSC1_regex.test(testPassword);
      this.setState({ passwordsMatch: match, pwMin8, pwLC1, pwUC1, pwNum1, pwSC1 });
      passwordIsValid = match && pwLC1 && pwUC1 && pwNum1 && pwSC1;

    return passwordIsValid;
  }
  validateForm() {

    const passwordGood = this.validatePassword();
      // const passwordRequired = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
      // const passwordGood = true; // this.state.password.match(passwordRequired);
      const isGood =  this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      // this.state.school.length > 1 &&      -- no longer required
      // this.state.district.length > 1 &&
      this.state.email.length > 0 &&
      this.state.email === this.state.emailAgain && 
      this.state.registrationCode.length > 5 &&
      passwordGood &&
      this.state.password.length > 7 &&
      this.state.password === this.state.confirmPassword;
      this.setState({formValid: isGood});
    return isGood;
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }
 
  handleChange = (e) => {
      //const id = event.target.id;
      //const validForm = this.validateForm();
    //this.setState({ [event.target.id]: event.target.value, validForm
      const name = e.target.id;
      const value = e.target.value;
      if (name === 'password') {
        if( value.includes(' ')) {
          this.setState({noSpaces: true});
          return;
        }
      }
      this.setState({[name]: value, noSpaces: false},
                  () => { this.validateField(name, value) });

    //});
  }

  validateField(fieldname,value){
    let valid = false;
  
    const isDirty = this.state.dirty[fieldname]
    //debugger;
    switch(fieldname) {
      case "firstName":
        case "lastName":
        case 'district':
        case 'school':
        valid = !isDirty || (value.length > 0)
        break;    
      case 'email':
        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        valid = !isDirty || (emailValid)
        break;
      case 'emailAgain':
        valid = !isDirty || (value === this.state.email)
        break;
      case 'registrationCode':
        valid = !isDirty || (value.length > 5)
        break;
      case 'password':
        const passwordValid = value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/);
        valid = !isDirty || (passwordValid)
        break;
      case 'confirmPassword':
        valid = !isDirty || (value === this.state.password)
        break;
        
      default:
        break;
    }
    const newDirty =this.state.dirty;
    newDirty[fieldname] = true;
    const fe = this.state.errors; 
    fe[fieldname] = !valid;

    
    this.setState({
    
      dirty: newDirty,
      errors: fe
    }, this.validateForm);
  }
    
/*
  handleChange = event => {
      const id = event.target.id;
      const validpasswordForm = this.validateForm();
    this.setState({
      [event.target.id]: event.target.value,
      validpasswordForm
    });
  }
  */

  handleSubmit = async event => {
    event.preventDefault();
    const body = this.state;
    this.setState({ isLoading: true });
    const data = await API.post(
        "users",
        `users/create`,
        { body }
      );
    if( data.validCode && data.validNewUser && data.createdNewUser) {
        // ok.. ready to create the user in cognito
        try {
            const newUser = await Auth.signUp({
              username: this.state.email,
              password: this.state.password
            });
            this.setState({
              newUser
            });
          } catch (e) {
            alert(e.message);
          }
        
    } else {
        let message = '';
        if(!data.validCode) {
            message = `Invalid registration code`;
        } else if(!data.validNewUser) {
            message = 'User already exists. ';
        } else if(!data.createNewUser) {
            message = data.createError;
            console.log('cognigo create user error: ', data.createError);
        }

        alert(message)
    }
    this.setState({ isLoading: false });

  }

  
  handleConfirmationSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);
      

      
      this.props.userHasAuthenticated(true);
      this.props.history("/");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <div className="help-block">Please check your email for the code.</div>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    const { password, confirmPassword, showPasswords, pwMin8, pwLC1, pwUC1, pwNum1, pwSC1, validForm, passwordsMatch } = this.state;

    return (
      <>
      <form onSubmit={this.handleSubmit}>
        <FormGroup bsSize="large">
          {/* <ControlLabel>Email</ControlLabel> */}
          <div></div>
          <FormControl
            id="firstName"
            autoFocus
            type="text"
            placeholder="First Name"
            value={this.state.firstName}
            onChange={this.handleChange}
            className={'signup-field'}
            isInvalid = {this.state.errors.firstName}
          />
         <FormControl
            id='lastName'
            type="text"
            placeholder="Last Name"
            value={this.state.lastName}
            onChange={this.handleChange}
            className={'signup-field'}
            isInvalid = {this.state.errors.lastName}
          />
          <FormControl
            id="email"
            type="email"
            placeholder="email"
            value={this.state.email}
            onChange={this.handleChange}
            className={'signup-field'}
            isInvalid = {this.state.errors.email}
          />
        <FormControl
            id="emailAgain"
            type="email"
            placeholder="confirm email"
            value={this.state.emailAgain}
            onChange={this.handleChange}
            className={'signup-field'}
            isInvalid = {this.state.errors.emailAgain}
          />
           <FormControl
            id="district"
            type="text"
            placeholder="District (optional)"
            value={this.state.district}
            onChange={this.handleChange}
            className={'signup-field'}
            isInvalid = {this.state.errors.district}
          />
          <FormControl
            id="school"
            type="text"
            placeholder="School (optional)"
            value={this.state.school}
            onChange={this.handleChange}
            className={'signup-field'}
            isInvalid = {this.state.errors.school}
          />
        </FormGroup>

        <FormGroup >
 
          <FormControl
            id="registrationCode"
            value={this.state.registrationCode}
            onChange={this.handleChange}
            type="registrationCode"
            placeholder="Registration Code"
            className={'signup-field'}
            />
        </FormGroup>

        {/* <div style={{fontSize:12}}>Password: Must be at least 8 characters, one lowercase letter, 
        one uppercase letter, one number, and one special character:
        </div> */}
              <FormGroup >
                <FormControl
                    id="password"
                    value={password}
                    onChange={this.handleChange}
                    type={showPasswords ? 'text' : 'password'}
                    placeholder="password"
                    className={'signup-field'}
                    isInvalid = {this.state.errors.password}
                />
                </FormGroup>

        <FormGroup >
          {this.state.noSpaces && (
            <div style={{color: 'red'}}>Spaces not allowed in passwords</div>
          )}

          <FormControl
            id="confirmPassword"
            value={confirmPassword}
            onChange={this.handleChange}
            type={showPasswords ? 'text' : 'password'}
            placeholder="confirm password"
            isInvalid = {this.state.errors.confirmPassword}
          />
        </FormGroup>
        <FormGroup>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={showPasswords}
                onChange={() => this.setState({ showPasswords: !showPasswords })}
              />
              <span>Show Passwords</span>
            </div>

          </FormGroup>

        {!this.state.formValid
          ?
          <LoaderButton
            block
            bsSize="large"
            // disabled={!this.validateForm()}
            disabled={true}
            text="Form Not Complete"

          />
          :
          <LoaderButton
            block
            bsSize="large"
            // disabled={!this.validateForm()}
            disabled={!this.state.formValid}
            type="submit"
            isLoading={this.state.isLoading}
            text="Register"
            loadingText="Registering…"
          />
        }
      </form>
              {/* ************** panel to show password progress *********************** */}
              <div className='password-prompter'>
          <b>New Password Requires: </b>
          <div className='password-prompter-inside'>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwMin8}
              />
              Min 8 Characters</div>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwLC1}
              />
              1 lower case letter</div>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwUC1}
              /> 1 upper case letter</div>

            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwNum1}
              /> 1 number</div>
            <div>
              <input
                type="checkbox"
                className="checkbox-checkbox"
                checked={pwSC1}
              /> 1 special character</div>
          </div>
        </div>
      </>
      
    );
  }

  

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
