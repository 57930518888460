import React, { useState } from 'react';

import { Button, Modal, ProgressBar } from "react-bootstrap";

import moment from 'moment';
import { API } from "aws-amplify";


const ReportDownload = props => {
  const { columns, data, type, selectedTeacher, totalVideos } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);

  /**
   * downloadLocallyGeneratedCsv
   *  Given a string of CSV data, download it to the user's computer.
   *  This is used by the Download Current View button.
   */
  const downloadLocallyGeneratedCsv = (csvString, fullReport = false) => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    const date = moment().format('YYYY-MM-DD');
    const downloadName = `${type === 'teacher' || fullReport ? 'Tutor Data' : 'Aggregate Data'} ${selectedTeacher === 'All Tutors' || fullReport ? '' : `- ${selectedTeacher} `}- ${date}.csv`
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, downloadName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', downloadName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  /**
   * downloadCsvFromS3
   *   Given a signed ULR, download the data report CSV from S3 to the user's computer.
   */
  const downloadCsvFromS3 = downloadLink => {
    const date = moment().format('YYYY-MM-DD');
    const downloadName = `${type === 'teacher' ? 'Tutor Data' : 'Aggregate Data'} ${selectedTeacher === 'All Tutors' ? '' : `- ${selectedTeacher} `}- ${date}.csv`
      const link = document.createElement('a');
      if (link.download !== undefined) {;
        link.href = downloadLink;
        link.setAttribute('download', downloadName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
  };

  /**
   * startProcessingCSV
   *   Create a CSV locally from the data in the current view.
   *   Download it to the user's computer.
   */ 
  const startProcessingCSV = () => {
    setIsProcessing(true);
    setProgress(0);

    // create the csv from the data and column headers...
    let lines = '';
    const rows = data.length;

    columns.forEach((c) => {
      lines += `${c.text},`
    });
    lines += '\r\n';

    data.forEach((row, index) => {
      columns.forEach((c) => {
        lines += `${row[c.dataField]}${c.percent ? '%' : ''},`;
      });
      setProgress(Math.ceil(index / rows));
      lines += '\r\n';
    });
    setProgress(100);
    setIsProcessing(false);
    downloadLocallyGeneratedCsv(lines);
  }

  /**
   * startProcessingFullReportCSV
   *    Request the generation of a full report on all stats for all tutorials.
   *    This takes a while, so we show a progress bar.
   *    When the report is ready, we download it from S3.
   */
  const startProcessingFullReportCSV = async() => {
    setIsProcessing(true);
    setProgress(0);

    // kick off the big, slow process
    setTimeout( () => {
      setProgress(50);
    }, 5000);

    const expectedSeconds = totalVideos / 200;
    let seconds = 0;
    const timer = setInterval(() => {
      const perc = seconds >= expectedSeconds ? 98 : (seconds * 100) / expectedSeconds;
      setProgress(Math.floor(perc));
      seconds++;
    }, 1000);

    const params = `?id=full&fullCSV=true&target=s3`;
    const csvLink = await API.get('teachers', `teachers/getreport/teacher${params}`);

    clearInterval(timer);
    setProgress(100);
    setIsProcessing(false);
    downloadCsvFromS3(csvLink);
  }

  // console.log(columns);
  return (
    <>
      <Button onClick={startProcessingFullReportCSV} className={'tm-report-download '}>Download Full Report</Button>
      <Button onClick={startProcessingCSV} className={'tm-report-download '}>Download Current View</Button>

      {/* Modal for processing... */}
      {isProcessing && (
        <Modal
          show={isProcessing}
          size="lg"
          aria-labelledby="processing-csv"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <div>
                Processing CSV for download....
              </div>
            </Modal.Title>

          </Modal.Header>
          <Modal.Body>
            <div className='tm-report-progress'>
              <ProgressBar 
                style={{ width: '100%' }} 
                animated 
                now={progress} 
                // label={` ${progress}% processed`} 
                />
                <div className='tm-report-percentage'>{`${progress}% processed`}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="default" onClick={() => this.setState({ showUpload: false })}>Cancel</Button>
            {this.state.fileToUpload && (
              <Button onClick={this.onUploadFile}>Upload</Button>
            )} */}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default ReportDownload;
