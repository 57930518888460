import { API } from "aws-amplify";

export async function getMe(userId) {
    const me = await API.get(`users`, `users/me/?userId=${encodeURIComponent(userId)}`);
    sessionStorage.setItem('userId', userId);
    sessionStorage.setItem('userDistrict', me.tb_disctrict); // field is misspelled in Db.
    sessionStorage.setItem('fullName', me.tb_fullName ? me.tb_fullName : userId);
    if(me && me.tb_features){
      try{
        sessionStorage.setItem('features', JSON.stringify(me.tb_features))
      }
      catch(err){
        console.error("Something went weong while setting features in session", err)
      }
    }
    const roles = me && me.tb_roles ? me.tb_roles.split(', ') : [];
    roles.forEach((role) => {
      role = role.toLowerCase().trim();
    })

    const isAdmin = roles.includes('admin') || roles.includes('superadmin') || roles.includes('developer');
    if (isAdmin) {
        sessionStorage.setItem("isAdmin", userId);
    }  else {
      sessionStorage.removeItem("isAdmin");
    }
    const isCoach = roles.includes('coach');
    if (isCoach) {
        sessionStorage.setItem("isCoach",  userId );
    } else {
      sessionStorage.removeItem("isCoach");
    }
    const isHATResearcher = roles.includes('hatresearcher');
    if (isHATResearcher) {
        sessionStorage.setItem("isHATResearcher", userId);
    } else {
      sessionStorage.removeItem("isHATResearcher");
    }

    const isGuest = roles.includes('teacher.guest');
    if (isGuest) {
        sessionStorage.setItem("isGuest", userId);
    }  else {
      sessionStorage.removeItem("isGuest");
    }

    return( { fullName: me.tb_fullName, isAdmin, isGuest, isCoach, isHATResearcher} );

}


export function getCurrentUser() {

    let currentUserId = sessionStorage.getItem('email');
    const admin = sessionStorage.getItem("isAdmin");
    const isCoach = sessionStorage.getItem("isCoach") || sessionStorage.getItem("isHATResearcher");
    if (admin) {
        const shadow = sessionStorage.getItem('shadowId');
        if (shadow) currentUserId = shadow;
    }
    if (isCoach) {
      const tutor = sessionStorage.getItem('tutorId');
      if (tutor) currentUserId = tutor;
  }
    return( currentUserId );

}

export async function createMe(userData){
    const body = {
                teacherId: this.state.teacherId,
                classId: this.state.currentClassId,
                surveyId: this.state.selectedSurvey,
            };
    const surveyResponse = await API.post('users', '/users/create', { body });
}

export async function getUploadKey(fileInfo) {
    try{
    const body = {
        fileName: fileInfo.fileName,
        teacherId: fileInfo.user,
        contentType: fileInfo.contentType,
        fileSize: fileInfo.fileSize
        }
      const result = await API.post('teachers', '/teachers/uploadKeyHandler', { body });
      const readResult = JSON.parse(result.body);
      return readResult.key;
    } catch (ex) {
      console.log(ex)
    }
    return {}
}