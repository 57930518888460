import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import ChangePassword from "./pages/ChangePassword";
import NotFound from "./pages/NotFound";

import Videos from "./containers/Videos";
import Video from "./containers/Video";

import Guide from "./containers/Guide";
import Shadow from './pages/Shadow';
import Reports from './pages/Reports';
import HomeV3 from './pages/HomeV3'; // signed in home page
import HomeV4 from './pages/HomeV4'; // visitor home page
import Upload from './pages/Upload';
import Trends from './pages/Trends';
import Definitions from './pages/Definitions';
import ResearchAndTeam from './pages/ResearchAndTeam';
import Overview from './pages/Overview';
import Recording from './pages/Recording';
import Resources from './pages/Resources';
import HomeCoach from "./pages/HomeCoach";

const AppRoutes = props => {

  const { isAuthenticated } = props;
  if (isAuthenticated) {
    return (
      <Routes>
        <Route path="/" exact element={<HomeV3 {...props} />} />
        <Route path="/login" exact element={<Home {...props} />} />
        <Route path="/research-dashboard" exact element={<Home {...props} />} />
        <Route path="/coach-home" exact element={<HomeCoach {...props} />} />
        <Route path="/hat-researcher-home" exact element={<HomeCoach {...props} />} />
        <Route path="/profile" exact element={<Profile {...props} />} />
        <Route path="/shadow" exact element={<Shadow {...props} />} />
        <Route path="/reports" exact element={<Reports {...props} />} />
        <Route path="/change-password" exact element={<ChangePassword {...props} />} />

        <Route path="/profile" exact element={<Profile {...props} />} />
        <Route path="/videos" exact element={<Videos {...props} />} />
        <Route path="/video/:id" exact element={<Video {...props} />} />
        <Route path="/video" exact element={<Video {...props} />} />
        <Route path="/trends" exact element={<Trends {...props} />} />

        <Route path="/guide" exact element={<Guide {...props} />} />
        <Route path="/newhome" exact element={<HomeV3 {...props} />} />
        <Route path="/upload" exact element={<Upload {...props} />} />

        <Route path="/research-and-team" exact element={<ResearchAndTeam {...props} />} component={ResearchAndTeam} {...props} />
        <Route path="/application-overview" exact element={<Overview {...props} />} component={Overview} {...props} />
        <Route path="/recording-with-swivl" exact element={<Recording {...props} />} component={Recording} {...props} />
        <Route path="/educator-resources" exact element={<Resources {...props} />} component={Resources} {...props} />
        <Route path="/definitions" exact element={<Definitions {...props} />} component={Definitions} {...props} />

        <Route path="/page-not-found" exact element={<NotFound {...props} />} />

        { /* Finally, catch all unmatched routes */}
      <Route path="*" element={<Navigate to="/page-not-found" replace />} />

      </Routes>
    )
  }

  // not authenticated
  return (
    <Routes>
      <Route path="/" exact element={<Home  {...props} />} />
      <Route path="/login" exact element={<Login  {...props} />} />

      {/* deep link for video for a coach.. sign in first */}
      <Route path="/video/:id" exact element={<Login {...props} />} /> 
      <Route path="/signup" exact element={<Signup  {...props} />} />
      <Route path="/login/t/:status" element={<Login  {...props} />} exact component={Login} {...props} />
      <Route path="/signup" exact element={<Signup  {...props} />} component={Signup} {...props} />
      <Route path="/test" exact element={<HomeV4  {...props} />} component={HomeV4} {...props} />

      <Route path="/sample-video" exact element={<Video  {...props} />} component={Video} {...props} />
      <Route path="/sample-trends" exact element={<Trends  {...props} />} component={Trends} {...props} />
      <Route path="/login/reset" exact element={<ResetPassword  {...props} />} component={ResetPassword} {...props} />
      <Route path="/page-not-found" exact element={<NotFound {...props} />} />

      <Route path="/research-and-team" exact element={<ResearchAndTeam {...props} />} component={ResearchAndTeam} {...props} />
      <Route path="/application-overview" exact element={<Overview {...props} />} component={Overview} {...props} />
      <Route path="/recording-with-swivl" exact element={<Recording {...props} />} component={Recording} {...props} />
      <Route path="/educator-resources" exact element={<Resources {...props} />} component={Resources} {...props} />
      <Route path="/definitions" exact element={<Definitions {...props} />} component={Definitions} {...props} />

      { /* Finally, catch all unmatched routes */}
      <Route path="*" element={<Navigate to="/page-not-found" replace />} />
   
    </Routes>
  )
}

export default AppRoutes;