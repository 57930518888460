import React, { Component } from "react";
import { getCurrentUser } from "../libs/appData";
import MyDropzone from "../components/Dropzone"
import "./Upload.css";


export default class Upload extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: true,
            isLoadingTrends: true,
            teacherEmail: encodeURI(getCurrentUser()),
            video_id: 0,
            other_data: [],
            stats_data: null,
            selectedFile: null,
        };
        const page_data = "upload";
        // eslint-disable-next-line no-undef
        gtag("event", "page_view", {
        page_title: page_data,
        page_location: `/${page_data}`,
        page_path: `/${page_data}`,
        send_to: "UA-91361306-3",
    });
  }
  render() {
    return (
      <div>
        <h3 style={{textAlign: 'center', marginBottom: 32}}>Upload a Video File</h3>
        <div style={{ display: 'flex', minHeight: '25vw' }}>
          <MyDropzone />
        </div>
      </div>
    );
  }
}