import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { getUploadKey } from "../libs/appData"
import { API } from "aws-amplify";
import axios from 'axios'
import { ProgressBar } from "react-bootstrap";

import Moment from 'moment'
import config from "../config";
import { s3GuestVideoUpload } from "../libs/awsLib";

//add progress bar
const MyDropzone = (props) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const handleUpload = async (acceptedFiles) => {
    if (!uploading) {
      setUploading(true);
      setProgress(0);
      const file = acceptedFiles[0];
      console.log(file)
      const fileExt = file.name.match(config.UPLOAD_FILE_EXT_REGEX);
 
    const isJSON = file.size ? false : true;
    const fileSize = isJSON ? file.length : file.size;
    const fileType = isJSON ? "mp4/video" : file.type;
    const revisedFileName = file.name.replace(/[^a-zA-Z0-9.-_]/gi, '_');
    const sizeMB = formatBytes(file.size);
    let teacher = sessionStorage.getItem('email');
    let fullName = sessionStorage.getItem('fullName');
    let shadowId = sessionStorage.getItem('shadowId');
    if (shadowId) {
      teacher = shadowId.toLowerCase();
      fullName = sessionStorage.getItem('shadowName');
    }
    const now = new Date().toString().split('-');
    const payload = { 
        'tb-file-description': `${revisedFileName} (HD ${sizeMB})`,
        'tb-bucket-key': ``,
        'tb-source-system': 'manual',
        'tb-source-version': '1',
        'tb-bucket-key-base': ``,
        'tb-content-length': `${file.size}`,
        'tb-teacher-id': teacher,
        'tb-last-modified': now[0],     // date through GMT
        'tb-swivl-project-code': '',
        'tb-file-type': 'VIDEO',
        'tb-video-id': '',
        'tb-file-name': revisedFileName,
        'tb-full-name': fullName,
        'tb-mime-type': file.type,
     };

      try {
        // get upload key for upload full file
        // const uploadKey = await apiManager.getUploadKey(payload);
         const uploadKey = await API.post(
          "videos",
          `videos/getUploadSignedUrl`,
          { body: payload }
        );
        const config = {
          onUploadProgress: (ev) => {
            setProgress( Math.round((ev.loaded / ev.total) * 100))
          },
          headers: {
            "content-type": file.type,
          },
        };
        if (uploadKey && uploadKey.url) {
          const response = await axios.put(uploadKey.url, file, config);
          if (response && response.statusText === "OK") {
            // setOpenSnack({
            //   type: "success",
            //   message: `${file.name} Upload Success`,
            // });
            alert(`${file.name} Upload Success`);
            setUploading(false);
            setProgress(0);
          }
        } else {
          // setOpenSnack({
          //   type: "error",
          //   message: "There was an error uploading the file. Please try again",
          // });
          alert(`Error: Please try again`);
          setUploading(false);
          setProgress(0);
        }
      } catch (e) {
        // setOpenSnack({ type: "error", message: e.response });
        alert(`Error: Upload implementation is not complete yet.`);
        setUploading(false);
        setProgress(0)
      }
    } else {
      alert('Upload in progress. please wait.')
    }
  };

  const handleUploadOriginal  = (async(file) => {
    console.log('handling upload');
    let path = "prod/guestuploads/"
    if(!uploading){
      setUploading(true);
      alert("Uploading video")
      const uploadResult = await s3GuestVideoUpload(
      file, 
      path,
      file[0].name, //filename 
      '0',        // project/video id 
      Moment().format('YYYY-MM-DD'),  // upload original date
      setProgress
    );
      console.log(uploadResult);
      setUploading(false);
    }
    });
 


    const {getRootProps, getInputProps, isDragActive, acceptedFile} = useDropzone({
      accept: ".mp4",
      onDrop: acceptedFile => {handleUpload(acceptedFile)}
      })
  
    return (
      <div style = {{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>
      <div {...getRootProps()} style = {{ outlineColor:'black', padding: 24, outlineStyle:'dashed',outlineWidth:'3px', minHeight: 200, width: '30%',}}>
        <input {...getInputProps()} />
        {
          uploading ?
            <p>Video Uploading...</p> :
            <p>Drag and drop video file here, <br />or click to select files</p>
        }
      </div>
        <div style={{minWidth: 500, paddingTop: 50}}>
          <ProgressBar style = {{ width: '100%'}} animated now={progress} />
            {progress < 100 &&
            <p>{progress}%</p>}
            {progress === 100 &&
            <p>Upload Complete</p>}
        </div>
      </div>
    )
}

export default MyDropzone;