import React, { Component } from "react";
import _ from "lodash";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend , LabelList, Surface, Symbols} from 'recharts';
import SimpleLineChart from "./RenderSimpleLineChart";
import Moment from 'moment';

const TiltedAxisTick = (props) => {

  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text 
        x={0} 
        y={0} 
        dy={16} 
        textAnchor="end" 
        fill="#666" 
        transform="rotate(-45)">
          <br></br>{payload.value}
      </text>
    </g>
  );  

};

class TimeBasedLineChart extends Component{
  constructor(props){
    super();
    this.state = {
        videosData: props.videosData,
    };

  }
  async componentDidMount(){
    //sort videosData by project date
    const { videosData } = this.props;
    if (videosData) {
      videosData.sort((a, b) => {
          if (a.tb_project_date < b.tb_project_date)
              return -1;
              if (a.tb_project_date > b.tb_project_date)
              return 1;
          return 0;
      });
      this.setState({
          videosData: videosData,
      });
    } else {
      this.setState({ videosData: null })
    }
  };
  handleClick = dataKey => {
     if (_.includes(this.state.disabled, dataKey)) {
      this.setState({
        disabled: this.state.disabled.filter(obj => obj !== dataKey)
      });
    } else {
      this.setState({ disabled: this.state.disabled.concat(dataKey) });
    }
  }

  renderCusomizedLegend = ({ payload }) => {
    return (
      <div className="customized-legend">
        {payload.map(entry => {
          const { color, name, dataKey } = entry;
          const active = _.includes(this.state.disabled, dataKey);
          const style = {
            marginRight: 10,
            color: active ? "#AAA" : "#000"
          };
          return (
            <span
              className="legend-item"
              onClick={() => this.handleClick(dataKey)}
              style={style}
            >
              <Surface width={20} height={10} viewBox="0 0 20 10">
                <Symbols cx={5} cy={5} type={'circle'} size={50} fill={color} />
                {active && (
                  <Symbols
                    cx={5}
                    cy={5}
                    type="circle"
                    size={25}
                    fill={"#FFF"}
                  />
                )}
              </Surface>
              <span>{name}</span>
            </span>
          );
        })}
      </div>
    );
  };

    filterFrequency = (videosData) => {
        return videosData.map(data => {
            const displayData = {
                ...data.tb_feedback.frequency.standardized.teacher,
                videoId: data.tb_video_id,
                date: data.tb_project_date,
                videoDate: this.formatDate(data.tb_project_date)
            }
            delete displayData.total_student_talkmoves;
            return (displayData)
        });
    };


  formatDate = (date) => Moment(date).format("MMM Do YY").toString()

  handleCatagoryCaptilization = (string) =>  string.split(' ').map( (str) => str[0].toUpperCase() + str.slice(1) + ' ').join(" ")

	render () {
        const COLORS = ['#f6cc46', '#fcdb71', '#fae5a2', /* '#3dc9c4', */'#9ee4e1', '#ff7359', '#ffb9ac'];
        
        return (
        <div>
        <div className="trend-content">
            <h5 style={{marginBottom: 30}}>How did my use of tutor talk moves change over time?</h5>
            <div> 
                <SimpleLineChart 
                    yLabel={'Frequency of each talk move for tutors'} 
                    data={this.filterFrequency(this.state.videosData)} 
                    categories={Object.keys(this.state.videosData[0].tb_feedback.frequency.teacher)}
                    labelPosition='insideBottomLeft'
                    handleCatagoryCaptilization={this.handleCatagoryCaptilization}
                    showLegend={true}
                    colors={COLORS}
                    type={'teachers'}
                    history={this.props.history}
                    >
                </SimpleLineChart>
            </div>
        </div>
    </div>
    );
  }
}

export default TimeBasedLineChart;